import { useContext, useEffect, useMemo, useState } from 'react';
import Popup from '../base/custom-page-components/popups/view/Popup';
import Section from '../base/Section';
import { UserContext } from '../Context/User';
import { SubContainer } from './styles';
let perfomance1 = 0, perfomance2 = 0;
function Default({ globalProps, page, setPopupStructure, templateZonesLoadingPageRoute }) {
    const { setPageId } = useContext(UserContext);
    const [loadedSuperiorCompleto, setLoadedSuperiorCompleto] = useState(false);

    const [loadedCentroA, setLoadedCentroA] = useState(false);

    const [loadedIzquierdaB, setLoadedIzquierdaB] = useState(false);
    const [loadedDerechaB, setLoadedDerechaB] = useState(false);

    const [loadedIzquierdaC, setLoadedIzquierdaC] = useState(false);
    const [loadedCentroC, setLoadedCentroC] = useState(false);
    const [loadedDerechaC, setLoadedDerechaC] = useState(false);

    const [loadedCentroD, setLoadedCentroD] = useState(false);

    
    const [loadedIzquierdaE1, setLoadedIzquierdaE1] = useState(false);
    const [loadedIzquierdaE2, setLoadedIzquierdaE2] = useState(false);
    const [loadedDerechaE1, setLoadedDerechaE1] = useState(false);
    const [loadedDerechaE2, setLoadedDerechaE2] = useState(false);
    
    const [loadedCentroF, setLoadedCentroF] = useState(false);
    /* useMemo(() => {
        // componentWillMount events
        console.log("useMemo");
       
        perfomance1 = performance.now();
      },[]);
      useEffect(() => {
        // componentDidMount events
        console.log("useEffect");
        perfomance2 = performance.now();
        console.log(`el tiempo es: ${perfomance2 - perfomance1}`);
        return () => {
          // componentWillUnmount events
        }
      }, []); */
    useEffect(() => {
        setPageId(page.id);
    }, [page])
    const templateZonesLoading = {
        superiorCompleto: { loaded: loadedSuperiorCompleto, setLoaded: setLoadedSuperiorCompleto },

        centroA: { loaded: loadedCentroA, setLoaded: setLoadedCentroA },

        izquierdaB: { loaded: loadedIzquierdaB, setLoaded: setLoadedIzquierdaB },
        derechaB: { loaded: loadedDerechaB, setLoaded: setLoadedDerechaB },

        izquierdaC: { loaded: loadedIzquierdaC, setLoaded: setLoadedIzquierdaC },
        centroC: { loaded: loadedCentroC, setLoaded: setLoadedCentroC },
        derechaC: { loaded: loadedDerechaC, setLoaded: setLoadedDerechaC },

        centroD: { loaded: loadedCentroD, setLoaded: setLoadedCentroD },
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
        izquierdaE1: { loaded: loadedIzquierdaE1, setLoaded: setLoadedIzquierdaE1 },
        izquierdaE2:{ loaded: loadedIzquierdaE2, setLoaded: setLoadedIzquierdaE2 },
        derechaE1:{ loaded: loadedDerechaE1, setLoaded: setLoadedDerechaE1 },
        derechaE2:{ loaded: loadedDerechaE2, setLoaded: setLoadedDerechaE2 },
        centroF:{ loaded: loadedCentroF, setLoaded: setLoadedCentroF }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="superiorCompleto" />
                    </div>
                </div>
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroA" />
                    </div>
                </div>
                

                <div className="row">

                    <div className="col-6">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaB" />
                    </div>
                    <div className="col-6">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB" />
                    </div>

                </div>

                <div className="row">
                    <div className="col-4">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaC" />
                    </div>
                    <div className="col-3">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroC" />
                    </div>
                    <div className="col-5">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaC" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroD" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaE1" />
                    </div>
                    <div className="col-3">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaE2" />
                    </div>
                    <div className="col-3">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaE1" />
                    </div>
                    <div className="col-3">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaE2" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroF" />
                    </div>
                </div>
                

            </div>
        </>

    );
}

export default Default;