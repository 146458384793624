import { createContext, useState, useEffect } from "react";
import { Theme1 } from "../../themes/Theme1";

export const UserContext = createContext();

export function UserInfo({ children }) {
  
  const [roles, setRoles] = useState([]);
  const [pageId, setPageId] = useState(null);
  /**
   * @edit tiene permisos de editar, de lo contrario no puede editar y se oculta opcion editar
   *  aplica para la tabla la misma configuración, editor y comentarios
   * @showAllPages tiene permisos en la opcion mostrar todas paginas en Editar
   * @filterRegion tiene permisos en la opcion filtrar por Region
   */
  const [hasPermission, setHasPermission] = useState({edit: false, showAllPages: false, filterRegion: false}); 
  const [idFilter, setIdFilter] = useState(null);
  const [colorPalette, setColorPalette] = useState(Theme1);
  const data = {
    
    setPageId,
    hasPermission,
    setIdFilter,
    validateShowComponent: function(a, b) {return validateShowComponent(a,b)},
    colorPalette,
  };
  
  useEffect(() => {
    let config = JSON.parse(localStorage.getItem('config'));
    setColorPalette({...Theme1, primary: config.color_primary, secondary: config.color_secondary});
    
    setRoles(JSON.parse(localStorage.getItem('content_permissions')))
  
  }, [])
  useEffect(() => {

    if(roles.length === 0 || pageId === null) return;
    let validatePermission = {edit: false, showAllPages: false,  filterRegion: false};
    if(roles.find(el => el.idpage === 0 && el.idfilter === 0)) {
      validatePermission = {edit: true, showAllPages: true, filterRegion: true};
    } else if (roles.find(el => el.idpage === pageId && el.idfilter === 0)) {
      validatePermission = {edit: true, showAllPages: false, filterRegion: true};
    } else {
      for (const el of roles) {
        if((el.idpage === 0 && el.idfilter === Number(idFilter)) || (el.idpage === pageId && el.idfilter === Number(idFilter))) {
          validatePermission = {edit: true, showAllPages: false, filterRegion: false};
          break;
        }
      }
    }
    
    if(!validatePermission.edit && localStorage.getItem("editable") === "1") {
      localStorage.setItem("editable", 0);
    }
    setHasPermission(validatePermission);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, idFilter]);

  const validateShowComponent = (everypages, filter) => {
    return (!everypages || (hasPermission.showAllPages && hasPermission.filterRegion))
    && (filter || hasPermission.filterRegion);
  }
  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}
