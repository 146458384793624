import { Link } from "react-router-dom"
import { useState, useContext } from 'react';
import { ItemWrapper, ItemContain, Label, ItemContainWrapper, ItemContainNoLink } from "./styles"
import { FaAngleDown, FaAngleRight, FaAngleUp, FaExternalLinkAlt } from "react-icons/fa";
import { Collapse } from 'react-bootstrap';
import { UserContext } from "../../../Context/User";

export const ItemSidebar = ({label, icon: Icon, path, child, external}) => {
    const { colorPalette } = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const handleOpenChild = () =>{
        if (path === "#" && child !== undefined){
            setOpen(!open);
        }
    };
    return (
    <ItemWrapper colors={colorPalette}>
        <ItemContainWrapper>
            <ItemContain primary={colorPalette.primary}>
                {
                    path === "#" ?
                    <ItemContainNoLink colors={colorPalette} onClick={handleOpenChild}>
                        <Icon />
                        <Label>{label}</Label>
                    </ItemContainNoLink>:
                    (
                        external ?
                            <a href={path} target="_blank" rel="noreferrer">
                                <Icon />
                                <Label>{label}</Label>
                            </a>
                        :
                            <Link to={path}>
                                <Icon />
                                <Label>{label}</Label>
                            </Link>
                        )
                }
                { child !== undefined && child.length > 0 && 
                    <span className="collase-menu" onClick={() => setOpen(!open)}>
                       { open ? <FaAngleUp/> : <FaAngleDown/>}
                    </span>
                }
            </ItemContain>
            <Collapse in={open}>
            <div className="menu-collase">
            { child !== undefined && 
                child.map((item, i) => (
                    item.external
                    ?
                        <a key={i} href={item.path} target="_blank" rel="noreferrer">
                            <FaExternalLinkAlt />
                            <Label>{item.label}</Label>
                        </a>
                    :
                    <Link key={i} to={item.path}>
                        <FaAngleRight />
                        <Label>{item.label}</Label>
                    </Link>
                ))
            }
            </div>
        </Collapse>
      </ItemContainWrapper>
    </ItemWrapper>
)
}