import { Spinner } from "react-bootstrap"
import { StyleLoading } from "./style"


export const Loading = ({ height, text = "Cargando....", fontSize="2rem" }) => {
    return (
        <StyleLoading height={height}>
            <Spinner animation="border" /><p style={{fontSize: fontSize}}><b>{text}</b></p>
        </StyleLoading>
    )
}