import {Route, Switch} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
// All templates
import AllTemplates from '../templates';
import Utils from '../utils';
import PageRoute from './PageRoute';
import PageNotFound from './PageNotFound';
function Templates({globalProps}){
    const [pages, setPages] = useState([]);
    const [load, setload] = useState(false);  
    useEffect(() => {
        Utils.Petition.get(
            '/pages/portal/'+localStorage.getItem('idportal'),
            (response)=>{
                setPages(response.data);
                setload(true);
            }
        );
    }, []);
    return (
        <main>
            {
                load &&
                <Switch>
                    {
                        pages.map(page => {
                            const ComponentTagName = AllTemplates[page.template || 'Default'];
                            return (<Route key={page.id} path={page.path} exact component={() => <PageRoute globalProps={globalProps} page={page} ComponentTagName={ComponentTagName} />} />)
                        })
                    }
                    <Route component={()=><PageNotFound />}/>
                </Switch>
            }
            { <iframe width="0" height="0" style={{border:0}} src={Utils.adminAutologin(localStorage.getItem('token_user'), 'true')} /> }
        </main>
    )
}
export default Templates;