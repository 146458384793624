import { useContext, useEffect, useState } from "react";
import Perspective from "./Perspective";
import Utils from '../../utils';
import CustomModal from "../../base/CustomModal";
import IndicatorDetail from "../../Components/IndicatorDetail";
import { ButtonsContent } from "./styles";
import { FaRegComment, FaEye, FaEyeSlash } from "react-icons/fa";
import { IndicatorConvensionesTwo } from "../../Components/styles";
import { Button } from "../../commonStyles";
import { UserContext } from "../../Context/User";
function View({page, module}){
    const { colorPalette } = useContext(UserContext);
    const [indicatorData, setIndicatorData] = useState([]);
    const [isConfigured, setIsConfigured] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [comment, setComment] = useState('');
    const [showObjective, setShowObjective] = useState(false);
    const [showIndicatorsDetailModal, setShowIndicatorsDetailModal] = useState(false);
    const [selectedIndicatorDetail, setSelectedIndicatorDetail] = useState(0);
    const handleCommentModal = ()=>{
        setShowModal(true);
    }
    useEffect(()=>{
        if( 
            Object.keys(module.config).length>0 && 
            module.config.data.length>0 && 
            module.config.stages.col1.name && module.config.stages.col1.data &&
            //module.config.stages.col2.name && module.config.stages.col2.data &&
            //module.config.stages.col3.name && module.config.stages.col3.data &&
            //module.config.stages.col4.name && module.config.stages.col4.data &&
            module.config.stages.sign.data

        ){
            let ids = [];

            for(let i in module.config.data){
                for(let j in module.config.data[i].data){
                    for(let k in module.config.data[i].data[j].data){
                        if(!ids.includes(module.config.data[i].data[j].data[k])){
                            ids.push(module.config.data[i].data[j].data[k]);
                        }
                    }
                }
            }

            for(let key in module.config.stages) {
                let element = module.config.stages[key];
                if(element.hasOwnProperty("data")){
                    element.data.ViewValues = {}
                    for (let idx = 0; idx < element.data.view.length; idx++) {
                        const el = element.data.view[idx];
                        element.data.ViewValues[el] = element.data.value[idx]
                    }
                }                
            }
            let columns = [];
            
            for(let key in module.config.stages){
                if(module.config.stages[key].hasOwnProperty("data")){
                    columns.push(key+'-'+module.config.stages[key].data.ViewValues[localStorage.getItem('view')]);
                }                
            }
            Utils.Petition.get(
                '/indicator-value/table/' + ids.join(',')+'/columns/'+columns.join(',')+'/year/'+localStorage.getItem('year')+'/month/'+ (parseInt(localStorage.getItem('month'))+1), (response)=>{
                    let _data = {};
                    for(let i in response.data){
                        _data[response.data[i].id] = response.data[i];
                    }
                    setIndicatorData(_data);
                    setIsConfigured(true);
                }
            );
            Utils.Petition.get('/comments/type-model/page_module/type-id/'+module.id+'/year/'+localStorage.getItem('year')+'/month/'+localStorage.getItem('month')+'/view/'+localStorage.getItem('view'), (response)=>{
                if(response){
                    setComment(response.data.comment);
                }
            })
        }
    }, []);
    return (
        isConfigured ?
        <>
        <ButtonsContent>
        {comment !=='' && <Button color={colorPalette.primary} onClick={handleCommentModal}>Ver comentarios generales <FaRegComment /></Button>}
            {
                <Button color={colorPalette.primary} onClick={()=>setShowObjective(!showObjective)}>
                    {showObjective?<>Ocultar todos <FaEyeSlash /></>: <>Mostrar todos <FaEye /></>}
                </Button>
            }
        </ButtonsContent>
        <IndicatorConvensionesTwo className="">
          
              <div style={{ display: "inline-block" }}>Convenciones: </div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail verde"></i><span>100%</span></div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail amarillo"></i><span>Parcial</span></div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail rojo"></i><span>No cumple</span></div>
            
      </IndicatorConvensionesTwo>
            { 
            showModal && 
                <CustomModal title={'Comentario del mes actual'} show={showModal} setShow={setShowModal}>
                    {comment}
                </CustomModal>
            }
            {
                showIndicatorsDetailModal &&
                <CustomModal title={"Detalle del indicador"} show={showIndicatorsDetailModal} setShow={setShowIndicatorsDetailModal}>
                    <IndicatorDetail idindicator={selectedIndicatorDetail}></IndicatorDetail>
                </CustomModal>
            }
            {
                module.config.data.map((perspective, index)=>{
                    return <Perspective key={index} idx={index} setShowIndicatorsDetailModal={setShowIndicatorsDetailModal} setSelectedIndicatorDetail={setSelectedIndicatorDetail} showObjective={showObjective} config={module.config} perspective={perspective} indicatorData={indicatorData} />
                })
            }
        </>
        : null
    )
}
export default View;