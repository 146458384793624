import styled from "styled-components";
import { Theme1 } from "./../../themes/Theme1";

export const GraphicWrapper = styled.div`
    width: 100%;
    padding: 10px;
`;
export const GraphicWrapperContent = styled.div`
    display: block;
    width: 100%;
`;
export const GraphicContainer = styled.div`
    width: 100%;
    padding: 20px;
    background-color: white;
    border-radius: ${Theme1.radius};
    box-shadow: ${Theme1.shadow};
    h3{
        font-size: 18px;
        display:flex;
        justify-content: space-between;
        margin-bottom: 0 !important;
    }
`;

