import { createGlobalStyle } from "styled-components";
import { Theme1 } from "./themes/Theme1";


export const GlobalStyle = createGlobalStyle`
  *{
    font-family: ${Theme1.font};
  }
  body {
    margin: 0;
    padding: 0;
    font-family: ${Theme1.font};
    color: ${Theme1.grayText};
  }
  a {
    text-decoration: none;
    &:hover{
      text-decoration: none;
    }
  }
  .modal-sm{
        max-width: 450px;
    }

// style SCROLL
::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
    
// focus input y botones
input.form-control:focus,
textarea.form-control:focus,
select.form-control:focus {
  border-color: ${props => props.colors?.primary};
  box-shadow: 0 0 0 0.2rem ${props => props.colors?.primary}30;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem ${props => props.colors?.primary}30;
}

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem ${props => props.colors?.primary}30;
}

.dropdown-item.active, .dropdown-item:active{
  background-color:  ${props => props.colors?.primary};
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  background-color:  ${props => props.colors?.primary};
  border-color: ${props => props.colors?.primary};
}
// boton para modal
button.modal-primary {
  background-color: ${props => props.colors?.primary};
  border-color: ${props => props.colors?.primary};

  &:hover {
    color: ${props => props.colors?.primary};
    background-color: white;
    border-color: ${props => props.colors?.primary};
  }

  &:focus {
    border-color: ${props => props.colors?.primary};
    box-shadow: 0 0 0 0.2rem ${props => props.colors?.primary}30;
    background-color: ${props => props.colors?.primary};
  }
}
.tox-notifications-container {
        display: none;
    }


 .tabla-seguimiento li, .tabla-seguimiento p, .tabla-seguimiento div, .tabla-seguimiento ul, .tabla-seguimiento ol {
  font-family: inherit !important;
  font-size: inherit !important;
  background-color: transparent !important;
}

.tabla-seguimiento span {
  font-family: inherit !important;
  font-size: inherit !important;
}

.tabla-seguimiento > tbody > tr:not(:nth-child(4)) span {
  background-color: transparent !important;
}
`;

