import { Col, Row } from 'react-bootstrap';
import React, { useState, useEffect, useContext } from "react";
import Utils from '../utils';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Button } from '../commonStyles';
import { Theme1 } from '../themes/Theme1';
import { styleTheme } from '../themes/themeSelect';
import { UserContext } from '../Context/User';

function SelectInfo({ form, setForm, setShowModal, id }) {
  const { colorPalette } = useContext(UserContext);
  const [stages, setStages] = useState([]);
  const [iduser, setIduser] = useState(localStorage.getItem('iduser'))
  const [indicator, setIndicator] = useState({});
  const [stage, setStage] = useState([]);
  const [flagUser, setFlagUser] = useState(false);

  useEffect(() => {
    Utils.Petition.get(
      '/stages_relation/select',
      (response) => {
        setStages(response.data);
      }
    );

    if (form.users.hasOwnProperty(iduser)) {
      setIndicator(form.users[iduser].indicator)
      setStage(form.users[iduser].stages)
      setFlagUser(true);
    } else {
      setIndicator(form.default.indicator)
      setStage(form.default.stages)
    }
  }, [])

  const loadIndicators = (inputValue) => {

    if (inputValue.length > 1) {
      return new Promise((resolve) => {
        setTimeout(() => {
          Utils.Petition.get(
            '/indicators/name-code-indicator/' + inputValue,
            (response) => {
              resolve(response.data)
            }
          )
        }, 100);
      });
    }
    return new Promise((resolve) => resolve([]));
  };

  const selectIndicators = (data) => {
    setIndicator(data);
  };

  const selectStage = (data, index) => {
    let temp = [...stage];
    temp[index] = data;
    setStage(temp);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const configUser = { indicator, stages: stage };
    const data = {
      id,
      config: configUser
    }
    let temp = form.users;
    temp = {
      ...temp,
      [iduser]: configUser
    }

    if (flagUser) {
      let changeStage = false;
      for( let i=0; i<stage.length; i++ ){
        if (stage[i].label !== form.users[iduser].stages[i].label) {
          changeStage = true;
          break;
        }
      }

      if (indicator.id !== form.users[iduser].indicator.id || changeStage) {
        Utils.Petition.post(
          '/pages-modules/update-user', data,
          (response) => {
            setForm({
              ...form,
              users: temp
            })
          }
        );
      }

    } else {
      let changeStage = false;
      for( let i=0; i<stage.length; i++ ){
        if (stage[i].label !== form.default.stages[i].label) {
          changeStage = true;
          break;
        }
      }
      if (indicator.id !== form.default.indicator.id || changeStage) {
        Utils.Petition.post(
          '/pages-modules/update-user', data,
          (response) => {
            setForm({
              ...form,
              users: temp
            })
          }
        );
      }

    }
    setShowModal(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <Row>
          <Col sm={4}>Indicador</Col>
          <Col sm={8}>
            <AsyncSelect
              value={indicator}
              cacheOptions
              defaultOptions
              loadOptions={loadIndicators}
              onChange={selectIndicators}
              theme={theme => (styleTheme(theme, colorPalette?.primary))}
            />
          </Col>
        </Row>
      </div>
      <div className="form-group">
        <Row>
          <Col sm={4}>Escenario 1</Col>
          <Col sm={8}> 
            <Select
              value={stage[0]}
              cacheOptions
              defaultOptions
              options={stages}
              onChange={e => selectStage(e, 0)}
              theme={theme => (styleTheme(theme, colorPalette?.primary))}
            />
          </Col>
        </Row>
      </div>
      <div className="form-group">
        <Row>
          <Col sm={4}>Escenario 2</Col>
          <Col sm={8}>
            <Select
              value={stage[1]}
              cacheOptions
              defaultOptions
              options={stages}
              onChange={e => selectStage(e, 1)}
              theme={theme => (styleTheme(theme, colorPalette?.primary))}
            />
          </Col>
        </Row>
      </div>
      <div className="text-center">
        <Button color={Theme1.success} type="submit" className="btn btn-primary">Guardar</Button>
      </div>
    </form>
  )

}

export default SelectInfo;