import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function GetPopup({
  idPopup,
  popUpDescription,
  setPopupStructure,
  templateZonesLoading,
}) {
  const [modalPopup, setModalPopup] = useState(false);

  const handlePopup = (event) => {
    event.preventDefault();
    setPopupStructure({
      id: idPopup,
      title: popUpDescription,
      show: true,
    });
    templateZonesLoading['popup'].setLoaded(false);
    templateZonesLoading['popupIzquierda'].setLoaded(false);
    templateZonesLoading['popupDerecha'].setLoaded(false);
    templateZonesLoading['popupIzquierdaA'].setLoaded(false);
    templateZonesLoading['popupDerechaA'].setLoaded(false);
    templateZonesLoading['popupIzquierdaB'].setLoaded(false);
    templateZonesLoading['popupCentroB'].setLoaded(false);
    templateZonesLoading['popupDerechaB'].setLoaded(false);
    templateZonesLoading['popupCentroC'].setLoaded(false);
    setModalPopup(true);
  };

  return (
    <span>
      <Link onClick={handlePopup}>
        {popUpDescription}
      </Link>
    </span>
  );
}

export default GetPopup;
