import { useContext, useEffect, useState } from "react";
import { FaEdit, FaSave } from "react-icons/fa";
import Sign from "../../Components/Sign";
import TendenceSign from "../../Components/TendenceSign";
import Utils from "../../utils";
import { Comments, CustomCard, ViewContainer } from "./styles";
import { Form} from "react-bootstrap";
import { Link } from 'react-router-dom';
import { UserContext } from "../../Context/User";

function View({page, module}){
    const { hasPermission } = useContext(UserContext);
    const [values, setValues] = useState({});
    const [edit, setEdit] = useState(false);
    const [comment, setComment] = useState('');
    const [commentInput, setCommentInput] = useState('');

    const format = Utils.getConfigPortal('format_number');

    useEffect(()=>{
        let sign = 0;
        let stage = 0;
        let viewsSign = {};
        let viewsStage = {};
        if(module.config && Object.keys(module.config).length > 0){
            for(let i= 0; i<module.config.sign.view.length; i++){
                viewsSign[module.config.sign.view[i]] = module.config.sign.value[i];
            }
            for(let i= 0; i<module.config.stage.view.length; i++){
                viewsStage[module.config.stage.view[i]] = module.config.stage.value[i];
            }
            //console.log(viewsSign, viewsStage);
            if(module.config.view){
                sign = viewsSign[module.config.view];
                stage = viewsStage[module.config.view];
            }else{
                sign = viewsSign[localStorage.getItem('view')];
                stage = viewsStage[localStorage.getItem('view')];
            }
            Utils.Petition.get(
                `/indicator-value/table/${module.config.idindicator}/columns/sign-${sign},col1-${stage},col2-0,col3-0,col4-0/year/${localStorage.getItem('year')}/month/${parseInt(localStorage.getItem('month'))+1}`,
                (response)=>{
                    setValues(response.data[0]);
                    //console.log(response.data);
                }
            )
            Utils.Petition.get(
                `/comments/type-model/page_module/type-id/${module.id}/year/${localStorage.getItem('year')}/month/${localStorage.getItem('month')}/view/${module.config.view ? module.config.view: localStorage.getItem('view')}`,
                (response)=>{
                    setComment(response.data.comment);
                }
            )
        }
        
    }, []);
    const handleEditMode = () => {
        setCommentInput(comment);
        setEdit(true);
    }
    const handleSave = () => {
        //Save
        Utils.Petition.post('/comments', {
            typeModel:'page_module',
            idtype:module.id, 
            year:localStorage.getItem('year'), 
            month:localStorage.getItem('month'), 
            view: module.config.view ? module.config.view: localStorage.getItem('view'),
            comment: commentInput
        }, (response)=>{
            setEdit(false);
            setComment(commentInput);
        })
    }
    const cardComponent = () => {
        return (
            <CustomCard>
                <div className="number">
                    <TendenceSign value={values.sign} type={values.type} base={module.config.base}  /> 
                    <p>{module.config.prefix}{Utils.formatNumber(format,values.col1)}{module.config.postfix}</p>
                </div>
                <div className="description text-center">
                    {module.config.description}
                </div>
            </CustomCard>
        )
    }
    return (
        <ViewContainer>
            {
                module.config.type && 
                <Link to={module.config.type === "sitio" ? module.config.link: { pathname:  module.config.link }} 
                    target={module.config.newTab ? "_blank": "_self"} rel="noopener noreferrer" >
                    {cardComponent()}
                </Link>
            }
            {
                !module.config.type && 
                <>
                    {cardComponent()}
                </>
            }
            <Comments>
                {
                    edit ?  
                        <>
                            <Form.Control as="textarea" placeholder="Escriba una descripción Max 60 caracteres" rows={2} maxLength={60} value={commentInput} id="description" name="description" onChange={(event)=> setCommentInput(event.target.value)}/>
                            <FaSave onClick={handleSave} />
                        </> 
                    : 
                        <>
                            <span className="comment">
                                {
                                    !comment ?
                                    'Sin comentarios': 
                                    comment
                                }
                            </span>
                            { hasPermission.edit && <FaEdit onClick={handleEditMode} /> }
                        </>
                }
            </Comments>
        </ViewContainer>
    )
}
export default View;