import styled from 'styled-components';
import { Theme1 } from '../../themes/Theme1';

export const DivMenu = styled.div`
    &.tmenu-tab li::after {
        content: '';
        height: calc(100% - 20px); //You can change this if you want smaller/bigger borders
        width: 1px;

        position: absolute;
        right: -2px;
        top: 10px; // If you want to set a smaller height and center it, change this value

        background-color: ${Theme1.grayLight}; // The color of your border
    }
    &.tmenu-tab li.active {
        background-color: white;
        box-shadow: 0px -10px 20px 1px rgb(0 0 0 / 15%);
        z-index: 2;
        border-radius: 0.8rem 0.8rem 0 0;
        border: none;
        font-size: 1.2rem;
        font-weight: bold;
        
        &::after {
        content: '';
        width: 0px;
    }
    }


    
    &.tmenu-tab a {
    color: ${Theme1.grayText};
    text-decoration: none;
    
    }
    &.menu-type-horizontal.tmenu-undefined {
        margin-top: -3rem;
        font-size: 0.9rem;
        margin-bottom: 1rem;
    }

    &.tmenu-btn{
        ul{
            padding-left: 0px;
        }
        li{
            button{
                font-size: 12px;
                background-color: ${props => props.colors?.primary};
                border-color: ${props => props.colors?.primary};
                &:hover {
                    background-color: transparent;
                    color: ${props => props.colors?.primary};
                } 
            }
            a{
                font-size: 12px;
                background-color: ${props => props.colors?.primary};
                border-color: ${props => props.colors?.primary} ;
                &:hover{
                    color: ${props => props.colors?.primary};
                background-color: transparent ;
                }
            }
            &:first-child{
            padding-left: 0px !important;
            }
        }
        li.active{
            
            button{
                color: ${props => props.colors?.primary};
                background-color: transparent;
            }
            a{
                color: ${props => props.colors?.primary} ;
                background-color: transparent ;
            }
        }
    }
`;

export const StyledMenu = styled.ul`

    display: flex;
    text-decoration: none;
    list-style:none;
    margin: 0;

    & li {
        padding: 0.8rem 1.2rem;
        position: relative;
        
    }

    & img {
        filter: sepia(100%) hue-rotate(190deg) saturate(500%);
    }
`;

