import styled from "styled-components";
import { Theme1 } from "../../themes/Theme1";

export const CustomCard = styled.div`

    border: 1px solid lightgray;
    border-radius: 0.5rem;
    padding: 1rem;
    box-shadow: ${Theme1.shadow};
    margin: 2.5rem 0 1rem 0;
    color: black;
    height: 170px;
    transition: transform 1s ease-in-out;

    &:hover {
        pointer-events: all;
        border: 1px solid green;
        cursor: pointer;
        transform: scale(1.1, 1.1);
    }
    & .number {
        display: flex;
        justify-content: space-evenly;
        margin: 1rem 0;
        & svg {
            font-size: 1.5rem;
        }
        & p {
            margin: 0;
            font-weight: bolder;
            font-size: 1.5rem;
        }
    }
    & .description {
        font-style: italic;
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65px;
    }
    
`;

export const Comments = styled.div`
    height: 50px;
    text-align: center;
    & svg {
        cursor: pointer;
    }

    & textarea {
        resize: none;
        font-size: 0.8rem;
        overflow: hidden;
    }
    & span.comment {
        margin-right: 1rem;
        font-size: 0.85rem;
        text-align: justify;
        overflow-wrap: break-word;
    }
`;

export const ViewContainer = styled.div`
    
`;