import { useEffect, useState,useContext } from "react";
import { Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import Utils from "../../../../utils";
import { WrapperButton } from "../../styles";
import { AlertForm, ICON } from "../../../../Components/SweetAlert";
import { Loading } from "../../../../Components/Loading";
import { Button, StyleCheckbox } from "../../../../commonStyles";
import {ButtonLoading} from "../../../../Components/Loading/ButtonLoading";
import { UserContext } from "../../../../Context/User";
import { BsCheck, BsSquare } from "react-icons/bs";

function ListModules({page,filter,globalProps,containerComponent}) {
    const { colorPalette } = useContext(UserContext);
    const [modules, setModules] = useState([]);
    const [modulesChecked, setModulesChecked] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [isLoadingSave, setisLoadingSave] = useState(false);

    useEffect(()=>{  
        setModulesChecked([]);
        if(filter && filter.page && filter.page > 0 ){
            Utils.Petition.get(`/pages-modules/page/idpage/${filter.page}/filter/${filter.filter}/year/${filter.year}/month/${filter.month}`, (response) => {
                setModules(response.data);
            },(e) => {
                console.log(e);
            },() => {
                setIsLoadingTable(false);
            })
        }        
    }, []);

    const checkModule = (module ) => {
        console.log(modulesChecked.includes(module));
        if(!modulesChecked.includes(module)){
            setModulesChecked([...modulesChecked,module])
        }else{
            let _modulesChecked = [...modulesChecked];
            _modulesChecked.splice(_modulesChecked.indexOf(module),1)
            setModulesChecked(_modulesChecked)
        }
    }

    const allCheckModules = (e) => {
         setModulesChecked((modulesChecked.length === modules.length) ? []: modules);
    }

    const handleSaveModules = (event) => {
        event.preventDefault();
        if(modulesChecked.length > 0 ){
            setisLoadingSave(true);
            const saveModules = []
            for (let index = 0; index < modulesChecked.length; index++) {
                const element = modulesChecked[index];
                const module = {
                    idmodule:element.id,
                    idpage:page.id,
                    idfilter:localStorage.getItem('idfilter'),
                }
                saveModules.push(module);
            }
            
            if(saveModules.length === modulesChecked.length){
                Utils.Petition.post(`/pages-modules/copy-modules/`,{modules:saveModules},
                () => {
                    containerComponent.setShowCustomPageComponent(false)
                    AlertForm(ICON.SUCCESS, 'Módulos copiados correctamente');
                    globalProps.setReloadContent(true);
                },(e) => {
                    console.log(e)
                }, () => {
                    setisLoadingSave(false);
                })
            }
        }else{
            AlertForm(ICON.WARNING, 'Selecciona al menos un módulo para copiar');
        }      
        
    }

    
    return(
        <>
            {isLoadingTable && <Loading fontSize="1rem" />}
            {
                modules.length > 0 &&
                <form onSubmit={handleSaveModules} className="mb-3">
                
                {!isLoadingTable && <Table hover bordered className="text-center mt-3" size="sm">
                    <thead className="bg-dark text-white">
                        <tr>
                            <th scope="col">
                            <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-4">
                                <input className="custom-control-input" id="allModules" type="checkbox" checked={modulesChecked.length === modules.length}  name={`module-all`} onChange={allCheckModules} />
                                <label className="custom-control-label" htmlFor="allModules">
                                    Todos
                                </label>
                            </StyleCheckbox>
                            </th>
                            <th scope="col">Modulo</th>
                            <th scope="col">Año / Mes</th>
                            <th scope="col">Vista</th>
                        </tr>                        
                    </thead>
                    <tbody>
                    {
                        modules.map((el,idx) => {
                            return(
                            <tr key={idx}>
                                <td>
                                    {/* <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                                        <input className="custom-control-input" id={`module-${el.id}`} type="checkbox" checked={modulesChecked.includes(el)}  name={`module-${el.id}`} onChange={(e)=>checkModule(e,el)}/>
                                        <label className="custom-control-label" htmlFor={`module-${el.id}`}></label>
                                    </StyleCheckbox> */}
                                    {modulesChecked.includes(el) ? <BsCheck  onClick={()=>checkModule(el)}/> : <BsSquare onClick={()=>checkModule(el)}/>}
                                    
                                </td>
                                <td>{el.name}</td>
                                <td>{el.year === null ? "Todos" : el.year} / {el.month === null ? "Todos" : el.month}</td>
                                <td>{el.view === null ? "Todas" : el.view}</td>
                            </tr>
                            )
                        })
                    }
                    </tbody>
                </Table>}
                
                <div>
                    <Button color={colorPalette.primary} type="submit" margin="auto" disabled={isLoadingSave} >
                        {!isLoadingSave ? <>Guardar <FaSave /></>: <ButtonLoading />} 
                    </Button>
                </div>
                </form>
            }
            
        </>
    )
}

export default ListModules;