import { useContext, useEffect, useState } from "react";
import CustomModal from '../../base/CustomModal';
import {ADMIN_URL} from '../../config';
import Utils from '../../utils';
import { Link } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import { ButtonConfig } from "../Components/ButtonConfig";
import { UserContext } from "../../Context/User";
import { Button } from "../../commonStyles";

function Edit({globalParams, page, module, everypages, filter}){
    const { validateShowComponent, colorPalette } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);

    useEffect(()=>{
        window.addEventListener('message', (event)=>{
            if(event.origin===ADMIN_URL && event.data==='cerrar popup'){
                setShowModal(false);
            }
        })
    }, []);

    return ( 
        <div>
            <Container className='mt-3'><Row>
            
            {
                module.config.data !==undefined 
                    ?
                    module.config.data.map(e=>{
                        return e.newTab?(<Button color={colorPalette.grayText} className='mx-2' href={e.url} >{e.title}</Button> )// newTab, title, type, url
                        : (<Link to={e.url}  ><Button className='mr-2' color={colorPalette.grayText} >{e.title}</Button> </Link> )
                    })
                    : <i>Visualización del menú: aún no se ha configurado</i>
            } 
            </Row></Container> 
            {(module.idpopup !== null || validateShowComponent(everypages, filter)) && <ButtonConfig onPress={()=>setShowModal(true)} />}
            
            { showModal && 
                <CustomModal title={"Configurar menú"} show={showModal} setShow={setShowModal}>
                    <iframe title={"Configurar menú"} style={{border:0}} width="100%" height="500" src={Utils.getAdminUrl(`/menu/module/${module.id}`)}></iframe>
                </CustomModal>
            }
        </div>
    );
}

export default Edit;