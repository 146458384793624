import { ADMIN_URL } from '../config';
import Petition from './Petition';
import Security from './Security';

const Utils = {
    Petition,
    Security,
    getAdminUrl:(url)=>{

        return (`${ADMIN_URL+url}`);
    },
    adminAutologinData:(url,encoded)=>{
        let data = JSON.stringify({ ...localStorage });
        return (`${ADMIN_URL}/autologin/data/${encodeURIComponent(data)}/url/${encoded?url:encodeURIComponent(url)}`);
    },
    adminAutologin: (token, iframe)=>{
        if(!iframe) iframe='false';
        return (`${ADMIN_URL}/autologin/token/${token}/year/${localStorage.getItem('year')}/month/${localStorage.getItem('month')}/view/${localStorage.getItem('view')}/site/${localStorage.getItem('site')}/iframe/${iframe}`);
    },

    getConfigPortal: (property) => {
        const config = JSON.parse(localStorage.getItem('config'));
        if(config !== null) return config[property];
        return null;
    },
    formatNumber:(format, number)=>{
        if(format){
            try{
                let _format =format.split('');
                let _number = number.toString().split('.');

            /*
                POSIBLES formatos

                1 caracter, Ejemplo ','
                    Separador decimal ','
                2 caracteres Ejemplo ',.' 
                    Separador de miles ',' 
                    Separador de decimales '.'
                4 caracteres Ejemplo ',.2f' 
                    Separador de miles ',' 
                    Separador de decimales '.', 
                    Mostrar 2 decimales siempre
                6 caracteres Ejemplo ',.2f4d' 
                    Separador de miles ','
                    Separador de decimales '.', 
                    Mostrar 2 decimales mientras el número sea igual o menor de 4 digitos 
                    En caso de 5 digitos mostrará un decimal 
                    En caso de que sea de 6 digitos no mostrará ninguno
            //*/

            switch(_format.length){
                    case 1: return _number.join(_format[0]);
                    case 2: 
                        _number[0] = _number[0].replace(/\B(?=(\d{3})+(?!\d))/g, _format[0]);
                        return _number.join(_format[1]);
                    case 4: 
                        _number = parseFloat(number).toFixed(format[2]).split('.');
                        _number[0] = _number[0].replace(/\B(?=(\d{3})+(?!\d))/g, _format[0]);
                        if(_format[2]>0){
                            return _number.join(_format[1]);
                        }else{
                            return _number[0];
                        }
                    default:
                        // Por defecto, 6 digitos
                        let _f = parseInt(_format[4]) + parseInt(_format[2]) - _number[0].length;

                        if(_format[2] > 0 && _f > 0){
                            if(_f<_format[2]) _format[2]= _f;
                            
                        }else{
                            _format[2] = 0;
                        }
                        _number = parseFloat(number).toFixed(_format[2]).split('.');
                        _number[0] = _number[0].replace(/\B(?=(\d{3})+(?!\d))/g, _format[0]);
                        return _number.join(_format[1]);
                }
            }catch(_){}
            return number;
            
        }
    },
    removeOptions: (selectElement)=>{
            let i, L = selectElement.options.length - 1;
            for(i = L; i >= 0; i--) {
               selectElement.remove(i);
            }
    }
}
export default Utils;