import { Editor } from "@tinymce/tinymce-react";
import * as tinymce from "@tinymce/tinymce-react";
import React, { useContext, useRef, useState } from "react";
import { Button } from "../../../commonStyles";
import { AlertForm, ICON } from "../../../Components/SweetAlert";
import Utils from "../../../utils";

//Indicator Convert Selector
import CustomModal from "../../../base/CustomModal";
import AddIndicator from "../content/addIndicator";

//custom popups
import AddPopup from "../content/addPopup";

//add sign
import AddSign from "../content/addSign"
import { UserContext } from "../../../Context/User";
export const EditorText = ({
  content,
  setContent,
  page,
  idModule,
  inline = false,
  menubar = true,
}) => {
  const { colorPalette } = useContext(UserContext);
  //state vars for indicator Adder
  const editorRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showSign, setShowSign] = useState(false);
  const [showCustomPageComponent, setShowCustomPageComponent] = useState(false);

  const selectorsHandler = () => {
    localStorage.removeItem("selectedIndicator");
    localStorage.removeItem("selectedStage");
    localStorage.removeItem("signIndicator");
    localStorage.removeItem("signStage");
  };

  //Saves data of the component
  const log = () => {
    if (editorRef.current) {
      setContent(editorRef.current.getContent());

      //petition to save data
      let data = editorRef.current.getContent();
      let id = idModule;

      let result = {
        id: id,
        config: { data: data },
      };

      Utils.Petition.post(
        "/pages-modules/update",
        result,
        (res) => {
          AlertForm(ICON.SUCCESS, "Guardado Correctamente", "");
        },
        (err) => {
          console.log(err);
        }
      );
    }
  };

  //Custom Popups

  const handleMenuCustomPageComponent = (event) => {
    event.preventDefault();
    setShowCustomPageComponent(true);
  };

  const handlePopup = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  const handleSign = (event) => {
    event.preventDefault();
    setShowSign(true);
  };


  const addIndicator = () => {
    let indicator = localStorage.getItem("selectedIndicator");
    let stage = localStorage.getItem("selectedStage");

    if (indicator && stage) {
      editorRef.current.execCommand('mceInsertContent', false, `Indicador@indicador:${indicator},escenario:${stage}@`)
      AlertForm(ICON.SUCCESS, "Guardado Correctamente", "");
    } else {
      AlertForm(ICON.ERROR, "Se deben seleccionar los 2 campos", "");
    }
  };

  const addPopup = () => {
    let popUpId = localStorage.getItem("popupOption");
    let popUpDescription = localStorage.getItem("popupDescription");

    if (popUpId && popUpDescription) {
      editorRef.current.execCommand('mceInsertContent', false, `PopUp@descripcion:${popUpDescription},id:${popUpId}@`)
      AlertForm(ICON.SUCCESS, "PopUp Añadido", "");
    } else {
      AlertForm(ICON.ERROR, "Se deben seleccionar los 2 campos", "");
    }
  };

  const addSign = () => {
    let indicator = localStorage.getItem("signIndicator");
    let stage = localStorage.getItem("signStage");

    if (indicator && stage) {
      editorRef.current.execCommand('mceInsertContent', false, `Semaforo@indicador:${indicator},escenario:${stage}@`)
      AlertForm(ICON.SUCCESS, "Guardado Correctamente", "");
    } else {
      AlertForm(ICON.ERROR, "Se deben seleccionar los 2 campos", "");
    }
  };

  return (
    <>
      <Editor
        apiKey='66yhm7quk48ngil2sffgmag70o378r4v3yoz749l4jp4y5xa'
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={content}
        init={{
          selector: "textarea#classic",
          width: "100%",
          height: 500,
          resize: false,
          inline,
          statusbar: false,
          language: "es",
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: function (cb, value, meta) {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");
            input.onchange = function () {
              var file = this.files[0];
              if (file.type.slice(0, 5) === "image") {
                let reader = new FileReader();
                reader.onload = function (e) {
                  if (meta.filetype === "image") {
                    cb(e.target.result, { alt: file.name });
                  }
                };
                reader.readAsDataURL(file);
              }
            };
            input.click();
          },

          menubar: menubar ? "file edit view insert format tools table" : false,
          plugins: [
            " advlist anchor autolink codesample fullscreen help image imagetools charmap print",
            " lists link media noneditable preview emoticons insertdatetime hr",
            " searchreplace table template  visualblocks wordcount code pagebreak ",
          ],
          templates: [
            {
              title: "Non-editable Example",
              description: "Non-editable example.",
              content: "table1",
            },
            {
              title: "Simple Table Example",
              description: "Simple Table example.",
              content: "table2",
            },
          ],

          toolbar:
            "undo redo | bold italic | forecolor backcolor | template codesample | link image | alignleft aligncenter alignright alignjustify | bullist numlist  | code pagebreak |  fontselect fontsizeselect styleselect  ",

          tinydrive_token_provider: function (success, failure) {
            success({
              token:
                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqb2huZG9lIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Ks_BdfH4CWilyzLNk8S2gDARFhuxIauLa8PwhdEQhEo",
            });
          },
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:16px }",
        }}
      />

      <div className="row mt-2 d-flex ml-2">
        <div className="mr-1 mb-1">
          <Button color={colorPalette.primary} onClick={log}>
            Guardar
          </Button>
        </div>
        <div className="mr-1 mb-1">
          <Button color={colorPalette.secondary}  onClick={handlePopup}>
            Añadir indicador
          </Button>
        </div>
        <div className="mr-1 mb-1">
          <Button color={colorPalette.secondary}  onClick={handleMenuCustomPageComponent}>
            Insertar Popup
          </Button>
        </div>
        <div className="mr-1 mb-1">
          <Button color={colorPalette.secondary}  onClick={handleSign}>
            Añadir Semáforo
          </Button>
        </div>
      </div>
      <CustomModal
        title={"Añadir Indicador"}
        show={showModal}
        setShow={setShowModal}
        handleSaveButton={addIndicator}
      >
        {showModal && <AddIndicator addIndicator={addIndicator}></AddIndicator>}
        {selectorsHandler()}
      </CustomModal>

      <CustomModal
        title={"Inertar PopUp"}
        show={showCustomPageComponent}
        setShow={setShowCustomPageComponent}
        handleSaveButton={addPopup}
      >
        {showCustomPageComponent && (
          <AddPopup idpage={page.id} addPopup={addPopup}></AddPopup>
        )}
      </CustomModal>

      <CustomModal
        title={"Añadir Semáforo"}
        show={showSign}
        setShow={setShowSign}
        handleSaveButton={addSign}
      >
        {showSign && <AddSign></AddSign>}
        {selectorsHandler()}
      </CustomModal>
    </>
  );
};
