import styled, { keyframes } from "styled-components";
import { Theme1 } from "./../themes/Theme1";
localStorage.setItem('config', JSON.stringify({color_primary: "orange", color_secondary: "gray"}));

export const Dashboard = styled.div`
    position: relative;
    width: 100vw;
    height: 100vh;
    
`;
export const DashboardHeader = styled.header`
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    box-shadow: ${Theme1.shadow};
`;
export const DashboardWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 66.5px;
    
`;
export const DashboardContent = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    transition: padding-left 1.7s ease-in-out;
    padding-left: 0px;
    overflow: hidden;
    &.active-Menu {
        padding-left: 241px;
    }
`;
export const DashboardSidebar = styled.div`
    transition: transform 1.5s ease-in-out;
    width: 241px;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transform: translateX(-241px);
    &.active-Menu {
        transform: translateX(0px);
    }
`;
export const DashboardContentWrapper = styled.div`
    width: 100%;
    height: 100%;
    
    position: relative;
    overflow-y: auto;
    
`;
export const DashboardContainertWrapper = styled.div`
    width: 100%;
    max-width: 1400px;
    margin: auto;
    padding-bottom: 30px;
`;
export const PageIdWrapper = styled.div`
    &.page-10{
        .graphic-custom{
            box-shadow: ${Theme1.shadow};
        }
    }
    &.page-14{
        .module-ValueMap{
            box-shadow: ${Theme1.shadow};
        }
    }

`;

// Secciones del template
export const SectionSettings = styled.div`
    width: 100%;
    text-align: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    .dropdown{
        button{
            &:after{
                display: none;
            }
            &:focus {
                color: ${props => props.colors?.grayText} !important;
            }
        }
        .dropdown-menu{
            font-size: 14px;
            color: ${props => props.colors?.grayText};
            display: flex;
            flex-wrap: wrap;
            a{
                color: ${props => props.colors?.grayText};
                order: 2;
                font-size: 12px;
                &.actual{
                    order:1;
                    color: ${props => props.colors?.primary};
                    span{
                        display: block;
                        font-size: 10px;
                    }
                }
            }
        }
    }
    button{
        font-size: 15px;
        padding: 0;
        color: ${props => props.colors?.grayText};
        margin-left: 5px;
        background-color: transparent !important;
        border: none !important;
        &:hover, &:active{
            color: ${ props => props.colors?.primary} !important;
        }
        &.trash{
            color: ${Theme1.red};
        }
    }
    span{
        margin-left: 5px;
        cursor:pointer;
        &:hover{
            color: ${ props => props.colors?.primary};
        }
    }
`;

export const SectionTitle = styled.div`
    width: 100%;
    font-weight: bold;
    font-size: 14px;
`;
export const SectionPestañas = styled.div`
    width: 100%;
    label{
        font-size: 12px;
    }

 `;

export const ModuleWrapper = styled.div`
    padding:15px;
    border-left: 2px solid #fff;
    background-color: #f8f8f8;
    border-color: #ccc;
    margin-bottom: 10px;
    border-radius: 10px;
    &:hover{
        border-color: ${props => props.color};
    }
`;
export const ModuleTitle = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: bold;
`;
export const ModuleType = styled.div`
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    color: ${props => props.color};
`;

export const ModuleOptions = styled.div`
    width: 100%;
    text-align: right;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
    .dropdown{
        button{
            &:after{
                display: none;
            }
            &:focus {
                color: ${ props => props.colors?.grayText} !important;
            }
        }
        .dropdown-menu{
            font-size: 14px;
            color: ${ props => props.colors?.grayText} !important;
            display: flex;
            flex-wrap: wrap;
            a{
                color: ${ props => props.colors?.grayText};
                order: 2;
                font-size: 12px;
                &.actual{
                    order:1;
                    color: ${ props => props.colors?.primary};
                    span{
                        display: block;
                        font-size: 10px;
                    }
                }
            }
        }
    }
    button{
        font-size: 16px;
        padding: 0;
        color: ${ props => props.colors?.grayText};
        margin-left: 5px;
        background-color: transparent !important;
        border: none !important;
        &:hover, &:active{
            color: ${ props => props.colors?.primary} !important;
        }
        &.trash{
            color: ${Theme1.red};
        }
    }

`;

export const ModuleOrder = styled.div`
    width: 100%;
    font-size: 12px;
`;

export const ModuleTabs = styled.div`
    width: 100%;
    font-size: 12px;
`;

export const ModuleActions = styled.div`
    width: 240px;
    font-size: 12px;
    margin-bottom: 1rem;
    .buton-filter{
        background-color: #333;
        font-size: 12px;
    }
    .section-filter{
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        margin-top: 5px;
        border:1px solid ${Theme1.grayLight};
    }
`;

// Agregar modulos personalizados

export const AggButtonWrapper = styled.div`
    width: 100%;
    font-size: 12px;
    margin: 20px 0 10px 0;
    display: flex;
    justify-content: flex-end;
`;

export const AggWrapper = styled.div`
    width: 100%;
    display: block;
    position: relative;
    z-index: 99;
`;

export const AggWrapperContainer = styled.div`
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.6);
    padding:20px;
    border-radius: 10px;
`;
export const AggWrapperClose = styled.div`
    display: block;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1rem;
`;


// filttos
export const FiltrosWrapper = styled.div`
    display: table;
    margin: 0;
    padding: 0px;
    background-color: white;
    border-radius: ${Theme1.radius};
`;

export const FiltrosMenu = styled.div`
    display: flex;
`;

export const FiltrosWrapperSelect = styled.div`
    padding-right: 10px;
    display: ${props => !props.hidden ? "block": "none"};
    select{
        background-color: white;
        border: ${Theme1.border};
        border-radius: 4px;
        font-size: 14px;
        min-width: 100px;
    }
`;

export const FiltrosLabel = styled.label`
    font-size: 12px;
    color: ${ props => props.color};
    display: block;
    font-weight: bold;
    margin-bottom: 0;
`;
// content modal
export const ModalWrapper = styled.div`
    .modal-sm{
        max-width: 450px;
    }
`;

//home 

export const HomeWrapper = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 20px;
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`;

export const HomeContent = styled.div`
    width: 100%;
    max-width: 1000px;
`;

export const HomeWrapperSelect = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
`;
export const HomeContainerSelect = styled.div`
    width: 48%;
`;

export const HomeCheckWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 10px;
    
`;

export const HomeCheck = styled.div`
    width: 30px;
    height: 30px;
    border: solid 3px #B3B1B2;
    border-radius: 100px;
    padding: 5px;
    span{
        width: 100%;
        height: 100%;
        border-radius: 100px;
        display: block;
    }
`;


export const StylesDiv = styled.div`
    
`;

const animation = keyframes`
 from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
`
export const StyleComentario = styled.button`
    position: fixed;
    bottom: 1rem;
    right: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    color: black;
    font-weight: bold;
    border: none;
    box-shadow: ${Theme1.shadowContainer};
    background-color: white;
    border-radius: 1rem 0 0 1rem;
    padding: 0.5rem;
    justify-content: center;
    font-size:0.8rem;
    transition: width 1s;
    width: 120px;

    &.active {
        height: 64px;
        width: 50px;
        z-index: 20;
        & img {
            animation-name: ${animation};
            animation-duration: 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }
        & span {
            display: none;
        }
    }

    &.visible {
        display:flex;
    }
`;
export const ContainerPage = styled.div`
    position: relative;
`;
export const ContainerEditor = styled.div`
    position: fixed;
    bottom: 16px;
    right: 50px;
    z-index: 10;
    width: 600px;
    height: 90vh;
    max-height: 500px;
    background-color: white;
    box-shadow: ${Theme1.shadowContainer};
    border-radius: 0.5rem;
    padding: 1rem;
    visibility: hidden;
    opacity: 0;
    text-align: center;
    
    &.active {
        visibility: visible;
        transition: all 2s;
        opacity: 1;
    }

    & > button {
        position: absolute;
        bottom: 1rem;
        left: 42%;
    }
`;

export const DivEditor = styled.div`
    text-align: start;
    overflow: auto;
    height: Calc(100% - 60px);
`;

export const DivClose = styled.div`
    text-align: end;

    & img {
        cursor: pointer;
    }
`;

// Breadcrums

export const BreadcrumbWrapper = styled.div`
    margin-top: 1rem;
    .breadcrumb{
        background-color: transparent;
        li{
            a{
                color: ${props => props.colors?.grayText};
                font-size: 14px;

                &:hover {
                    color: ${props => props.colors?.secondary};
                }
            }
            &:last-child{
                a{
                    font-weight: bold;
                    color: ${props => props.colors?.primary};
                }
            }
        }
    }
`;

// PageNotFound

export const PageNotFoundWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;
export const PageNotFoundImage = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 30px;
    img{
        max-width: 115px;
        width: 100%;
        display: inline-block;
    }
`;
export const PageNotFoundTitle = styled.div`
    width: 100%;
    color: ${props => props.color};
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 38px;
    margin-top: 15px;
`;

export const PageNotFoundDescription = styled.div`
    width: 100%;
    color: ${props => props.color};
    text-align: center;
    font-size: 24px;
    margin-top: 0px;
`;

// horizontal seccion 

export const SeccionWrapperHorizontal = styled.div`
    
    &.horizontal-mode{
        display: flex;
        align-items: flex-end;
        padding: 0 10px;
        & > div{
            width: ${props => props.amountModules!== 0 ? 100 / props.amountModules : 100 }%;
            .graphic-custom{
                padding: 0px !important;
            }

            &>div{
                padding: 0px !important;
            }
        }
    }
    &.horizontal-edit{
        display: flex;
        align-items: flex-end;
        padding: 0 10px;
        flex-wrap: wrap;
        justify-content: space-between;
        & > div{
            width: 32.0%;
        }
    }
    
`;