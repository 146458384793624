import { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import Utils from "../utils";
import {AlertForm, ICON} from './../Components/SweetAlert'
import { BgContainer, ButonLink, CtaContainer, IntroContainer, LoginContainer, OptionsContainer, PrimaryBtn, SecondaryBtn, TertiaryBtn } from "./styles";
import { OLD_SITE } from "../config";
import Swal from "sweetalert2";

function Login({logged, setLogged, loginDefaultMessage, setLoginDefaultMessage}){
    
    const [form, setForm] = useState({
        username:'',
        password:'',
        idportal:0,
    });
    
    const [message, setMessage] = useState('');
    const [guess, setGuess] = useState(false);
    const [redirectUrl] = useState(localStorage.getItem('redirect_url') || '');
    const [imgLogin, setImgLogin] = useState("");
    const [urlCentrify, setUrlCentrify] = useState(null);
    const handleInputForm = (event)=>{
        setForm({...form, [event.target.name]:event.target.value});
    }

    const handleLogin = (event)=>{
        event.preventDefault();
        setMessage('');

        if(form.username===''){
            setMessage('Completa tu nombre de usuario');
        }else if(form.password===''){
            setMessage('Completa tu contraseña');
        }else{
            
            if(form.idportal){
                Utils.Petition.publicPost('/users/login', form, (response)=>{
                    Utils.Security.setStartLocalStorage(response, 'Login', ()=>{
                        setLogged(true);
                    })
    
                },
                (error)=>{
                    if(error.response){
                        setMessage(error.response.data.message);
                    }else{
                        setMessage('La aplicación está presentando problemas, por favor intente más tarde');
                    }
                });
            }else{
                AlertForm(ICON.WARNING, 'Para iniciar sesión primero configura correctamente el portal', "", "center", null, 'De acuerdo');
            }
        }
    }
    const handleSSO = ()=>{
        if(form.idportal){
            window.location.href=urlCentrify;
            
        }else{
            AlertForm(ICON.WARNING, 'Para iniciar sesión primero configura correctamente el portal', "", "center", null, 'De acuerdo');
        }
    }
    useEffect(() => {
        if (loginDefaultMessage !== '' ){
            AlertForm(ICON.WARNING, loginDefaultMessage, "", "center", null, 'De acuerdo');
            setLoginDefaultMessage('');
        }
        
    }, [loginDefaultMessage]);

    useEffect(() => {
        const domain = window.location.href.toLowerCase();
        
        if(domain.includes('login')){
            Swal.fire({
                title: 'Cargando...',
                allowOutsideClick: false,
            });
            Swal.showLoading()
            const url = encodeURIComponent(domain.split('/login')[0]);
            localStorage.setItem('site', url);
            Utils.Petition.publicGet('/portal-url/url/'+url, (response)=>{
                if(response && response.data){
                    setForm({...form, idportal:response.data.idportal});
                    setImgLogin(response.data.portal.portal_config.splash);
                    setUrlCentrify(response.data.portal.portal_config.centrify_login);
                }else{
                    AlertForm(ICON.WARNING, 'No se ha configurado correctamente el portal', "", "center", null, 'De acuerdo');
                }
            }, (error) => console.log(error),
            () => Swal.close())
        }
    }, []);

    return (
        <>
            <BgContainer imgLogin={"data:image/png;base64," + imgLogin}/>
            {!logged &&
                <OptionsContainer>
                    
                    {
                        !guess && urlCentrify &&
                        <CtaContainer>
                            <h2>¡Te estamos esperando!</h2>
                            <PrimaryBtn onClick={handleSSO}>Iniciar sesión</PrimaryBtn>
                            <hr/>
                            <SecondaryBtn onClick={()=> setGuess(true)}>Invitados externos</SecondaryBtn>
                        </CtaContainer>
                    }
                    {
                        (guess || !urlCentrify) && 
                        <LoginContainer>
                            <h3>{!urlCentrify ? "Iniciar Sesion" : "Invitado"}</h3>
                            <form onSubmit={handleLogin} autoComplete="off">
                                <input type="text" name="username" id="" value={form.username} onChange={handleInputForm} placeholder="Usuario"/>
                                <input type="password" name="password" id="" value={form.password}  onChange={handleInputForm} placeholder="Contraseña" />
                                {
                                    message!=='' &&
                                    <div className="alert alert-danger" role="alert">
                                        {message}
                                    </div>
                                }
                                <PrimaryBtn type="submit">Ingresar</PrimaryBtn>
                            </form>
                            {urlCentrify  && <TertiaryBtn onClick={()=> setGuess(false)}>Volver</TertiaryBtn>}
                            
                        </LoginContainer>
                    }
                </OptionsContainer>
            }
            {
                logged && (<Redirect to={redirectUrl} />)
            }
            <IntroContainer>
                <h2>Bienvenido</h2>
                <p>Aquí podrás ingresar a la plataforma de Conectándonos con la estrategia, en la cual podrás ver las cifras del desempeño de la empresa en sus diversas áreas.</p>
            </IntroContainer>
        </>

    );
}
export default Login;

