import { useEffect, useState } from 'react';

import Circle from './Circle';
function TendenceSign({value, type, base, styles}){
    const [baseNumber, setBaseNumber] = useState(100);
    const [valueNumber, setValueNumber] = useState(100);
    useEffect(() => {
      if(base === undefined) {
        setBaseNumber(0);
      } else if(Number(base) === "NaN") {
        setBaseNumber(null);
      } else {
        setBaseNumber(Number(base));
      }
    }, [base]);

    useEffect(() => {
        if(value === undefined || value === null) {
          setValueNumber(null);
        } else if(Number(value) === "NaN") {
            setValueNumber(null);
        } else {
            setValueNumber(Number(value));
        }
      }, [value]);
    
    return(
        (valueNumber===null || baseNumber===null)
            ? <Circle color='gray'/>
            :(
                (valueNumber === baseNumber)
                ? <Circle inside="igual" color='green' />
                :(
                    (type === 'C')
                    ?(
                        (valueNumber > baseNumber) 
                        ?<Circle inside="up" color='green' />
                        :<Circle inside="down" color='red' />
                    )
                    :(
                        (valueNumber < baseNumber) 
                        ?<Circle inside="down" color='green' />
                        :<Circle inside="up" color='red' />
                    )
                )
            )

    )
}
export default TendenceSign;