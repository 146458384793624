import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../Context/User";
import Utils from "../utils";
import { Col, Container, Row, Form, ButtonGroup } from 'react-bootstrap';
import GraphicView from './GraphicView';
import { IndicatorHeadContainer, IndicatorConvensiones, IndicatorHeadContain, IndicatorHeadWrapper, IndicatorHeadLabel } from "./styles";
import Swal from "sweetalert2";
import { Loading } from "./Loading";


function IndicatorDetail({ idindicator }) {
  const { colorPalette } = useContext(UserContext);
  const table = {
    "type": "BarChart",
    "title": "Ejemplo",
    "legendPosition":"bottom",
    "indicators": [],
    "completeYear": true,
    "indicatorsComplete": [],
    "stages": [],
    "series": [],
    "columns": [],
    "indicatorsStages": [],
    "option": "currentYear"
  }
  const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ];
  const [dataIndicators, setDataIndicators] = useState([]);
  const [idIndicator, setIdIndicator] = useState(idindicator);
  const [indicator, setIndicator] = useState([]);
  const [infoMes, setCallback] = useState([]);
  const [change, setChange] = useState(true);
  const [dataTable,setDataTable] = useState({...table});
  const [isLoading, setIsLoading] = useState(false);
  const format_number = Utils.getConfigPortal('format_number');
  const format_percent = Utils.getConfigPortal('format_percent');
  const _dataTable = {...table}
  useEffect(() => {
    
    if(change){
      setIsLoading(true);
      Utils.Petition.get(
        '/indicator-relations/id/' + idindicator,
        (response) => {
          let dataI = response.data.map((data) => {
            return data.idindicator
          })
          if (dataI !== [] && dataI.length !== 0) {
            const indicatorValue = {
              indicators: dataI,
              view: "A",//localStorage.getItem('view'), ---- ${localStorage.getItem('view')}
              year: localStorage.getItem('year'),
              month: parseInt(localStorage.getItem('month'))+1
            };
            getColor(indicatorValue)
          }
        }
      );
      Utils.Petition.get('/stages_relation/codes/R,VR,P/type/A', (responseRelations)=>{
        Utils.Petition.get(
          '/indicators/id/' + idIndicator,
          (response) => {
            var stage=[]
            var stages=[]
            var stage1={
              "value": "2 Real acumulado",
              "label": "2 Real acumulado",
              "id": responseRelations.data.filter(relation=>relation.code==='R')[0]?.idstage,
              "name": "Real acumulado",
              "color": "#f5a623",
              "labelStage": "Real Acum"
            };
            var stage2={
              "value": "14 Variación real acumulado %",
              "label": "14 Variación real acumulado %",
              "id": responseRelations.data.filter(relation=>relation.code==='VR')[0]?.idstage,
              "name": "Variación real acumulado %",
              "color": "#000000",
              "type": "line",
              "borderDash": true,
              "otherAxis": true,
              "labelStage": "Var Real"
            }
            stage.push(stage1)
            stage.push(stage2)
            var newDataTable = {
              "value": response.data.id,
              "label": "1_21_00655 Ventas de nuevas categorías carnes marinadas  | Colombia",
              "id": response.data.id,
              "code": response.data.code,
              "name": response.data.name,
              "filter": {
                "id": response.data.id,
                "name": response.data.value
              },
              "complete": 0,
              "color": "#777273",
              "multiStages": true,
              "stage": stage
            }
            var stage3 = {
              "value": "4 Presupuesto acumulado",
              "label": "4 Presupuesto acumulado",
              "id": responseRelations.data.filter(relation=>relation.code==='P')[0]?.idstage,
              "name": "Presupuesto acumulado",
              "color": "#DF7210",
              "labelStage": "Ppto acum"
            };
            
            _dataTable.indicators.push(newDataTable);
            _dataTable.title = '';
            stages.push(stage3)
            var newDataTable2 = {
              "value": response.data.id,
              "label": "1_21_00655 Ventas de nuevas categorías carnes marinadas  | Colombia",
              "id": response.data.id,
              "code": response.data.code,
              "name": response.data.name,
              "filter": {
                "id": response.data.id,
                "name": response.data.value
              },
              "complete": 1,
              "color": "#777273",
              "multiStages": true,
              "stage": stages
            }
            _dataTable.indicatorsComplete.push(newDataTable2)
            // console.log(_dataTable)
            const indicatorValue = {
              indicators: [response.data.id],
              view: "A",// localStorage.getItem('view'),
              year: localStorage.getItem('year'),
              month: parseInt(localStorage.getItem('month'))+1
            };
            if (indicatorValue.indicators.length !== 0 && indicatorValue.indicators !== []) {
              getColor(indicatorValue, "I")
            }
            setDataTable(_dataTable)
        }, (err) => console.log(err),
        () => {
          setIsLoading(false);
        });
        tablaMeses()
        setChange(false);

      });

    }
  }, [change]);


  let dataMes = []

  function tablaMeses() {
    let year = localStorage.getItem('year');
    let lastYear = (parseInt(localStorage.getItem('year')) - 1)
    const data = [['R', year, "Real"], ['CP', year, "Cumplimiento PPTO %"], ['VR', year, "Variación %"]]
    for (let i in data) {
      Utils.Petition.get(`/indicator-value/idindicator/${idIndicator}/stage/${data[i][0]}/view/A/year/${data[i][1]}`,
        (response) => {
          response.data[0].layer = data[i][2]
          dataMes.push(response.data[0])
          if (dataMes.length === 3) {
            setCallback(dataMes)
          }
        })
    }
  }


  function getColor(indicatorValue, type) {
    if (indicatorValue.indicators.length !== 0 && indicatorValue.indicators !== []) {
      Utils.Petition.post('/indicator-value/indicators_table_values',
      indicatorValue,
      (response) => {
        response.data.map((data, index) => {
          var real = 0;
          var ppto = 0;
          var rf = 0;
          if (data.real !== null) { real = parseFloat(data.real.replace(".", "").replace(",", ".")) };
          if (data.ppto !== null) { ppto = parseFloat(data.ppto.replace(".", "").replace(",", ".")) };
          if (data.rf !== null) { rf = parseFloat(data.rf.replace(".", "").replace(",", ".")) };
          var color = ""
          var rfColor = ""
          if (real === 0 && ppto === 0) {
            color = "gris"
            response.data[index].real = "0.00"
            response.data[index].ppto = "0.00"
          } else
            if (real === ppto) { color = "verde"; }
            else {
              if (data.type === "C") {
                if (real > ppto) { color = "verde" }
                else { color = "rojo" }
              } else {
                if (real > ppto) { color = "rojo" }
                else { color = "verde" }
              }
            }

          if (real === 0 && rf === 0) {
            rfColor = "gris"
            response.data[index].real = "0.00"
            response.data[index].rf = "0.00"
          } else
            if (real === rf) { rfColor = "verde"; }
            else {
              if (data.type === "C") {
                if (real > rf) { rfColor = "verde" }
                else { rfColor = "rojo" }
              } else {
                if (real > rf) { rfColor = "rojo" }
                else { rfColor = "verde" }
              }
            }
          response.data[index].color = color
          response.data[index].rfColor = rfColor
        })
        if (type === "I" && idindicator == idIndicator) {
          setIndicator(response.data[0])
        } else {
          setDataIndicators(response.data)
        }
      })
    }
  }

  const changeIndicator = (id) => {
    if(id === idIndicator) return;
      setIdIndicator(id);
      setChange(true)
  }
  return (
    <div>
      <Container fluid>
        <IndicatorHeadWrapper>
          <IndicatorConvensiones>
              <div style={{ display: "inline-block" }}>Convenciones: </div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail verde"></i><span>Cumple</span></div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail rojo"></i><span>No cumple</span></div>
          </IndicatorConvensiones>
          <IndicatorHeadContain>
            <IndicatorHeadContainer Wd="30%" colors={colorPalette}>
              <div>
                <IndicatorHeadLabel  colors={colorPalette}>Hecho</IndicatorHeadLabel>
                <p style={{ textAlign: "center", marginRight: "15px" }}><i className={"circle-color-detail " + indicator.color}></i>{Utils.formatNumber(format_number,indicator.real)}</p>
              </div>
            </IndicatorHeadContainer>
            <IndicatorHeadContainer Wd="70%" colors={colorPalette}>
              <div>
                <IndicatorHeadLabel  colors={colorPalette}>Detalle Indicadores</IndicatorHeadLabel>
                <p onClick={() => changeIndicator(indicator.id)}><i className={"circle-color-detail " + indicator.color}></i>{indicator.name}</p>
                <ul>
                  {
                    dataIndicators.map((indicator, index) => {
                      return (<>
                        <p onClick={() => changeIndicator(indicator.id)}><i className={"circle-color-detail " + indicator.color} ></i>{indicator.name}</p>
                      </>)
                    })
                  }
                </ul>
              </div>
            </IndicatorHeadContainer>
          </IndicatorHeadContain>
        </IndicatorHeadWrapper>
        <IndicatorHeadWrapper>
          <IndicatorHeadContain>
            <IndicatorHeadContainer Wd="100%" colors={colorPalette}>
              <div>
                <IndicatorHeadLabel  colors={colorPalette}>Grafica</IndicatorHeadLabel>
                {isLoading && <Loading text="Cargando Grafica..." /> }
                  {!isLoading && dataTable.indicators.length > 0 && <GraphicView config={dataTable}/>}  
                  
              </div>
            </IndicatorHeadContainer>
          </IndicatorHeadContain>
        </IndicatorHeadWrapper>
        <IndicatorHeadWrapper>
          <IndicatorHeadContain>
            <IndicatorHeadContainer Wd="100%" colors={colorPalette}>
              {isLoading && <Loading text="Cargando Tabla..." /> }
              {!isLoading && 
                <div>
                <table className="table table-bordered" style={{ fontSize: "12px" }} >
                  <thead>
                    <th></th>
                    {
                      months.map((month,i)=> <th className={parseInt(localStorage.getItem('month'))+1===i.toString() ? 'activo':null}>{month}</th>)
                    }
                  </thead>
                  <tbody>
                    {
                      !change &&
                      infoMes.map((data, index) => {
                        return (<>
                          <tr>
                            <th>{data.layer}</th>
                            {
                              months.map((month,i)=> <td className={parseInt(localStorage.getItem('month'))+1===i.toString() ? 'activo':null}>{Utils.formatNumber(format_number,data[month])}</td>)
                            }
                          </tr>
                        </>)
                      })
                    }
                  </tbody>
                </table>
              </div>
              }
              
            </IndicatorHeadContainer>
          </IndicatorHeadContain>
        </IndicatorHeadWrapper>
      </Container>
      <Container fluid>
      </Container>
    </div>
  );
}

export default IndicatorDetail;