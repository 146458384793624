import React, { useState, useEffect, useRef, useContext } from "react";

//Editor of hell component imports and Styles

import { EditorText } from "./Editor";
import { Text } from "./Editor/text";
import { ContainerCustom } from "./Editor/styles";
import { Button } from "../../commonStyles";
import { UserContext } from "../../Context/User";
import "./View.css";

function Edit({ globalParams, page, module,everypages, filter }) {
  const { validateShowComponent, colorPalette } = useContext(UserContext);
  const [content, setContent] = useState("");
  const showEditor = useRef(null);
  const showText = useRef(null);
  const textbtn = useRef(null);
  const textbtn2 = useRef(null);

  //Loads info saved in the dataBase
  useEffect(() => {
    setContent(module.config.data)
    document.addEventListener('click', handleAccordion);
    return () => document.removeEventListener("click", handleAccordion);
  }, [])

  const handleEditor = () => {
    showEditor.current.classList.toggle("active");
    showText.current.classList.toggle("active");
    textbtn.current.classList.toggle("btn-inactive");
    textbtn2.current.classList.toggle("btn-inactive");
  };

  const handleAccordion = (e) => {
    if(e.target.classList.contains('titulo-acrodeon') || e.target.classList.contains('desplegable')){
        e.preventDefault();
        let id = e.target.parentNode.querySelector('.desplegable').getAttribute('href').replace('#', '');
        let $id = document.getElementById(id);
        if ($id.classList.contains('show') ) {
          $id.style.opacity = 0;
          $id.style.transform = 'scale(0)';
          
            window.setTimeout(function(){
              $id.classList.remove('show');
              $id.classList.add('hide');
              $id.style.display = 'none';
            },700); // timed to match animation-duration
          }
          else {
            $id.style.display = 'block';
            window.setTimeout(function(){
              $id.classList.remove('hide');
              $id.classList.add('show');
              $id.style.opacity = 1;
              $id.style.transform = 'scale(1)';
            },0);
          }
    }
}
  return (
    <>
      <ContainerCustom ref={showEditor}>
        <EditorText setContent={setContent} content={content} page={page} idModule={module.id} />
      </ContainerCustom>
      <Text showText={showText} content={content} />

      {(module.idpopup !== null || validateShowComponent(everypages, filter)) &&
       <Button color={colorPalette.primary} onClick={handleEditor} className="text-left mx-2">
        <span ref={textbtn}>Editar</span>
        <span className="btn-inactive" ref={textbtn2}>
          Ver
        </span>{" "}
        texto
      </Button>}

    </>
  );
}

export default Edit;
