import styled from "styled-components";
import { Theme1 } from "../../../themes/Theme1";

export const ShowChange = styled.div`
    display: block;
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 20px;
    border-radius: ${Theme1.radius};
    background-color: white;
    box-shadow: ${Theme1.shadow};
    font-size: 20px;
    cursor: pointer;
    color: ${Theme1.grayText};
    &:hover{
        box-shadow: 0px 0px 20px 3px ${props => props.color}20;
        color: ${props => props.color};
    }
`;

export const ChangeWrapper = styled.div`
    display: ${props => props.showChage ? 'block' : 'none'};
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: white;
    padding: 20px;
    border-radius: ${Theme1.radius};
    box-shadow: ${Theme1.shadow};
    width: 100%;
    max-width: 334px;
    z-index: 4;
    cursor: pointer;
    &>div{
        &:first-child{
            margin-bottom: 10px;
        }
        width: 100%;
        border-radius:${Theme1.radius};
        box-shadow: ${Theme1.shadow} !important;
        background-color: white;
        padding: 20px;
        display: block;
        img{
            display: block;
            width: 100%;
        }
        .head{
            display: flex;
            width: 100%;
            align-items: center;
            margin-bottom: 10px;
            .check{
                width: 20px;
                height: 20px;
                border: solid 2px #B3B1B2;
                border-radius: 100px;
                padding: 5px;
                span{
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    display: block;
                }
            }
            .title{
                font-size: 12px;
                font-weight: bold;
                color: ${Theme1.grayText};
                width: 100%;
                padding-left: 10px;
            }
        }
        &:hover>div, &.active>div{
                &>div{
                    color: ${props => props.color} !important;
                    &.check{
                        border-color: ${props => props.color} !important;
                        span{
                        background-color: ${props => props.color} !important;
                    }
                    }
                    
                }
            }
    }
`;
export const ChangeOverlay = styled.div`
    display: ${props => props.showChage ? 'block' : 'none'};
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: transparent;
    z-index: 3;
`;