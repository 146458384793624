import { useEffect } from "react";
import { useParams } from "react-router";
import Utils from "../utils";

function RedirectAdminComponent(){
    const {url} = useParams();
    useEffect(()=>{
        window.location.href=Utils.getAdminUrl(url, true);
    }, []);
    return (<div>Redireccionando...</div>);
}
export default RedirectAdminComponent;