import { FaChevronDown, FaChevronUp, FaGripLines } from "react-icons/fa";

function Circle (props) {
    const styles = {
        width: props.inside ? '40px' : "12px", 
        height: props.inside ? '40px' : "12px", 
        borderRadius: "50%", 
        border: "1px solid #ccc", 
        color:"white", 
        display: "inline-flex",
        paddingLeft:'4px',
        paddingRight:'4px',
        background: props.color,
        alignItems:'center',
        marginRight: "4px",
        justifyContent: 'center',
    };
    return(
        <span style={styles}>
            {
                props.inside!==undefined &&
                (
                    props.inside==='igual'
                    ? <FaGripLines />
                    : props.inside==='up'
                    ? <FaChevronUp />
                    : <FaChevronDown />
                )
            }
        </span>
    )
}

export default Circle;
