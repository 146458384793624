import React, { useState } from "react";
import Plantillas from "./functions/Plantillas";
import { Link } from "react-router-dom";

function View({ page, module }) {
  let [data, setData] = useState(module.config.data);

  if (data) {
    return (
      <div className="container">
        <Plantillas config={data} />
      </div>
    );
  } else {
    return (
          <div className="container">
            <i>Sin ningún contenido</i>
          </div>
    );
  }
}
export default View;

/*

<>
          <div className="container">
            <h2>{data.headerTemplate}</h2>
            <p>{data.descriptionTemplate}</p>
            <img
              style={{ width: "300px" }}
              src={`${API_MEDIA_URL}${data.imageTemplate}`}
              alt="ImagenTemplate"
            />
            <div>
              <Link to={data.linkTemplate}>{data.linkDescription}</Link>
            </div>
          </div>
        </>

*/