import { useEffect, useState, useRef, useContext, useMemo } from "react";
import { Container, Breadcrumb } from "react-bootstrap";
import { Button } from "../commonStyles";
import Utils from "../utils";
import CustomPageComponent from "./custom-page-components/CustomPageComponent";
import CustomPageComponentGroup from "./custom-page-components/CustomPageComponentGroup";
import AdminPopups from "./custom-page-components/popups/admin/AdminPopups";
import Popup from "./custom-page-components/popups/view/Popup";
import { AggButtonWrapper, AggWrapper, AggWrapperContainer, AggWrapperClose, PageIdWrapper, StyleComentario, ContainerEditor, ContainerPage, DivEditor, DivClose, BreadcrumbWrapper } from "./styles";
import { FaPlus } from "react-icons/fa"
import { Helmet } from 'react-helmet'
import AdminComments from "./custom-page-components/comments/admin/AdminComments";
import React from "react";
import { EditorText } from "./Editor";
import { Text } from "./Editor/text";
import { ContainerCustom } from "./Editor/styles";
import { FaHome } from "react-icons/fa";
import AdminFilters from "./custom-page-components/hide-filters/admin/AdminFilters";
import CopyModules from "./custom-page-components/copy-modules/admin/CopyModules";
import { UserContext } from "../Context/User";
import { registerVisit } from "../utils/RegisterVisit";
import { counter } from "../App";

function PageRoute({ globalProps, page, ComponentTagName }) {
    const { hasPermission, colorPalette } = useContext(UserContext);
    const [popupStructure, setPopupStructure] = useState({
        id: 0,
        show: false,
        title: ''
    });
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const [showCustomPageComponent, setShowCustomPageComponent] = useState(false);
    
    const [loadedPopup, setLoadedPopup] = useState(false);
    const [loadedPopupIzquierda, setLoadedPopupIzquierda] = useState(false);
    const [loadedPopupDerecha, setLoadedPopupDerecha] = useState(false);
    const [loadedPopupIzquierdaA, setLoadedPopupIzquierdaA] = useState(false);
    const [loadedPopupDerechaA, setLoadedPopupDerechaA] = useState(false);
    const [loadedPopupIzquierdaB, setLoadedPopupIzquierdaB] = useState(false);
    const [loadedPopupCentroB, setLoadedPopupCentroB] = useState(false);
    const [loadedPopupDerechaB, setLoadedPopupDerechaB] = useState(false);
    const [loadedPopupCentroC, setLoadedPopupCentroC] = useState(false);
    
    const [content, setContent] = useState("");
    const btnComment = useRef(null);
    const showComment = useRef(null);
    const showEditor = useRef(null);
    const showText = useRef(null);
    const textbtn = useRef(null);
    const textbtn2 = useRef(null);

    const templateZonesLoading = {
        popup: { loaded: loadedPopup, setLoaded: setLoadedPopup },
        popupIzquierda: { loaded: loadedPopupIzquierda, setLoaded: setLoadedPopupIzquierda },
        popupDerecha: { loaded: loadedPopupDerecha, setLoaded: setLoadedPopupDerecha },
        popupIzquierdaA: { loaded: loadedPopupIzquierdaA, setLoaded: setLoadedPopupIzquierdaA },
        popupDerechaA: { loaded: loadedPopupDerechaA, setLoaded: setLoadedPopupDerechaA },
        popupIzquierdaB: { loaded: loadedPopupIzquierdaB, setLoaded: setLoadedPopupIzquierdaB },
        popupCentroB: { loaded: loadedPopupCentroB, setLoaded: setLoadedPopupCentroB },
        popupDerechaB: { loaded: loadedPopupDerechaB, setLoaded: setLoadedPopupDerechaB },
        popupCentroC: { loaded: loadedPopupCentroC, setLoaded: setLoadedPopupCentroC },
    };

    const containerComponent = {setShowCustomPageComponent}
    useEffect(() => {
        let timeActive = 0;
        let timeActiveInitial =  performance.now();
        let timeActiveFinal;
        let isInactiveByTime = false;
        let tiempoInactividad = JSON.parse(localStorage.getItem("config")).tiempo_inactividad;
        tiempoInactividad = Number(tiempoInactividad) * 60000;
        const handleVisibility = () => {
            let visibilityState = document.visibilityState
           
            if (visibilityState === 'hidden') {  // Cuando la página se en segundo plano
                timeActiveFinal = performance.now();
                if(isInactiveByTime) timeActiveInitial = timeActiveFinal;
                timeActive += (timeActiveFinal - timeActiveInitial);
            }
            if (visibilityState === 'visible') {   // Cuando la página está octivo
                timeActiveInitial = performance.now();
            }
        }
        document.addEventListener("visibilitychange", handleVisibility);
        let programarTiempo;
        const inactivityByTime = () => {
                
            let amountEndpoint = counter.number;
            
            timeActiveFinal = performance.now();
            timeActive += (timeActiveFinal - timeActiveInitial);
            registerVisit(timeActive, amountEndpoint, true);
            let idUserPlatform = Date.parse(new Date());
            localStorage.setItem("idUserPlatform", idUserPlatform);
            timeActive = 0;
            isInactiveByTime = true;
        }
        programarTiempo = setTimeout(inactivityByTime, tiempoInactividad);
        const handleClickEvent = (e) => {
                if(programarTiempo) clearTimeout(programarTiempo);
                programarTiempo = setTimeout(inactivityByTime, tiempoInactividad);
                console.log({timeActive})
                if(isInactiveByTime) {
                    timeActiveInitial =  performance.now();
                    isInactiveByTime = false;
                }
        } 
        
        let $mainContent = document.getElementById("main-content");
        $mainContent.addEventListener("click", handleClickEvent);
        $mainContent.addEventListener("scroll",handleClickEvent);
        

        /* cierre de ventana, pestaña o recarga la pagina*/
        let handleBeforeUnload = (e) => {
            let amountEndpoint = counter.number;
            registerVisit(timeActive, amountEndpoint, false);
            delete e['returnValue'];
        }
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            timeActiveFinal = performance.now();
            timeActive += (timeActiveFinal - timeActiveInitial);
            let amountEndpoint = counter.number;
            clearTimeout(programarTiempo);
            $mainContent.removeEventListener("click", handleClickEvent)
            $mainContent.removeEventListener("scroll", handleClickEvent);
            document.removeEventListener("visibilitychange", handleVisibility);
            window.removeEventListener("beforeunload", handleBeforeUnload);
            registerVisit(timeActive, amountEndpoint, false);
        }
    }, [])
    
    useEffect(() => {
        /* debugger; */

        let _filters = JSON.parse(localStorage.getItem('filters'));
        
        Utils.removeOptions(globalProps.filterTag.current);
        for (let i = 0; i < _filters.length; i++) {
            globalProps.filterTag.current.add(new Option(_filters[i].description, _filters[i].value))
        }
        globalProps.filterTag.current.value=localStorage.getItem('idfilter');
        if(page.hide_filters.length){
            let useDefault=false;
            for(let i in page.hide_filters){
                for (let j=0; j<globalProps.filterTag.current.options.length; j++) {
                    
                    if (globalProps.filterTag.current.options[j].value === page.hide_filters[i].idfilter.toString()){
                        if( globalProps.filterTag.current.options[j].value===globalProps.filterTag.current.value && globalProps.filterTag.current.value ===localStorage.getItem('idfilter')){
                            useDefault=true;
                        }
                        globalProps.filterTag.current.remove(j);
                        break;
                    }
                }
            }
            if(useDefault){
                localStorage.setItem('idfilter',localStorage.getItem('defaultFilter'))
                globalProps.filterTag.current.value= localStorage.getItem('defaultFilter');
            }

        }
        localStorage.setItem("pageName",  page.name );
       
        Utils.Petition.get(`/pages/breadcrumbs/portal/${localStorage.getItem('idportal')}/url/${encodeURIComponent(page.path)}`, (response) => {
            setBreadcrumbs(response.data);
        });
        Utils.Petition.get('/comments/type-model/page/type-id/' + page.id + '/year/' + localStorage.getItem('year') + '/month/' + localStorage.getItem('month')+ '/view/' + localStorage.getItem('view')+'/idfilter/'+localStorage.getItem('idfilter'),
            (response) => {
                setContent(response.data?.comment);
            })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const handleMenuCustomPageComponent = () => {
        setShowCustomPageComponent(!showCustomPageComponent);
    }

    const handleComments = () => {
        btnComment.current.classList.toggle('active');
        showComment.current.classList.toggle('active');
        showText.current.classList.add('active');
        showEditor.current.classList.remove('active');
        if(hasPermission.edit){
            textbtn.current.classList.remove('btn-inactive');
            textbtn2.current.classList.add('btn-inactive');
        }
    }

    const handleEditor = () => {
        showEditor.current.classList.toggle('active');
        showText.current.classList.toggle('active');
        textbtn.current.classList.toggle('btn-inactive');
        textbtn2.current.classList.toggle('btn-inactive');
    }

    return (
        <ContainerPage>
            {
                globalProps.editMode &&
                <>
                    <Helmet> <title>{`CCE | ${page.name}`}</title> </Helmet>
                    <Container fluid>
                        <AggButtonWrapper>
                            <Button color={colorPalette.primary} onClick={handleMenuCustomPageComponent}>Agregar componente personalizado <FaPlus /></Button>
                        </AggButtonWrapper>

                        {
                            showCustomPageComponent &&
                            <AggWrapper>
                                <AggWrapperContainer>
                                    <AggWrapperClose>
                                        <Button color={colorPalette.primary} onClick={handleMenuCustomPageComponent} type="button"><span aria-hidden="true">X</span><span className="sr-only">Close</span></Button>
                                    </AggWrapperClose>

                                    <CustomPageComponentGroup>
                                        <CustomPageComponent name="Popup" selectedComponent={() => <AdminPopups templateZonesLoading={templateZonesLoading} setPopupStructure={setPopupStructure} page={page} containerComponent={containerComponent}/>} />
                                        <CustomPageComponent name="Comentarios" selectedComponent={() => <AdminComments page={page} containerComponent={containerComponent}/>} />
                                        <CustomPageComponent name="Administrar regiones" selectedComponent={() => <AdminFilters page={page} containerComponent={containerComponent}/>} />
                                        <CustomPageComponent name="Copiar modulos" selectedComponent={() => <CopyModules page={page} globalProps={globalProps} containerComponent={containerComponent}/> } />
                                    </CustomPageComponentGroup>
                                </AggWrapperContainer>
                            </AggWrapper>
                        }
                    </Container>
                </>
            }
            <Popup
                globalProps={globalProps}
                setPopupStructure={setPopupStructure}
                popupStructure={popupStructure}
                templateZonesLoading={templateZonesLoading}
                page={page}
            />
            <BreadcrumbWrapper colors={colorPalette}>
            <Breadcrumb>
                <Breadcrumb.Item href="/"><FaHome /> Inicio</Breadcrumb.Item >
                {
                    breadcrumbs.map((item, i) => (
                        <Breadcrumb.Item key={i} href={item.page.path}>
                            {item.page.name}
                        </Breadcrumb.Item>
                    ))
                }

            </Breadcrumb></BreadcrumbWrapper>
            
            <PageIdWrapper className={"page-" + page.id}>
                <ComponentTagName setPopupStructure={setPopupStructure} globalProps={globalProps} page={page} templateZonesLoadingPageRoute={templateZonesLoading} />
            </PageIdWrapper>
            <StyleComentario ref={btnComment} onClick={handleComments} className={page.comment === "1" && "visible"}>
                <img src="/assets/Icon-flecha-izquierda.svg" alt="arrow" />
                <span>Comentarios</span>
            </StyleComentario>
            <ContainerEditor ref={showComment}>
                <DivClose className="text-right">
                    <img src="/assets/Icon-cerrar.svg" alt="close" onClick={handleComments} />
                </DivClose>
                <DivEditor>
                    <ContainerCustom ref={showEditor}>
                        <EditorText setContent={setContent} content={content} page={page} />
                    </ContainerCustom>
                    <Text showText={showText} content={content} />
                </DivEditor>

                {   
                    hasPermission.edit &&
                    <Button color={colorPalette.primary} onClick={handleEditor}>
                        <span ref={textbtn} >Editar</span><span className="btn-inactive" ref={textbtn2} >Ver</span> texto
                    </Button>
                }
            </ContainerEditor>
        </ContainerPage>
    )
}
export default PageRoute;