import { useContext, useEffect, useState } from 'react';
import Popup from '../base/custom-page-components/popups/view/Popup';
import Section from '../base/Section';
import { UserContext } from '../Context/User';
import { SubContainer } from './styles';

function SalesContribution({ globalProps, page, setPopupStructure, templateZonesLoadingPageRoute }) {
    const { setPageId } = useContext(UserContext);
    const [loadedCentroContribucionA, setLoadedCentroContribucionA] = useState(false);

    const [loadedIzquierdaContribucionB, setLoadedIzquierdaContribucionB] = useState(false);
    const [loadedDerechaContribucionB, setLoadedDerechaContribucionB] = useState(false);

    const [loadedCentroContribucionC, setLoadedCentroContribucionC] = useState(false);
    const [loadedIzquierdaContribucionC, setLoadedIzquierdaContribucionC] = useState(false);
    const [loadedDerechaContribucionC, setLoadedDerechaContribucionC] = useState(false);
    const [loadedIzquierdaContribucionD, setLoadedIzquierdaContribucionD] = useState(false);
    const [loadedDerechaContribucionD0, setLoadedDerechaContribucionD0] = useState(false);
    const [loadedDerechaContribucionD, setLoadedDerechaContribucionD] = useState(false);
    const [loadedDerechaContribucionD2, setLoadedDerechaContribucionD2] = useState(false);

    const [loadedCentroContribucionE0, setLoadedCentroContribucionE0] = useState(false);
    const [loadedCentroContribucionE, setLoadedCentroContribucionE] = useState(false);
    const [loadedCentroContribucionF, setLoadedCentroContribucionF] = useState(false);

    useEffect(() => {
        setPageId(page.id);
    }, [page])
    const templateZonesLoading = {
        centroContribucionA: { loaded: loadedCentroContribucionA, setLoaded: setLoadedCentroContribucionA },
        izquierdaContribucionB: { loaded: loadedIzquierdaContribucionB, setLoaded: setLoadedIzquierdaContribucionB },
        derechaContribucionB: { loaded: loadedDerechaContribucionB, setLoaded: setLoadedDerechaContribucionB },
        centroContribucionC: { loaded: loadedCentroContribucionC, setLoaded: setLoadedCentroContribucionC },
        izquierdaContribucionC: { loaded: loadedIzquierdaContribucionC, setLoaded: setLoadedIzquierdaContribucionC },
        derechaContribucionC: { loaded: loadedDerechaContribucionC, setLoaded: setLoadedDerechaContribucionC },
        izquierdaContribucionD: { loaded: loadedIzquierdaContribucionD, setLoaded: setLoadedIzquierdaContribucionD },
        derechaContribucionD0: { loaded: loadedDerechaContribucionD0, setLoaded: setLoadedDerechaContribucionD0 },
        derechaContribucionD: { loaded: loadedDerechaContribucionD, setLoaded: setLoadedDerechaContribucionD },
        derechaContribucionD2: { loaded: loadedDerechaContribucionD2, setLoaded: setLoadedDerechaContribucionD2 },
        centroContribucionE0: { loaded: loadedCentroContribucionE0, setLoaded: setLoadedCentroContribucionE0 },
        centroContribucionE: { loaded: loadedCentroContribucionE, setLoaded: setLoadedCentroContribucionE },
        centroContribucionF: { loaded: loadedCentroContribucionF, setLoaded: setLoadedCentroContribucionF },
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroContribucionA" />
                    </div>
                </div>
                
                <SubContainer>
                    <div className="row" style={{paddingTop:"15px"}}>
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaContribucionB" />
                        </div>
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaContribucionB" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroContribucionC" />
                        </div>
                    </div>
                    
                    <div className="row">
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaContribucionC" />
                        </div>
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaContribucionC" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-3">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaContribucionD" />
                        </div>
                        <div className="col-9">
                            <Section setPopupStructure={setPopupStructure} setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaContribucionD0" />
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaContribucionD" />
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaContribucionD2" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroContribucionE0"/>
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroContribucionE" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroContribucionF" />  
                        </div>
                    </div>
                </SubContainer>



            </div>
        </>

    );
}

export default SalesContribution;