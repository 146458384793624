import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CENTRIFY_LOGOUT } from "../config";
import Utils from "../utils";

function Logout({setLogged}){
    let history = useHistory();
    useEffect(()=>{
        const w = window.open(CENTRIFY_LOGOUT,"Cerrando sesión", "height=50,width=50");
        setTimeout(()=>w.close(), 3000);
        localStorage.clear();
        setLogged(false);
        history.push('/');

    }, []);
    return (null)
}
export default Logout;