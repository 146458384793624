import { useContext, useEffect, useState } from "react";
import { styleTheme } from "../../../../themes/themeSelect";
import Utils from "../../../../utils";
import AsyncSelect from 'react-select/async';

import { FaSave } from "react-icons/fa";
import { WrapperButton } from "../../styles";
import ListModules from "./ListModules";
import { AlertForm, ICON } from "../../../../Components/SweetAlert";
import { Button } from "../../../../commonStyles";
import { UserContext } from "../../../../Context/User";

function CopyModules({page,globalProps,containerComponent}) {
    const { colorPalette } = useContext(UserContext);
    const [filters, setFilters] = useState([]);
    const [Allpages, setAllpages] = useState([]);
    const [pageSeleted, setPageSeleted] = useState({})
    const [pageTemplateActual, setPageTemplateActual] = useState(page.template);
    const [showListModule, setShowListModule] = useState(false);
    const [filterOfModules, setFilterOfModules] = useState({});
    const [templates, setTemplates] = useState([]);
    const [form, setForm] = useState({
        page:null,
        filter:null,
        month:null,
        year:""
    });
    const filter_name = Utils.getConfigPortal('filter_name');
    const months = ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre','Todos'];
    useEffect(()=>{
        Utils.Petition.get('/portals-filter', (response) => {
            let _filter = [];
            for (let i = 0; i < response.data.length; i++) {
                _filter.push({ value: response.data[i].filter.id.toString(), description: response.data[i].filter.name, default: response.data[i].default})
            }
            setFilters(_filter);
        });
        Utils.Petition.get('/pages/portal/'+localStorage.getItem('idportal'),(response) => {
            setAllpages(response.data)
        })
        setShowListModule(false);
        Utils.Petition.get('/templates/',(response) => {
            setTemplates(response.data)
        })
        
    }, []);

    const loadPages = (inputValue) => {
        if (inputValue.length > 2) {
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    const searchPages = [];                    
                    Allpages.filter(e => e.name.toLowerCase().includes(inputValue.toLowerCase())).map((el) => {
                        const objPage = {}
                        objPage.value = el.id;
                        objPage.label = el.name;
                        searchPages.push(objPage);
                    })
                    resolve(searchPages)
                    
                },100)
            })
        }
        return new Promise((resolve) => resolve([]));
    }

    const loadFilter = (inputValue) => {
        if (inputValue.length > 2) {
            return new Promise((resolve)=>{
                setTimeout(()=>{
                    const searchFilter = [];                    
                    filters.filter(e => e.description.toLowerCase().includes(inputValue.toLowerCase())).map((el) => {
                        const objPage = {}
                        objPage.value = el.value;
                        objPage.label = el.description;
                        searchFilter.push(objPage);
                    })
                    resolve(searchFilter)
                    
                },100)
            })
        }
        return new Promise((resolve) => resolve([]));
    }

    const LoadMonths = () => {
        return new Promise((resolve)=>{
            setTimeout(()=>{
                const monthsResponse = months.map((e, idx) => {
                   return({
                    value: idx,
                    label: e
                   })
                })
                resolve(monthsResponse)
                
            },100)
        })
    }
    const handleform = (event) => {
        if(event && event.target){
            setForm({...form,[event.target.name]:event.target.value === "" ? null : event.target.value})
        }
    }
    const ListModule = (event) => {
        event.preventDefault();
        if(validate()){
    
            setFilterOfModules({
                page:form.page.value,
                filter:form.filter.value,
                month:form.month === null ? null : form.month.value,
                year:form.year === "" ? null : form.year
            });
            setShowListModule(true);
        }
        
    }
    const backModule = () => {
        setShowListModule(false);
    }

    const validate = () => {
        if(form.page === null){
            AlertForm(ICON.WARNING, 'Selecciona una página');
            return false;
        }
        if(form.filter === null){
            AlertForm(ICON.WARNING, 'Selecciona una región');
            return false;
        }
        return true;
    }

    useEffect(() => {
        if(form.page != null){
            Utils.Petition.get('/pages/id/'+form.page.value, (response) => {
                setPageSeleted(response.data[0]);
            })
        }
        
    }, [form.page])

    const changeTemplate = () => {
        if(form.page != null && pageSeleted.template != undefined){
            Utils.Petition.post('/pages/update', {
                id: page.id,
                template: pageSeleted.template
            },(response) => {
                    setPageTemplateActual(pageSeleted.template)
                    AlertForm(ICON.SUCCESS, response.message, "");
                }
            );
        }
        
    }


    return(
        <>
        <h3>Copiar Modulos</h3>
        <span> <span>Todos los módulos seleccionados se copiarán en la pagina </span> <b>{Allpages.filter(e => e.path === page.path)[0]?.name }</b> <span>en: <br/> {filter_name} &gt; </span> <b>{ filters.filter(e => e.value === localStorage.getItem('idfilter'))[0]?.description }</b> </span>
        {
            !showListModule &&
            <form onSubmit = {ListModule}>
                <div className="form-group mt-3">
                    <label><b>Pagina *</b></label>
                    <AsyncSelect
                        value={form.page}
                        defaultOptions
                        isClearable
                        placeholder="Nombre de la página"
                        loadOptions={loadPages}
                        onChange={(e) => { e !== null ? setForm({ ...form, page:e }) : setForm({ ...form, page:null}) }}
                        theme={theme => (styleTheme(theme, colorPalette?.primary))}
                    />
                </div>
                <div className="form-group">
                {
                    pageSeleted.template !== undefined && pageSeleted.template !== pageTemplateActual &&
                    <div className="d-flex">
                        <p className="mr-2 mt-2">Para continuar debes cambiar el diseño de página a <b>{templates.filter(e => e.code === pageSeleted.template)[0].name }</b>. </p>
                        <Button color={colorPalette.primary} onClick={changeTemplate}>Cambiar diseño</Button>
                    </div>
                }
                </div>
                <div className="form-group">
                    <label><b>{filter_name} *</b></label>
                    <AsyncSelect
                        value={form.filter}
                        cacheOptions
                        defaultOptions
                        isClearable
                        placeholder={filter_name}
                        loadOptions={loadFilter}
                        onChange={(e) => { e !== null ? setForm({ ...form, filter:e }) : setForm({ ...form, filter:null}) }}
                        theme={theme => (styleTheme(theme, colorPalette?.primary))}
                        required
                    />
                </div>
                <div className="form-group">
                    <label><b>Mes</b></label>
                    <AsyncSelect
                        value={form.month}
                        cacheOptions
                        defaultOptions
                        isClearable
                        placeholder="Mes"
                        loadOptions={LoadMonths}
                        onChange={(e) => { e !== null ? setForm({ ...form, month:e }) : setForm({ ...form, month:null}) }}
                        theme={theme => (styleTheme(theme, colorPalette?.primary))}
                    />            
                </div>
                <div className="form-group">
                    <label><b>Año</b></label>
                    <input
                        type="text"
                        name="year"
                        className="form-control"
                        placeholder="Año"
                        onChange={handleform}
                        value={form.year}
                    />
                </div>
                <div>
                    <Button color={colorPalette.primary} type="submit" disabled = {pageSeleted?.template !== pageTemplateActual && "disabled"}>
                        Siguiente 
                    </Button>
                </div>
            </form>
        }
        {
            showListModule &&
            <>
            <ListModules page={page} globalProps={globalProps} filter={filterOfModules} containerComponent={containerComponent} ></ListModules>
            <div>
                <Button color={colorPalette.primary} type="button" onClick={backModule} >
                    Atras 
                </Button>
            </div>
            </>
        }
        
        
        
        </>
    )
}
export default CopyModules;