import React, { useState, useEffect } from "react";
import Utils from "../../utils";
let url = "";
function IndicatorValue({ idIndicator, idStage }) {
  const [totalValue, setTotalValue] = useState();
  //TODO: Sólo se usa format number, permitir cambiar a format_percent desde la edición
  const format_number = Utils.getConfigPortal('format_number');
  const format_percent = Utils.getConfigPortal('format_percent');

  let year = localStorage.getItem("year");
  let month = (1+parseInt(localStorage.getItem("month"))).toString();
  let date = `${year}-${month < 10 ? 0 + month : month}-01`;


  useEffect(() => {
    url = `/indicator-value/indicator/${idIndicator}/stage/${idStage}/range/${date}/${date}`;

    Utils.Petition.get(
      url,
      (res) => {
        if (res.data[0].value === null) {
          setTotalValue("0");
        } else {
          setTotalValue(res.data[0].value);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const valueFormat = (value) => {
    return Utils.formatNumber(format_number, value);
  };

  return <span>{valueFormat(totalValue)}</span>;
}

export default IndicatorValue;
