import styled from "styled-components";
import { Theme1 } from "./../../themes/Theme1";

export const GraphicCustomWrapper = styled.div`
    width: 100%;
    padding: 0 10px;
    margin-bottom: 30px;
`;
export const GraphicCustomWrapperContent = styled.div`
    display: block;
    width: 100%;
`;
export const GraphicCustomContainer = styled.div`
    width: 100%;
    background-color: white;
    border-radius: ${Theme1.radius};
    h3{
        font-size: 18px;
        margin-bottom: 0 !important;
    }
`;
