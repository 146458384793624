import React, { useState, useEffect } from "react";
import Utils from '../../utils';
import BarChartUser from '../../Components/BarChartUser';
import { FaCog } from "react-icons/fa";
import { Col, Row } from 'react-bootstrap';
import CustomModal from '../../base/CustomModal'
import SelectInfo from "../../Components/SelectInfo";
import { GraphicWrapper, GraphicContainer, GraphicWrapperContent } from "./styles";

function View({ page, module }) {

  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [form, setForm] = useState(module.config);
  const [loaded, setLoaded] = useState(false);
  const [title, setTitle] = useState("");
  const [config, setConfig] = useState({});
  const [idModule, setIdModule] = useState(module.id);
  const [idstages, setIdstages] = useState([]);


  const getInfo = ({ stages, indicator }) => {
    const view = localStorage.getItem('view');
    let contador = 0;
    let today = new Date(localStorage.getItem('year'), localStorage.getItem('month'))
    let to = '';

    if (today.getMonth() + 1 < 10) {
      to = today.getFullYear() + '-0' + (today.getMonth() + 1) + '-01';
    } else {
      to = today.getFullYear() + '-' + (today.getMonth() + 1) + '-01';
    }
    const from = today.getFullYear() + '-01-01';
    let aux = [];
    let aux_2 = [];

    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      let idstage = -1;

      for (let j = 0; j < stage.view.length; j++) {
        if (stage.view[j] === view) {
          idstage = stage.value[j];
          aux_2.push(idstage);
          break;
        }
      }

      const end_point = `/indicator-value/indicator/${indicator.id}/stage/${idstage}/range/${from}/${to}`;
      Utils.Petition.get(end_point,
        (response) => {
          const data = {
            indicator: indicator.id,
            stage: idstage,
            data: response.data
          }
          aux.push(data)
          contador++;
          if (contador === stages.length) {
            setData(aux);
            setLoaded(true);
          }
        }
      )
    };
    setIdstages(aux_2);
  }

  useEffect(() => {
    setLoaded(false);

    if (Object.keys(module.config).length > 0) {
      const iduser = localStorage.getItem('iduser');
      let indicator = {};
      let stages = {};
      if (form.users[iduser]) {
        indicator = form.users[iduser].indicator;
        stages = form.users[iduser].stages;
        for (let i = 0; i < form.default.stages.length; i++) {
          stages[i].color = form.default.stages[i].color;
          stages[i].type = form.default.stages[i].type;
        }
        setTitle(form.users[iduser].indicator.name)
      } else {
        indicator = form.default.indicator;
        stages = form.default.stages;
        setTitle(form.default.title === "" ? form.default.indicator.name : form.default.title);
        indicator.legend = form.default.legend;
      }
      setConfig({ indicator, stages, hideLegend: form.default.hideLegend, legendPosition: form.default.legendPosition, height: form.default.height, yLeft: form.default.yLeft, minYL: form.default.minYL });
      getInfo({ indicator, stages });
    }
  }, [form])

  const handlePopup = () => {
    setShowModal(true);
  }

  return (
    <GraphicWrapper>
      <GraphicWrapperContent>
        <GraphicContainer>
          {
            loaded && Object.keys(config).length > 0 &&
            <>
              <Row>
                <Col><h3 className="mb-4">{title} <FaCog onClick={handlePopup} /></h3></Col>
              </Row>
              {
                data.length > 0 && <BarChartUser config={config} data={data} stages={idstages} />
              }
              <CustomModal hideFooter title={"Edición"} show={showModal} setShow={setShowModal} size="sm">
                {
                  showModal &&
                  <SelectInfo form={form} setForm={setForm} id={idModule} setShowModal={setShowModal} />
                }
              </CustomModal>
            </>
          }
        </GraphicContainer>
      </GraphicWrapperContent>

    </GraphicWrapper>
  )
}
export default View;