import { useContext, useEffect, useState } from 'react';
import Popup from '../base/custom-page-components/popups/view/Popup';
import Section from '../base/Section';
import { UserContext } from '../Context/User';
import { SubContainer } from './styles';

function Default({ globalProps, page, templateZonesLoadingPageRoute, setPopupStructure }) {
    const { setPageId } = useContext(UserContext);
    const [loadedSuperiorCompleto, setLoadedSuperiorCompleto] = useState(false);

    const [loadedCentroA, setLoadedCentroA] = useState(false);

    const [loadedIzquierdaB, setLoadedIzquierdaB] = useState(false);
    const [loadedDerechaB, setLoadedDerechaB] = useState(false);

    const [loadedIzquierdaB2, setLoadedIzquierdaB2] = useState(false);
    const [loadedDerechaB1, setLoadedDerechaB1] = useState(false);
    const [loadedDerechaB2, setLoadedDerechaB2] = useState(false);
    const [loadedDerechaB3, setLoadedDerechaB3] = useState(false);

    const [loadedIzquierdaC, setLoadedIzquierdaC] = useState(false);
    const [loadedCentroC, setLoadedCentroC] = useState(false);
    const [loadedDerechaC, setLoadedDerechaC] = useState(false);

    const [loadedCentroD0, setLoadedCentroD0] = useState(false);
    const [loadedCentroD, setLoadedCentroD] = useState(false);
    const [loadedCentroE, setLoadedCentroE] = useState(false);
    useEffect(() => {
        setPageId(page.id);
    }, [page])
    const templateZonesLoading = {
        superiorCompleto: { loaded: loadedSuperiorCompleto, setLoaded: setLoadedSuperiorCompleto },

        centroA: { loaded: loadedCentroA, setLoaded: setLoadedCentroA },

        izquierdaB: { loaded: loadedIzquierdaB, setLoaded: setLoadedIzquierdaB },
        derechaB: { loaded: loadedDerechaB, setLoaded: setLoadedDerechaB },

        izquierdaB2: { loaded: loadedIzquierdaB2, setLoaded: setLoadedIzquierdaB2 },
        derechaB1: { loaded: loadedDerechaB1, setLoaded: setLoadedDerechaB1 },
        derechaB2: { loaded: loadedDerechaB2, setLoaded: setLoadedDerechaB2 },
        derechaB3: { loaded: loadedDerechaB3, setLoaded: setLoadedDerechaB3 },

        izquierdaC: { loaded: loadedIzquierdaC, setLoaded: setLoadedIzquierdaC },
        centroC: { loaded: loadedCentroC, setLoaded: setLoadedCentroC },
        derechaC: { loaded: loadedDerechaC, setLoaded: setLoadedDerechaC },
        centroD0: { loaded: loadedCentroD0, setLoaded: setLoadedCentroD0 },
        centroD: { loaded: loadedCentroD, setLoaded: setLoadedCentroD },
        centroE: { loaded: loadedCentroE, setLoaded: setLoadedCentroE },
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="superiorCompleto" />
                    </div>
                </div>
                
                <SubContainer>
                    <div className="row" style={{paddingTop:'15px'}}>
                        <div className="col-12">
                        <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroA" />
                        </div>
                    </div>
                    
                    <div className="row mt-3">
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaB" />
                        </div>
                        <div className="col-6">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-3">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaB2" />
                        </div>
                        <div className="col-9">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB1" />
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB2" />
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB3" />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-4">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaC" />
                        </div>
                        <div className="col-4">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroC" />
                        </div>
                        <div className="col-4">
                            <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaC" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroD0" />
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroD" />
                        </div>
                        <div className="col-12">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroE" />
                        </div>
                    </div>
                    
                </SubContainer>


            </div>
        </>

    );
}

export default Default;