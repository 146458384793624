import styled from "styled-components";
import { Theme1 } from "../../../themes/Theme1";
export const ItemWrapper = styled.div`
    width: 100%;
    padding: 0 10px;
    a{
        display: flex;
        width: 100%;
        
        color: ${props => props.colors?.grayText};
        padding: 10px 0;
        font-size: 18px;
        &:hover{
            color: ${props => props.colors?.primary};
        }
    }
`;

export const ItemContainWrapper = styled.div`
    border-bottom: ${Theme1.borderTwo};
    width: 100%;
    .menu-collase{
        padding-left: 20px;
    }
`;
export const ItemContain = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    .collase-menu{
        font-size: 12px;
        display: block;
        width: 20px;
        text-align: center;
        cursor: pointer;
        text-align: center;
        border-radius: 4px;
        &:hover{
            background-color: ${props => props.primary};
            color: white;
        }
    }
`;
export const ItemContainNoLink = styled.div`
        display: flex;
        width: 100%;
        cursor: pointer;
        color: ${props => props.colors?.grayText};
        padding: 10px 0;
        &:hover{
            color: ${props => props.colors?.primary};
        }
`;
export const Label = styled.span`
    font-size: 14px;
    margin-left: 5px;
    text-decoration: none;
`;
