import { useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { UserContext } from "../Context/User";
import Utils from "../utils";
import { FiltrosWrapper, FiltrosLabel, FiltrosMenu, FiltrosWrapperSelect } from "./styles";

function Filtros({ globalProps, setEditMode, updateLocalStorage, setUpdateLocalStorage }) {
    const { setIdFilter, colorPalette } = useContext(UserContext);
    const [months, setMonths] = useState([]);
    const [years, setYears] = useState([]);
    const [views, setViews] = useState([]);
    const [filterSelects, setFilterSelects] = useState({
        month: '',
        year: '',
        view: '',
        section: '',
    });
    const filter_name = Utils.getConfigPortal('filter_name');
    useEffect(() => {
        let date = new Date();
        let month = date.getMonth();
        let year = date.getFullYear();
        //let lastYear = (month === 0) ? year - 1 : year;
        let lastYear = year;
        if (!localStorage.getItem('view')) localStorage.setItem('view', 'M');
        if (!localStorage.getItem('month')) localStorage.setItem('month', month - 1);
        if (!localStorage.getItem('year')) localStorage.setItem('year', lastYear);

        Utils.Petition.get('/view-business', (response) => {
            let _views = [];
            
            response.data.forEach(element => {
                let obj = {}
                obj.value = element.code;
                obj.description = element.description;
                _views.push(obj);
            });
            setViews(_views);

        })
        
        let _years = [];
        for (let i = lastYear - 5; i <= lastYear; i++) {
            _years.push(i);
        }
        setYears(_years);
        let _months = [];
        if(localStorage.getItem('view')==='T'){
            _months = ['Enero-Marzo', 'Abril-Junio', 'Julio-Septiembre', 'Octubre-Diciembre'];
            setMonths(_months);
        }else{

            _months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
            if (localStorage.getItem('year') === year) {
                _months.splice(month);
            }
            setMonths(_months);
        }
        if(_months.length===4 && 
            localStorage.getItem('month')!=='2' &&
            localStorage.getItem('month')!=='5' &&
            localStorage.getItem('month')!=='8' &&
            localStorage.getItem('month')!=='11'
        ){
            localStorage.setItem('month','2');
        }

        setFilterSelects({
            month: localStorage.getItem('month'),
            year: localStorage.getItem('year'),
            view: localStorage.getItem('view'),
            section: localStorage.getItem('idfilter'),
        });
        setIdFilter(localStorage.getItem('idfilter'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(updateLocalStorage) {
            setFilterSelects({
                month: localStorage.getItem('month'),
                year: localStorage.getItem('year'),
                view: localStorage.getItem('view'),
                section: localStorage.getItem('idfilter'),
            });
            setUpdateLocalStorage(false);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateLocalStorage])

    const handleFilterSelect = (event) => {
        setFilterSelects({ ...filterSelects, [event.target.name]: event.target.value });
        if (event.target.name === 'section') {
            localStorage.setItem('idfilter', event.target.value);
            localStorage.setItem('filter', event.target[event.target.selectedIndex].text);
        } else {
            let _localname = event.target.name;
            localStorage.setItem(_localname, event.target.value);
        }
        globalProps.setReloadContent(true);
    }


    return (
        <Container fluid>
            <FiltrosWrapper>
                <FiltrosMenu className="menu-filter">

                    <FiltrosWrapperSelect hidden={JSON.parse(localStorage.getItem('filters')).length <= 1}>
                        <FiltrosLabel color={colorPalette.secondary} >{filter_name}</FiltrosLabel>
                        <select ref={globalProps.filterTag} id="filter-section" onChange={handleFilterSelect} name="section" value={filterSelects.section}>
                           { /* Los filtros se incluyen por página (PageRoute.js) */ }
                        </select>
                    </FiltrosWrapperSelect>
                    <FiltrosWrapperSelect>
                        <FiltrosLabel color={colorPalette.secondary}>Mes</FiltrosLabel>
                        <select id="filter-month" onChange={handleFilterSelect} name="month" value={filterSelects.month}>
                            {
                                months.map((month, i) => {
                                    return <option key={i} value={localStorage.getItem('view')==='T'?i*3+2:i}>{month}</option>
                                })
                            }
                        </select>
                    </FiltrosWrapperSelect>
                    <FiltrosWrapperSelect>
                        <FiltrosLabel color={colorPalette.secondary}>Año</FiltrosLabel>
                        <select id="filter-year" onChange={handleFilterSelect} name="year" value={filterSelects.year}>
                            {
                                years.map((year, i) => {
                                    return <option key={i} value={year}>{year}</option>
                                })
                            }
                        </select>
                    </FiltrosWrapperSelect>
                    <FiltrosWrapperSelect>
                        <FiltrosLabel color={colorPalette.secondary}>Tipo</FiltrosLabel>
                        <select id="filter-view" onChange={handleFilterSelect} name="view" value={filterSelects.view}>
                            {
                                views.map((view, i) => {
                                    return <option key={i} value={view.value}>{view.description}</option>
                                })
                            }
                        </select>
                    </FiltrosWrapperSelect>
                </FiltrosMenu>
            </FiltrosWrapper>
        </Container>
    );
}
export default Filtros;