import Circle from './Circle';
function Sign({value, type, base, isDescendingIndicator = false}){
    if(base===undefined) base= 100;
    return(
        value===null
            ? <Circle color='gray'/>
            :(
                (value == base)
                ? <Circle color='green' />
                :( (!isDescendingIndicator) ? (
                    (value > base) 
                    ?<Circle color='green' />
                    :<Circle color='red' />) : (
                        (type === 'C')
                        ?(
                            (value > base) 
                            ?<Circle color='green' />
                            :<Circle color='red' />
                        )
                        :(
                            (value < base) 
                            ?<Circle color='green' />
                            :<Circle color='red' />
                        )
                    )
                )
            )

    )
}
export default Sign;