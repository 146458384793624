import React, { useState, useEffect } from "react";
import CreateTable from "../../Components/CreateTable"
import Utils from '../../utils';
import { TableTitle } from "./styles";

function View({ page, module, setPopupStructure, templateZonesLoading }) {

    const [data, setData] = useState([]);
    const [idstages, setIdstages] = useState([]);
    const [flag, setFlag] = useState(false);
    const [configPopup, setConfigPopup] = useState({ id: 0, title: '' });

    useEffect(() => {
        if (configPopup.id !== 0) {
            Utils.Petition.get('/popups/id/' + configPopup.id,
                response => {
                    setPopupStructure({
                        id: configPopup.id,
                        title: response.data.name,
                        show: true,
                    })
                }
            )
            templateZonesLoading['popup'].setLoaded(false);
            templateZonesLoading['popupIzquierda'].setLoaded(false);
            templateZonesLoading['popupDerecha'].setLoaded(false);
            templateZonesLoading['popupIzquierdaA'].setLoaded(false);
            templateZonesLoading['popupDerechaA'].setLoaded(false);
            templateZonesLoading['popupIzquierdaB'].setLoaded(false);
            templateZonesLoading['popupCentroB'].setLoaded(false);
            templateZonesLoading['popupDerechaB'].setLoaded(false);
            templateZonesLoading['popupCentroC'].setLoaded(false);
        }
    }, [configPopup])

    useEffect(() => {
        if( Object.keys(module.config).length > 0) {
            setFlag(false);
            const view = module.config.hasOwnProperty('view') && module.config.view !== "PorDefecto" ? module.config.view : localStorage.getItem('view');
            let info_values = [];
            let idstages_temp = [];

            const data = {
                indicators: module.config.indicators.map(ind => ind.id)
            }

            let contador = 1;
            let bandera = 0
            module.config.stages.map( stage => {if( stage.othersInd ) contador ++;})

            Utils.Petition.post(
                '/indicators/getTypeIndicators', data,
                (response) => {
                    const resp = response.data;
                    for (let i = 0; i < module.config.indicators.length; i++) {
                        const aux = resp.filter(ind => ind.id === module.config.indicators[i].id)[0];
                        module.config.indicators[i].type = aux.type;
                        module.config.indicators[i].unit = aux.unit;
                    }
                    setFlag(true)
                }
            );

            for(let k=0; k<module.config.stages.length; k++ ) {
                let dataNewInd = {}
                if( module.config.stages[k].othersInd ) {
                    dataNewInd = {
                        indicators: module.config.stages[k].newIndicators.map( ind => ind.id)
                    }
                    Utils.Petition.post(
                        '/indicators/getTypeIndicators', dataNewInd,
                        (response) => {
                            const resp = response.data;
                            for (let i=0; i < module.config.stages[k].newIndicators.length; i++) {
                                const aux = resp.filter( ind => ind.id === module.config.stages[k].newIndicators[i].id)[0];
                                module.config.stages[k].newIndicators[i].type = aux.type;
                                module.config.stages[k].newIndicators[i].unit = aux.unit;
                            }
                            bandera ++;
                            if (bandera === contador) setFlag(true)
                        }
                    );
                }
            };

            module.config.stages.map(stage => {
                let idstage = { id: -1, semaphore: false };
                let month = parseInt(localStorage.getItem('month')) + 1;
                let year = parseInt(localStorage.getItem('year'));
                let idCompliances = null;
                for (let i = 0; i < stage.view.length; i++) {
                    if (stage.view[i] === view) {
                        idstage.id = stage.values[i];
                        idstage.percent = stage.percent;
                        if(stage.hasOwnProperty('compliance_stages') && stage.compliance_stages !== [null, null]) {
                            idCompliances = stage.compliance_stages[i].split(",");
                            idstage.compliance = true;
                            idstage.compliance_stages = idCompliances;
                        }
                        if (stage.semaphore) {
                            idstage.semaphore = stage.semaphore;
                        }
                    }
                };
                
                if( stage.lastYear ) {
                    year--;
                }   
                if( stage.lastDec ) {
                    year--;
                    month = 12;
                }

                module.config.indicators.map((indicator, index) => {
                    if(stage.othersInd) {
                        if( stage.lessInd ) {
                            if(index + 1 <= stage.amountLessInd) {
                                info_values.push("'" + stage.newIndicators[index].id + "-" + idstage.id + "-" + year + "-" + month + "'");
                                if(idCompliances !== null) {
                                    info_values.push("'" + stage.newIndicators[index].id + "-" + parseInt(idCompliances[0]) + "-" + year + "-" + month + "'");
                                    info_values.push("'" + stage.newIndicators[index].id + "-" + parseInt(idCompliances[1])+ "-" + year + "-" + month + "'");
                                }
                            }
                        } else {
                            info_values.push("'" + stage.newIndicators[index].id + "-" + idstage.id + "-" + year + "-" + month + "'");
                            if(idCompliances !== null) {
                                info_values.push("'" + stage.newIndicators[index].id + "-" + parseInt(idCompliances[0]) + "-" + year + "-" + month + "'");
                                info_values.push("'" + stage.newIndicators[index].id + "-" + parseInt(idCompliances[1]) + "-" + year + "-" + month + "'");
                            }
                        }
                    } else {
                        info_values.push("'" + indicator.id + "-" + idstage.id + "-" + year + "-" + month + "'");
                        if(idCompliances !== null) {
                            info_values.push("'" + indicator.id + "-" + parseInt(idCompliances[0]) + "-" + year + "-" + month + "'");
                            info_values.push("'" + indicator.id + "-" + parseInt(idCompliances[1]) + "-" + year + "-" + month + "'");
                        }
                    }
                });
                idstages_temp.push(idstage)
            });
            setIdstages(idstages_temp);

            Utils.Petition.get('/indicator-value/info_values/' + info_values,
                (response) => {
                    setData(response.data)
                }
            )
        }
    }, []);

    return (
        <>
            {module.config.title && <TableTitle className="mb-4 text-center">{module.config.title}</TableTitle>}
            {
                flag && data.length > 0 && <CreateTable dataConfig={module.config} data={data}id={module.id} idstages={idstages} configPopup={configPopup} setConfigPopup={setConfigPopup} />
            }
        </>
    )
}


export default View;