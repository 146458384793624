import { useHistory,  } from "react-router-dom";
import { useContext, useState } from "react";
import { FaDesktop } from "react-icons/fa";
import { ShowChange, ChangeWrapper, ChangeOverlay } from "./styles";
import { UserContext } from "../../../Context/User";
export const ChangeProfile = () => {
    const { colorPalette } = useContext(UserContext);

    const [homePage, setHomePage] = useState(localStorage.getItem('home-page'));
    const [showChage, setShowChage ] = useState(false) ;
    let history = useHistory();
    const handleHomePage = (valor)=>{
        
            let url = '/'+ valor
            localStorage.setItem('home-page', url)
            setHomePage(url);
            history.push(url);
            
        
    }
    const handleChange = () =>{
        setShowChage (!showChage);
    }
    return (
        <>
        <ShowChange color={colorPalette.primary} onClick={handleChange}>
            <FaDesktop />
        </ShowChange>
        <ChangeOverlay showChage={showChage} onClick={handleChange}/>
        <ChangeWrapper color={colorPalette.primary} showChage={showChage}>
            <div className={ homePage === '/estrategia' ? 'active' : null} onClick={()=>handleHomePage('estrategia')}>
                <div className="head">
                    <div className="check"><span></span></div>
                    <div className="title">Estrategia</div>
                </div>
                <img src="/assets/estrategia.png" alt="estrategia" />
            </div>
            <div className={ homePage === '/gestion' ? 'active' : null} onClick={()=>handleHomePage('gestion')}>
            <div className="head">
                    <div className="check"><span></span></div>
                    <div className="title">Gestion</div>
                </div>
                <img src="/assets/gestion.png" alt="gestion"/>
            </div>
        </ChangeWrapper>
        
        </>
    )
}