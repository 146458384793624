import styled from 'styled-components';

export const ButtonGroup = styled.div`
    display: flex;
    flex-wrap: wrap;

    & button {
        margin-top: 1rem;
    }
`;

export const ButtonWrapper = styled.div`
    
    font-size: 15px;
    padding: 5px 10px;
    
    button{
        color: white;
        background-color: ${props => props.color} !important;
        border: solid 1px ${props => props.color} !important;
        font-size: 14px;
        font-weight: bold;
        box-shadow: 1px 0px 20px 10px #00000020;
        &:hover{
            color: ${props => props.color} !important;
            background-color: transparent !important;
        }
        &:focus {
            box-shadow: 0 0 12px 0 ${props => props.color};
        }
    }
`;

export const StyleEditor = styled.div`
    margin-top: 0.6rem;
    padding: 0.5rem;
    box-shadow: 1px 0px 20px 10px #00000020;
    border-radius: 0.6rem;

    

    & .rdw-editor-toolbar > [class$='wrapper'], & .rdw-editor-toolbar > [class$='dropdown'] {
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
        margin: 0.2rem;
        box-shadow: 1px 0px 20px 10px #aeaeae20;

        & > .rdw-option-wrapper {
            border: none;
        }
    }

    & .rdw-editor-main {
        border: 1px solid #aeaeae;
        border-radius: 0.3rem;
    }
`;

export const HtmlWrapper = styled.div`
    font-size: 14px;
    h1{
        font-size: 24px;
        margin-bottom: 0px;
    }
    h2{
        font-size: 22px;
        margin-bottom: 0px;
    }
    h3{
        font-size: 18px;
        margin-bottom: 0px;
    }
    h4{
        font-size: 16px;
        margin-bottom: 0px;
    }
    h5{
        font-size: 14px;
        margin-bottom: 0px;
    }
    h6{
        font-size: 12px;
        margin-bottom: 0px;
    }
    .desplegable.collapsed{
        color: ${props => props.color};
        text-decoration: underline;
        font-weight: bold;
        &:after{
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background-image: url('/assets/chevron.png');
            background-size: cover;
            background-position: center;
            margin-left: 5px;
            position:relative;
            top:7px;
        }
    }
    a:not(.desplegable){
        color: ${ props => props.color};
        text-decoration: underline;
        font-weight: bold;
        &:after{
            content: "";
            display: inline-block;
            width: 12px;
            height: 12px;
            background-image: url('/assets/hipervinculo.png');
            background-size: cover;
            background-position: center;
            margin-left: 5px;
        }
    }
`;
