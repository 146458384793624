import { FaHome, FaNetworkWired, FaChartBar, FaCog, FaUsers, FaBell, FaGraduationCap, FaLifeRing, FaExternalLinkAlt } from "react-icons/fa";
import { ItemSidebar } from "./components/ItemSidebar";
import { SideContent, SideSupp, SideWrapper } from "./styles";
import { Link } from "react-router-dom"
import Utils from "../../utils";
import React, { useContext } from "react";
import { OLD_SITE } from "../../config";
import { UserContext } from "../../Context/User";


const icons = {FaHome, FaNetworkWired, FaChartBar, FaCog, FaUsers, FaBell, FaGraduationCap, FaLifeRing, FaExternalLinkAlt}


export const Sidebar = () => {
	const { colorPalette } = useContext(UserContext);
	const menu = Utils.getConfigPortal('menu');
	const SideOptions = menu === null ? [] : menu.data === undefined ? [] : menu.data;

	for (let index = 0; index < SideOptions.length; index++) {
		const element = SideOptions[index];
		element.icon = icons[element.icon]
	}
	const SideOptionsAdmin = [];

	if(Utils.Security.checkRoleGroup('viewIndicator')){
		SideOptionsAdmin.push({
			icon: FaCog,
			label: "Indicadores",
			path: OLD_SITE,
			external: true
		})
	}

    return(
        <SideWrapper>
            <SideContent color={colorPalette.grayText}>
                {SideOptions.map((item, i) => (
                 <ItemSidebar key={i} {...item} />
                ))}
            </SideContent>
            
            <SideSupp color={colorPalette.grayText}>
                <Link to="/soporte">
                    <FaLifeRing/>
                    <span>Soporte</span>
                </Link>
            </SideSupp>
        </SideWrapper> 
    );
}