import { useState, useContext } from "react";
import { ButtonGroup } from "react-bootstrap";
import { FaEdit, FaRedo, FaSave, FaTrash } from "react-icons/fa";
import { Button } from "../../../../commonStyles";
import Utils from "../../../../utils";
import { UserContext } from "../../../../Context/User";

function Item({templateZonesLoading, setPopupStructure, popups, setPopups, popup, index}){
    const { colorPalette } = useContext(UserContext);
    const [editMode, setEditMode] = useState(false);
    const [popupName, setPopupName]= useState('');
    const handleDelete = (event)=>{
        Utils.Petition.delete('/popups/id/'+popup.id, (_)=>{
            let _popups = [...popups];
            _popups.splice(index, 1);
            setPopups(_popups);
        })
    }
    const handlePopup= (event)=>{
        event.preventDefault();
        setPopupStructure({
            id:popup.id,
            title: popup.name,
            show: true,
        });
        
        templateZonesLoading['popup'].setLoaded(false);
        templateZonesLoading['popupIzquierda'].setLoaded(false);
        templateZonesLoading['popupDerecha'].setLoaded(false);
        templateZonesLoading['popupIzquierdaA'].setLoaded(false);
        templateZonesLoading['popupDerechaA'].setLoaded(false);
        templateZonesLoading['popupIzquierdaB'].setLoaded(false);
        templateZonesLoading['popupCentroB'].setLoaded(false);
        templateZonesLoading['popupDerechaB'].setLoaded(false);
        templateZonesLoading['popupCentroC'].setLoaded(false);
        
    }
    const handleEdit = ()=>{
        setEditMode(true);
        setPopupName(popup.name);
    }
    const handleReturn = ()=>{
        setEditMode(false);
    }
    const handleSave = ()=>{
        Utils.Petition.post('/popups/update',
        {id: popup.id, name:popupName},
        (_)=>{
            let _popups = [...popups];
            for(let i = 0; i<popups.length; i++){
                if(popup.id===popups[i].id){
                    popup.name=popupName;
                    break;
                }
            }
            setPopups(_popups);
            setEditMode(false);
        })
    }
    const handleChangeName = (event)=>{
        setPopupName(event.target.value);
    }
    return (
        <div className="mb-2">
            <ButtonGroup key={index}>
                {!editMode && <Button className="mr-3" color={colorPalette.primary} onClick={handlePopup}>{popup.id} - {popup.name}</Button>}
                {editMode && <input type="text" className="mr-2" onChange={handleChangeName} name="popupName" value={popupName} />}
                {!editMode && <Button className="mr-2" color={colorPalette.secondary} onClick={handleEdit}><FaEdit /></Button>}
                {editMode && <Button className="mr-2" color={colorPalette.secondary} onClick={handleReturn}><FaRedo /></Button>}
                {editMode && <Button className="mr-2" color={colorPalette.success} onClick={handleSave}><FaSave /></Button>}
                <Button className="mr-2" color={colorPalette.red} onClick={handleDelete}><FaTrash /></Button>
            </ButtonGroup>
        </div>
        
    )
}
export default Item;