
export const Theme1 = {
    font: "'Montserrat', sans-serif",
    orange: '#EF8220',
    red: '#F12929',
    border: 'solid 1px #B3B1B2',
    borderTwo: 'solid 1px rgba(0, 0, 0, 0.1)',
    radius: '10px',
    grayText: '#555555',
    grayLight: '#d6d3d4',
    shadow: '0px 0px 20px 3px rgba(0, 0, 0, 0.08)',
    shadowContainer: '0px 0px 20px 3px rgba(0, 0, 0, 0.25)',
    blue: "#0A87E6",
    success: "#28a745",
    dark: "#111111"
}
