import styled from "styled-components";
import {Theme1} from "../../themes/Theme1";

export const SideWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: white;
    box-shadow: ${Theme1.shadow};
    padding: 50px 0;
`;
export const SideContent = styled.div`
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    h6{
        font-size: 14px;
        font-weight: 100;
        color: ${props => props.color};
        padding: 10px 10px 0;
        margin: 0;
    }
`;

export const SideSupp = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0 10px;
    a{
        width: 100%;
        display: block;
        color: ${props => props.color};
        text-align: center;
        border-top: ${Theme1.borderTwo};
        padding: 10px 0;
        font-size: 16px;
        span{
            margin-left: 5px;
        }
    }
`;

