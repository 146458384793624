import { useContext, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Section from '../base/Section';
import { ButtonClose, StyleCard, WrapperPadding } from './styles';
import { Button } from "../commonStyles";
import { UserContext } from '../Context/User';
function EnvironmentAnalysis({ globalProps, page, templateZonesLoadingPageRoute, setPopupStructure }) {
    const { setPageId, colorPalette } = useContext(UserContext);
    const [loadedContentA, setLoadedContentA] = useState(false);

    const [loadedMacroeconomicKey, setLoadedMacroeconomicKey] = useState(false);
    const [loadedMarketKey, setLoadedMarketKey] = useState(false);
    const [loadedCateringKey, setLoadedCateringKey] = useState(false);

    const [loadedMacroeconomicValue, setLoadedMacroeconomicValue] = useState(false);
    const [loadedMarketValue, setLoadedMarketValue] = useState(false);
    const [loadedCateringValue, setLoadedCateringValue] = useState(false);

    const [loadedContentB, setLoadedContentB] = useState(false);

    const templateZonesLoading = {

        contenedorCompletoSuperior: { loaded: loadedContentA, setLoaded: setLoadedContentA },

        macroeconomicoSuperior: { loaded: loadedMacroeconomicKey, setLoaded: setLoadedMacroeconomicKey },
        mercadoSuperior: { loaded: loadedMarketKey, setLoaded: setLoadedMarketKey },
        abastecimientoSuperior: { loaded: loadedCateringKey, setLoaded: setLoadedCateringKey },

        macroeconomicoContenido: { loaded: loadedMacroeconomicValue, setLoaded: setLoadedMacroeconomicValue },
        mercadoContenido: { loaded: loadedMarketValue, setLoaded: setLoadedMarketValue },
        abastecimientoContenido: { loaded: loadedCateringValue, setLoaded: setLoadedCateringValue },

        contenedorCompletoInferior: { loaded: loadedContentB, setLoaded: setLoadedContentB },
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
        
    };
    /*
        openContent: none, macroeconomic, market, catering, cada cambio abrirá el contenido correspondiente
    */
    const [openContent, setOpenContent] = useState('none');
    
    useEffect(() => {
        setPageId(page.id);
    }, [page])
    const handleShowContent = (content, code) => {
        templateZonesLoading[code].setLoaded(false);
        setOpenContent(content);
    }

    const handleClose = (event) => {
        event.preventDefault();
        setOpenContent('none');
    }
    return (
        <div className="environment-analysis">
            <Container fluid>

                <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="contenedorCompletoSuperior" />
                <Row>
                    <Col>
                        <StyleCard>
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="macroeconomicoSuperior" hideTabs />
                            <Button color={colorPalette.primary} onClick={() => handleShowContent('macroeconomic', 'macroeconomicoContenido')}>Ver más</Button>
                        </StyleCard>
                    </Col>
                    <Col>
                        <StyleCard>
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="mercadoSuperior" hideTabs />
                            <Button color={colorPalette.primary} onClick={() => handleShowContent('market', 'mercadoContenido')}>Ver más</Button>
                        </StyleCard>
                    </Col>
                    <Col>
                        <StyleCard>
                            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="abastecimientoSuperior" hideTabs />
                            <Button color={colorPalette.primary}  onClick={() => handleShowContent('catering', 'abastecimientoContenido')}>Ver más</Button>
                        </StyleCard>
                    </Col>
                </Row>
                <Row>
                <Col className="content-environment">
                    {
                        openContent === 'macroeconomic' &&
                        <div>
                            
                            <WrapperPadding>
                                <ButtonClose color={colorPalette.primary} onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></ButtonClose>
                                <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="macroeconomicoContenido" />
                            </WrapperPadding>
                            
                        </div>
                    }
                    {
                        openContent === 'market' &&
                        <div>
                            
                            <WrapperPadding>
                            <ButtonClose color={colorPalette.primary} onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></ButtonClose>
                                <Section globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="mercadoContenido" />
                            </WrapperPadding>
                        </div>
                    }
                    {
                        openContent === 'catering' &&
                        <div>
                            
                            <WrapperPadding>
                            <ButtonClose color={colorPalette.primary} onClick={handleClose} type="button" className="close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></ButtonClose>
                                <Section globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="abastecimientoContenido" />
                            </WrapperPadding>
                        </div>
                    }
                </Col>
                </Row>
                
                <Section globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="contenedorCompletoInferior" />
            </Container>
        </div>

    );
}

export default EnvironmentAnalysis;