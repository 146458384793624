import React, { useEffect, useState, useContext } from "react";
import Utils from "../../utils";
import IndicatorValue from "./IndicatorValue";
import parse from "html-react-parser";
import GetPopup from "./content/getPopup";
import { HtmlWrapper } from "./styles";
import SignValue from "./SignValue";
import { UserContext } from "../../Context/User";
import "./View.css";

function View({ page, module, setPopupStructure, templateZonesLoading }) {
  const { colorPalette } = useContext(UserContext);
  const [totalValue, setTotalValue] = useState("");
  const [attrRelation, setAttrRelation] = useState([]);
  const [updateTable, setUpdateTable] = useState(false);

  useEffect(() => {
    //consulta mia debe devolver

    let view = localStorage.getItem("view");
    let url = `/stages_relation/filterbycode/${view}`;
    Utils.Petition.get(
      url,
      (res) => {
        setAttrRelation(res.data);
        dataConverter();
        
      },
      (err) => {
        console.log(err);
      }
    );
    document.addEventListener('click', handleAccordion);
    return () => document.removeEventListener("click", handleAccordion);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(totalValue || updateTable) {
      setTimeout(changeColor, 2000);
      setUpdateTable(false);
    }
  }, [totalValue , updateTable])

  const handleAccordion = (e) => {
      if(e.target.classList.contains('titulo-acrodeon') || e.target.classList.contains('desplegable')){
          e.preventDefault();
          let id = e.target.parentNode.querySelector('.desplegable').getAttribute('href').replace('#', '');
          let $id = document.getElementById(id);
          if ($id.classList.contains('show') ) {
            $id.style.opacity = 0;
            $id.style.transform = 'scale(0)';
            
              window.setTimeout(function(){
                $id.classList.remove('show');
                $id.classList.add('hide');
                $id.style.display = 'none';
              },700); // timed to match animation-duration
            }
            else {
              $id.style.display = 'block';
              window.setTimeout(function(){
                $id.classList.remove('hide');
                $id.classList.add('show');
                $id.style.opacity = 1;
                $id.style.transform = 'scale(1)';
              },0);
              
            }
      }
  }
  const changeColor = () => {
    if(document.getElementsByClassName("colors").length && document.getElementsByClassName("panel-default").length){
      for (let index = 0; index < document.getElementsByClassName("panel-default").length; index++) {
        const element = document.getElementsByClassName("panel-default")[index];
        let suma = 0;
        let entradas = 0;
        if( element.getElementsByClassName("fecha")[0].getElementsByTagName("table").length) {
          for (let i = 0; i < element.getElementsByClassName("fecha")[0].getElementsByTagName("table")[0].getElementsByTagName("tr").length; i++) {
            const el = element.getElementsByClassName("fecha")[0].getElementsByTagName("table")[0].getElementsByTagName("tr")[i];
            let _tr = el.getElementsByTagName("td");
            if(_tr.length > 0){
              let real = 0;
              let presupuesto = 0;
              if(_tr[1].getElementsByTagName("span")[1]) {
                real = parseFloat(_tr[1].getElementsByTagName("span")[1].innerHTML)
              } else {
                return setUpdateTable(true);
              }
              presupuesto = parseFloat(_tr[2].getElementsByTagName("span")[0].innerHTML)
              if((real !== 0 || presupuesto !== 0)){
                entradas++;
                if(presupuesto === 0 || real/presupuesto >= 1) suma+=3;
                else if(real/presupuesto>=0.9){
                  suma+=2;
                }else{
                  suma += 1;
                }
              }
            }
          }
          let color = 'bg-gris';
          if(entradas>0){
            let promedio= suma/entradas;
            if(promedio===3) color = 'bg-verde';
            else if(promedio >= 2) color = 'bg-amarillo';
            else color = 'bg-rojo';
          }
          element.getElementsByClassName("titulo-acrodeon")[0].classList.add(color);
        }
      }
    }
  }
  /*DataConverter - Modulo que convierte data de popups e indicadores
    a valores legibles por el usuario el prefijo debe llevar
    tipo@nombreValor:valor,nombreValor:valor@ para convertir los 
    datos ya sea en un popup o en el total de un indicador*/
  const dataConverter = () => {
    let content = module.config.data;
    const regexSearch = /PopUp@(.*?)\@/g;
    const regexSearchIndicator = /Indicador@(.*?)\@/g;
    const regexSign = /Semaforo@(.*?)\@/g;

    //Convertidor de los indicadores
    if (content.match(regexSearchIndicator)) {
      let dataForReplace = content.match(regexSearchIndicator);
      let dataReplaced = "";
      let dataResults = [];

      for (let pos in dataForReplace) {
        dataReplaced = dataForReplace[pos].replace(/Indicador@|@|<|>/g, "");
        dataResults.push(dataReplaced);
      }

      let objs = [];
      for (let pos in dataResults) {
        dataResults[pos] = dataResults[pos].split(",");

        let obj = {
          idIndicator: dataResults[pos][0].replace("indicador:", ""),
          nameStage: dataResults[pos][1].replace("escenario:", ""),
        };
        objs.push(obj);
      }

      //Remplazo
      for (let pos in objs) {
        let regex = new RegExp(
          `Indicador@indicador:${objs[pos].idIndicator},escenario:${objs[pos].nameStage}\@`
        );
        content = content.replace(
          regex,
          `<span class="indicator-value" data-indicator="${objs[pos].idIndicator}" data-stage="${objs[pos].nameStage}">test</span>`
        );
      }
    }

    //Convertidor de los popUps
    if (content.match(regexSearch)) {
      let dataForReplace = content.match(regexSearch);
      let dataReplaced = "";
      let dataResults = [];

      for (let pos in dataForReplace) {
        dataReplaced = dataForReplace[pos].replace(
          /(\W|)(PopUp@|@|<|>)(\W|)/gm,
          ""
        );
        dataResults.push(dataReplaced);
      }

      let objs = [];
      for (let pos in dataResults) {
        dataResults[pos] = dataResults[pos].split(",");

        let obj = {
          description: dataResults[pos][0].replace("descripcion:", ""),
          idOfPopup: dataResults[pos][1].replace("id:", ""),
        };

        objs.push(obj);
      }

      //Remplazo
      for (let pos in objs) {
        let regex = new RegExp(
          `PopUp@descripcion:${objs[pos].description},id:${objs[pos].idOfPopup}@`
        );
        content = content.replace(
          regex,
          `<span class="popUp-value" data-description="${objs[pos].description}" data-idPopup="${objs[pos].idOfPopup}">test</span>`
        );
      }
    }

    //Convertidor de los semaforos
    if (content.match(regexSign)) {
      let dataForReplace = content.match(regexSign);
      let dataReplaced = "";
      let dataResults = [];

      for (let pos in dataForReplace) {
        dataReplaced = dataForReplace[pos].replace(/Semaforo@|@|<|>/g, "");
        dataResults.push(dataReplaced);
      }

      let objs = [];
      for (let pos in dataResults) {
        dataResults[pos] = dataResults[pos].split(",");

        let obj = {
          idIndicator: dataResults[pos][0].replace("indicador:", ""),
          nameStage: dataResults[pos][1].replace("escenario:", ""),
        };
        objs.push(obj);
      }

      //Remplazo
      for (let pos in objs) {
        let regex = new RegExp(
          `Semaforo@indicador:${objs[pos].idIndicator},escenario:${objs[pos].nameStage}\@`
        );
        content = content.replace(
          regex,
          `<span class="sign-value" data-indicator="${objs[pos].idIndicator}" data-stage="${objs[pos].nameStage}">test</span>`
        );
      }
    }
    setTotalValue(content);
  };

  const options = {
    replace: ({ attribs, children }) => {
      if (!attribs) {
        return;
      }
      if (attribs.class === "indicator-value") {
        let stage = attrRelation.filter(
          (relation) => relation.code === attribs["data-stage"]
        );
        //attribs['data-stage'] R o P
        return (
          stage.length > 0 && <IndicatorValue
            idIndicator={attribs["data-indicator"]}
            idStage={stage[0].idstage}
          />
        );
      }

      if (attribs.class === "popUp-value") {
        return (
          <GetPopup
            idPopup={attribs["data-idpopup"]}
            popUpDescription={attribs["data-description"]}
            setPopupStructure={setPopupStructure}
            templateZonesLoading={templateZonesLoading}
          />
        );
      }

      if (attribs.class === "sign-value") {
        let stage = attrRelation.filter(
          (relation) => relation.code === attribs["data-stage"]
        );
        //attribs['data-stage'] R o P
        return (
          stage.length > 0 && <SignValue
            idIndicator={attribs["data-indicator"]}
            idStage={stage[0].idstage}
          />
        );
      }
    },
  };
  return (
    <>
      <div className="">
        <HtmlWrapper color={colorPalette.primary}>{parse(totalValue, options)}</HtmlWrapper>
        </div>
    </>
  );
}
export default View;
