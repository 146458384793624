import styled from "styled-components";
import { Theme1 } from "../themes/Theme1";

export const ButonLink  = styled.p`
    color : ${Theme1.orange};
    margin-top: -10px;
    a{
        color : ${Theme1.orange};   
        font-size: 14px;
        text-decoration: underline
    }
`;

export const BgContainer = styled.div`
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.45)),
    url(${props => props.imgLogin});
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
`;

export const OptionsContainer = styled.div`
  position: absolute;
  width: 32rem;
  height: 32rem;
  top: 8rem;
  right: 8rem;
  background-color: #FFFFFF;
  border-radius: 1rem;
  text-align: center;
  z-index: 2;
`;

export const IntroContainer = styled.div`
  position: absolute;
  width: 35rem;
  bottom: 8rem;
  left: 8rem;
  border-radius: 1rem;
  color: #FFF;
`;

export const CtaContainer = styled.div`
& h2 {
    margin: 8rem 0 2rem;
}
& button {
    margin: 2rem 0;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
}
& hr {
    width: 80%;
}
`;

export const PrimaryBtn = styled.button`
    background-color: #EF8220;
    color: #FFFFFF;
    border: thin solid #EF8220;
    :hover {
        background-color: #FFFFFF;
        border: thin solid #EF8220;
        color: #EF8220;
    }
`;

export const SecondaryBtn = styled.button`
    background-color: #FFFFFF;
    border: none;
    color: #EF8220;
        :hover {
        opacity: 0.8;
    }
`;

export const TertiaryBtn = styled.button`
    background-color: #FFFFFF;
    color: gray;
        :hover {
        opacity: 0.8;
    }
`;

export const LoginContainer = styled.div`
& h3 {
    margin: 4rem 0 2rem;
}
& form {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin: auto;
    gap: 1rem;
}
& input {
    padding: 1rem;
    border: thin solid gray;
    border-radius: 1rem;
}
& button {
    width: 50%;
    margin: auto;
    padding: 1rem 3rem;
    border: none;
    border-radius: 0.5rem;
}
`;