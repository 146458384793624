import {  useState } from "react";
import { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { VERSION_SESSION } from "../config";
import Utils from "../utils";

function AutologinSSO({logged, setLogged}){

    const [loaded, setLoaded] = useState(false);
    const [redirectUrl] = useState(localStorage.getItem('redirect_url') || '');
    const [data, setData] = useState({});
    const {token } = useParams();
    useEffect(()=>{

            localStorage.setItem('token_user', token);
            Utils.Petition.get('/users/validate-token',(response)=>{
                Utils.Security.setStartLocalStorage(response, 'SSO', ()=>{
                    setLogged(true);
                    setLoaded(true);
                    window.location.href='/';
                })
            });
    }, []);

    return (
        <>
            {
                !loaded && 'Validando usuario'
            }
            {
                loaded && !logged && (<Redirect to="/login" />)
            }
            {
                logged && loaded 
                && 'Autenticación correcta, redireccionado...'
                /* && <Redirect to={redirectUrl} /> */
            }
            

        </>
        
    )
}
export default AutologinSSO;