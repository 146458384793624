import { useContext, useEffect, useState } from 'react';
import CustomModal from '../../base/CustomModal';
import {ADMIN_URL} from '../../config';
import { UserContext } from '../../Context/User';
import Utils from '../../utils';
import { ButtonConfig } from '../Components/ButtonConfig';

function Edit({globalParams, page, module, everypages, filter}){
    const { validateShowComponent } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    useEffect(()=>{
        window.addEventListener('message', (event)=>{
            if(event.origin===ADMIN_URL && event.data==='cerrar popup'){
                setShowModal(false);
            }
        })
    }, []);
    
    const handlePopup = (event)=>{
        event.preventDefault();
        setShowModal(true);
        
    }
    return (
        <div>          
            {(module.idpopup !== null || validateShowComponent(everypages, filter)) && <ButtonConfig onPress={handlePopup} />}
            { showModal && 
                <CustomModal title={"Configurar gráfica"} show={showModal} setShow={setShowModal}>
                    <iframe title={"Configurar gráfica"} style={{border:0}} width="100%" height="500" src={Utils.getAdminUrl(`/user-graphic/module/${module.id}`)}></iframe>
                </CustomModal>
                }
        </div>
    );
}

export default Edit;