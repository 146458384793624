import Circle from './Circle';
function SignYellowIndicator({value, type, base = 100, percent = 90, isDescendingIndicator = false}){
    let percentYellow = Number(percent) / base;
    const validateColor = () => {
        if(value === null) return "gray";
        if(value === base) return "green"; 
        if(!isDescendingIndicator) {
            if(value > base) return "green";
            
            if(value / base > percentYellow) return "yellow";
            return "red";
        } else {
            if(type === "C" ) {
                if(value > base) return "green";
                if(value / base > percentYellow) return "yellow";
                return "red";
            } else {
                return (value < base) ? "green" : "red"; 
            }
        }

    }     
    return(
         <Circle color={validateColor()}/>
    )
}
export default SignYellowIndicator;