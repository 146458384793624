import { useContext, useState } from "react";
import { Card } from "react-bootstrap";
import CustomModal from "../../base/CustomModal";
import { UserContext } from "../../Context/User";
import Utils from "../../utils";
import { ButtonConfig } from "../Components/ButtonConfig";

function Edit({globalParams, page, module, everypages, filter}){
    const { validateShowComponent } = useContext(UserContext);
    const [showModal, setShowModal] = useState(false);
    const handlePopup = (event)=>{
        event.preventDefault();
        setShowModal(true);
        
    }
    
    return (
        <div>
            {
                module.config.description !== undefined && 
                <Card className="text-center">
                    { module.config.description }
                </Card>
            }          
            {(module.idpopup !== null || validateShowComponent(everypages, filter)) && <ButtonConfig onPress={handlePopup} />}
            { 
                showModal && 
                <CustomModal title={"Configurar indicador"} show={showModal} setShow={setShowModal}>
                    <iframe title={"Configurar indicador"} style={{border:0}} width="100%" height="500" src={ Utils.getAdminUrl(`/indicator-relevant/module/${module.id}`) }></iframe>
                </CustomModal>
            }
        </div>
    );
}
export default Edit;