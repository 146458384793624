import {useContext} from "react";
import { Modal } from "react-bootstrap";
import { Button } from "../commonStyles";
import { ModalWrapper } from "./styles";
import { UserContext } from '../Context/User';

function CustomModal(props) {
    const { colorPalette } = useContext(UserContext);

    const handleClose = () => {
        props.setShow(false)
        if (props.handleClose) {
            props.handleClose()
        }
    };

    return (
        <ModalWrapper>
            <Modal show={props.show} onHide={handleClose} size={props.size}  backdrop="static" keyboard={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.children}
                </Modal.Body>

                {!props.hideFooter && <Modal.Footer>
                    <Button color={colorPalette.secondary} variant="secondary" onClick={handleClose}>Cerrar</Button>
                    {
                        props.handleSaveButton &&
                        <Button color={colorPalette.primary} className="modal-primary" onClick={props.handleSaveButton}>{props.saveButtonText || "Guardar cambios"}</Button>
                    }
                </Modal.Footer>
                }
            </Modal>
        </ModalWrapper>

    );
}
export default CustomModal;