import styled from "styled-components";
import { Theme1 } from "../../themes/Theme1";

export const ButtonsContent = styled.div`
    width: 100%;
    display:flex;
    justify-content: flex-end;
    margin-bottom: 15px;

`;

export const PerspectiveWrapper = styled.div`
    width: 100%;
    border-radius: ${Theme1.radius};
    background-color: white;
    padding: 10px;
    box-shadow: ${Theme1.shadow};
    margin-bottom: ${props => props.design ? "20px": "10px"};
    position: relative;
    :first-child{
        &.is-grouped{
            margin-top: unset !important;
            &:after{
                display: none !important;
            }
        }
    }
    &.is-grouped{
        margin-top: -20px;
        padding-top: 30px;
        &:after{
            content: "";
            display: block;
            width: 100%;
            height: 30px;
            background-color: white;
            top: -20px;
            left: 0;
            position: absolute;
            border-bottom: ${Theme1.borderTwo};
        }
    }
`;
export const PerspectiveTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: ${props => props.design ? "space-between": "center"};
    align-items: center;

    & .empty {
        width: 120px;
    }
`;

export const PerspectiveTitle = styled.div`
    width: 300px;
    font-size: 18px;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
    text-align: ${props => props.design ? "left": "center"};
    &::after{
        content: "";
        width:  ${props => props.design ? "120px": "280px"};
        text-align: ${props => props.design ? "left": "center"};
        height: 2px;
        background-color: ${props => props.color};
        position: absolute;
        bottom: 8px;
        left: 0;
    }
`;
export const PerspectiveTitleHandle = styled.div`
    color: ${Theme1.grayText};
    font-size: 12px;
    cursor: pointer;
    min-width: 112px;
    &:hover{
        color: ${props => props.color};
    }
`;

export const ObjetiveWrapperCollapse = styled.div`
    width: 100%;
    position: relative;
`;

export const ObjetiveContainerCollapse = styled.div`
    display: block;
    width: 100%;
    max-width: 470px;
    border-radius: ${Theme1.radius};
    background-color: white;
    padding: 20px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    box-shadow: ${Theme1.shadow};
    .objetive_content{
        width: 100% !important;
        &>div{
            width: 100% !important;
        }
    }
`;

export const ObjetiveWrapper = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
    justify-content: center;
`;

export const ObjetiveContent = styled.div`
    width: 100%;
    padding: 10px;
    &.is-transversal{
        width: 100%;
        &>div{
            width: 100%;
        }
    }
    &.is-unique{
        width: 100%;
        &>div{
            width: 50%;
            margin: 0 auto;
        }
    }
    table{
        font-size: 12px;
    }
`;

export const ObjetiveContainer = styled.div`
    
    width: 100%;
    border-radius: ${Theme1.radius};
    .container-table {
        border: ${Theme1.borderTwo};
        border-radius: ${Theme1.radius};
        font-size: 12px;
        max-height: 300px;
        overflow-y: auto;

        & table {
            position: relative;
        }
        & table > thead {
            position: sticky;
            top: 0;
            left: 0;
            background-color: white;
        }
        & table > thead th:first-child, & table> tbody > tr > td:first-child {
           width: 40%;
        }

        & table tbody > tr {
            border-bottom: ${Theme1.borderTwo};
            font-size: 10px;
            padding: 0.5rem;
        }
    }
    .objective-title{
        font-size: 14px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        color: white;
        background-color: ${props => props.backGroundColor};
        padding: 10px 10px;
        align-items: center;
        border-radius: 10px;
        border: medium solid  ${props => props.backGroundColor};
        .label{
            padding-left: 0px;
            span{
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 10px;
                border: 1px solid rgb(204, 204, 204);
                margin-right: 5px;
            }
        }
        &:hover{
            box-shadow: ${Theme1.shadowContainer};
        }
        
        &.color-gray{
            .label{
                span{
                    background-color: gray;
                }
            }
        }
        &.color-red{
            .label{
                span{
                    background-color: red;
                }
            }
        }
        &.color-green{
            .label{
                span{
                    background-color: green;
                }
            }
        }
    }
`;

export const IndicatorLabel = styled.div`
    color: ${Theme1.grayText};
    cursor: pointer;
    font-weight: bold;
    &:hover{
        text-decoration: underline;
    }
`;
export const ObjetiveJerarquico = styled.div`
    display: flex;
`;
export const StyleArrow = styled.div`
    background: url(${props => props.image});
    height: 25px;
    width: 100%;
    color: red
`;
