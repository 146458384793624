import { useContext, useState } from "react";
import { useEffect } from "react";
import { Form, FormGroup, Table } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { Button, StyleCheckbox } from "../../../../commonStyles";
import { AlertForm, ICON } from "../../../../Components/SweetAlert";
import { UserContext } from "../../../../Context/User";
import Utils from "../../../../utils";
import { WrapperButton } from "../../styles";

function AdminFilters({page}){
    const { colorPalette } = useContext(UserContext);
    const [sections, setSections]=useState([]);
    const [form, setForm] = useState({idpage:page.id, filters:[]});
    useEffect(()=>{
        Utils.Petition.get('/portals-filter', (response) => {
            let _filter = [];
            for (let i = 0; i < response.data.length; i++) {
                _filter.push({ value: response.data[i].filter.id.toString(), description: response.data[i].filter.name, default: response.data[i].default})
            }
            Utils.Petition.get('/hide-filters/page/'+page.id, (response)=>{
                let _form = JSON.parse(JSON.stringify(form));
                for (let i = 0; i < response.data.length; i++) {
                    _form.filters.push(response.data[i].idfilter.toString());
                }
                setForm(_form);
            })
            setSections(_filter);
        });
    }, []);

    const handleChange = (event) =>{
        let _form = JSON.parse(JSON.stringify(form));
        const checked = event.target.checked;
        const id = event.target.name.split('-')[1];
        if(checked){
            if(!_form.filters.includes(id)){
                _form.filters.push(id);
            }
        }else{
            if(_form.filters.includes(id)){
                _form.filters.splice(_form.filters.indexOf(id), 1);
            }
        }
        setForm(_form);
    }
    const saveHideFilters = ()=>{
        Utils.Petition.post('/hide-filters', form, (response)=>{
            AlertForm(ICON.SUCCESS, response.message, "");
        });
    }

    return (
        <Form>
            <Form.Group>
                <Table>
                    <thead>
                        <tr>
                            <th width="85">Ocultar</th>
                            <th>#</th>
                            <th>Nombre</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        sections.map((section, i)=>{
                            return (
                                <tr key={i}>
                                    <td align="center">
                                        <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                                            <input className="custom-control-input" name={"filter-"+section.value} id={"filter-"+section.value} type="checkbox" checked={form.filters.includes(section.value)} onChange={handleChange} disabled={section.default==='1'}/>
                                            <label className="custom-control-label" htmlFor={"filter-"+section.value}>
                                            </label>
                                        </StyleCheckbox>
                                    </td>
                                    <td>{section.value}</td>
                                    <td>{section.description}</td>
                                </tr>
                            )
                        })    
                    }
                    </tbody>
                </Table>
            </Form.Group>
            <FormGroup>
                <div>
                    <Button color={colorPalette.primary} onClick={saveHideFilters}>Guardar cambios <FaSave /></Button>
                </div>
            </FormGroup>
        </Form>
    )
}
export default AdminFilters;