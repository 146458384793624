import Templates from './Templates';
import { Dashboard, DashboardWrapper, DashboardHeader, DashboardContent, DashboardSidebar, DashboardContentWrapper, DashboardContainertWrapper } from './styles';
import { Sidebar } from './Sidebar';
import { Head } from './Head';
import { useRef } from 'react';

function MasterPage({ editMode, setEditMode, globalProps, }) {
    const showPage = useRef(null);
    const showSidebar = useRef(null);

    const handleSidebar = () => {
        showPage.current.classList.toggle("active-Menu");
        showSidebar.current.classList.toggle("active-Menu");
    }
    return (
        <>
            <Dashboard>
                <DashboardHeader>
                    <Head globalProps={globalProps} editMode={editMode} setEditMode={setEditMode} onPress={handleSidebar} />
                </DashboardHeader>
                <DashboardWrapper>
                    <DashboardContent ref={showPage} className="active-Menu">
                        <DashboardSidebar ref={showSidebar} className="active-Menu">
                            <Sidebar />
                        </DashboardSidebar>
                        <DashboardContentWrapper id="main-content">
                            <DashboardContainertWrapper>
                                
                                <Templates globalProps={globalProps} />
                            </DashboardContainertWrapper>
                        </DashboardContentWrapper>
                    </DashboardContent>

                </DashboardWrapper>
            </Dashboard>
        </>
    );
}

export default MasterPage;