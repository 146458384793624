import Utils from ".";
import { counter } from "../App"

export const registerVisit = (timeSpent, amountEndpoint, inactivity,reportByHour = false) => {
        console.log(counter.number, amountEndpoint,counter.startTime.length,counter.endTime.length)
        let ocupation = JSON.parse(localStorage.getItem("user_ocupation"));
        let tiempoRebote = JSON.parse(localStorage.getItem("config")).tiempo_rebote;
        
        let data = {
            client: localStorage.getItem('client'),
            filter: localStorage.getItem('filter'),
            portal: localStorage.getItem('portal'),
            business: localStorage.getItem('business'),
            rolAdmin: localStorage.getItem('rolAdmin'),
            rolContent: localStorage.getItem('rolContent'),
            year: localStorage.getItem('year'),
            month: localStorage.getItem('month'),
            view: localStorage.getItem('view'),
            page: localStorage.getItem("pageName"),
            department: ocupation.department,
            position: ocupation.position,
            idUserPlatform: Number(localStorage.getItem("idUserPlatform"))
        }
        if(reportByHour) { /* reporte cada hora saber la concurrencia */
            Utils.Petition.post('/users/visit', {
                ...data,
                cargaPagina: localStorage.getItem("cargaPagina"),
                permanencia: "N/A",
                tasaRebote: "N/A",
                idUserPlatform: "ReporteHora"
            });
        } else if((counter.number && counter.number === counter.startTime.length && counter.number === counter.endTime.length)) { /* Cambia de pagina */
           
            let calcInitialTime = Math.min(...counter.startTime);
            let calcEndTime = Math.max(...counter.endTime);
            let cargaPagina = counter.enabled ? calcEndTime : calcEndTime - calcInitialTime;
            localStorage.setItem("cargaPagina", cargaPagina);
            Utils.Petition.post('/users/visit', {
                ...data,
                cargaPagina: cargaPagina,
                permanencia: timeSpent,
                tasaRebote: Number(tiempoRebote) * 1000 > timeSpent /* tiempo de rebote < tiempo permanencia, si se cumple es true tasa de rebote */
            });
        } else if(inactivity) { /* Registro por inactividad */

            let cargaPagina = localStorage.getItem("cargaPagina");
            Utils.Petition.post('/users/visit', {
                ...data,
                cargaPagina: Number(cargaPagina),
                permanencia: timeSpent,
                tasaRebote: false 
            });

        } else if(counter.number === 0 && amountEndpoint === 0 && counter.startTime.length === 0 && counter.endTime.length === 0) {   /* Registro por  post inactividad y cambia de pagina*/
            let cargaPagina = localStorage.getItem("cargaPagina");
            Utils.Petition.post('/users/visit', {
                ...data,
                cargaPagina: cargaPagina,
                permanencia: timeSpent,
                tasaRebote: Number(tiempoRebote) * 1000 > timeSpent /* tiempo de rebote < tiempo permanencia, si se cumple es true tasa de rebote */
            });
        } else { /* LA pagina no cargo en su totalidad  */
            Utils.Petition.post('/users/visit', {
                ...data,
                cargaPagina: timeSpent,
                permanencia: timeSpent,
                tasaRebote: true 
            });
        }
        counter.number = 0; 
        counter.startTime = [];
        counter.endTime = [];
        counter.enabled = false;
}
