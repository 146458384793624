import { useState, useEffect, useRef, useContext } from 'react';
import ValueMap from '../modules/value-map';
import Html from '../modules/html';
import AdminReport from '../modules/admin-report';
import Graphic from '../modules/graphic';
import Table from '../modules/table';
import TableCostumer from '../modules/tableCostumer'
import Menu from '../modules/menu';
import StrategicMap from '../modules/strategic-map';
import Utils from '../utils';
import EditModule from './EditModule';
import { FaPlus, FaRedo } from 'react-icons/fa';
import HorizontalTabs from './section-type/HorizontalTabs';
import VerticalTabs from './section-type/VerticalTabs';
import UserGraphic from '../modules/user-graphic';
import PlantillaWeb from '../modules/PlantillaWeb';
import IndicatorRelevant from '../modules/indicator-relevant';
import { Dropdown } from 'react-bootstrap';
import { SectionSettings, SectionTitle, SectionPestañas, StylesDiv, SeccionWrapperHorizontal } from './styles';
import { UserContext } from '../Context/User';
import { Button, StyleCheckbox } from '../commonStyles';

const allModules = {
    Html,
    ValueMap,
    AdminReport,
    Graphic,
    Table,
    Menu,
    UserGraphic,
    StrategicMap,
    PlantillaWeb,
    IndicatorRelevant,
    TableCostumer
}

function Section({ globalProps, popupStructure, templateZonesLoading, page, code, setPopupStructure, hideTabs }) {
    const { hasPermission, colorPalette } = useContext(UserContext);
    const [modules, setModules] = useState([]);
    const [orders, setOrders] = useState([]);
    const [isMenuAddModule, setIsMenuAddModule] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [horizontalMode, setHorizontalMode] = useState(false);
    const [tabsMode, setTabsMode] = useState(false);
    const [tabsModeType, setTabsModeType] = useState('horizontal');
    const mountedRef = useRef(false);
    const [amountModules, setAmountModules] = useState(0);
    useEffect(() => {
        mountedRef.current = true
        return () => {
            mountedRef.current = false
        }
    }, []);
    useEffect(() => {
        if (localStorage.getItem('editable') === '1') {
            setEditMode(true);
        }
        if (!templateZonesLoading[code].loaded || globalProps.reloadContent) {
            let _orders = [];
            if(popupStructure){
                Utils.Petition.get(
                    '/pages-modules/popup/' + popupStructure.id + '/section/' + code + '/year/' + localStorage.getItem('year')+ '/month/' + localStorage.getItem('month') + '/filter/' + localStorage.getItem('idfilter') + '/view/' + localStorage.getItem('view'),
                    (response) => {
                        for (let i = 0; i < response.data.length; i++) {
                            _orders.push({ id: response.data[i].id, order: (i + 1) });
                        }
                        if (mountedRef.current) {
                            setOrders(_orders);
                            setModules(response.data);
                            setAmountModules( response.data.length );
                        }
                    }
                );
            }else{
                Utils.Petition.get(
                    '/pages-modules/page/' + page.id + '/section/' + code + '/year/' + localStorage.getItem('year') + '/month/' + localStorage.getItem('month') + '/filter/' + localStorage.getItem('idfilter') + '/view/' + localStorage.getItem('view'),
                    (response) => {
                        for (let i = 0; i < response.data.length; i++) {
                            _orders.push({ id: response.data[i].id, order: (i + 1) });
                        }
                        if (mountedRef.current) {
                            setOrders(_orders);
                            setModules(response.data);
                            setAmountModules( response.data.length );
                        }
                    }
                );
            }
        }
        globalProps.setReloadContent(false);
        templateZonesLoading[code].setLoaded(true);
        const configuration = page.page_section_configurations.filter((configuration => configuration.section_code === code));
        if (configuration.length) {
            if (configuration[0].tabs === '1') {
                setTabsMode(true);
            }
            setTabsModeType(configuration[0].tabs_type);
            if(configuration[0].horizontal==='1') setHorizontalMode(true);
        }


    }, [templateZonesLoading[code].loaded, popupStructure, globalProps.reloadContent]);

    const handleRefreshSection = () => {
        templateZonesLoading[code].setLoaded(false);
    }
    const handleAddModule = (event) => {
        event.preventDefault();

        const pageModule = {
            section: code,
            name: event.target.dataset.moduleCode,
            module_code: event.target.dataset.moduleCode,
            config: {},
            order: 0,
            idfilter: localStorage.getItem('idfilter')
        };
        if(popupStructure){
            pageModule.idpopup = popupStructure.id;
        }else{
            pageModule.idpage = page.id;
        }
        
        Utils.Petition.post('/pages-modules',
            pageModule,
            (response) => {
                setAmountModules( modules.length + 1 );
                setModules([...modules, response.data]);
                templateZonesLoading[code].setLoaded(false);
                setIsMenuAddModule(false);
            })
    }
    const handleTabsMode = (event) => {
        setTabsMode(event.target.checked);
        Utils.Petition.post('/page-section-configuration/update', { idpage: page.id, section_code: code, tabs: (event.target.checked) ? '1' : '0', tabs_type: tabsModeType })
    }
    const handleHorizontalMode = (event) => {
        setHorizontalMode(event.target.checked);
        Utils.Petition.post('/page-section-configuration/update', { idpage: page.id, section_code: code, horizontal: (event.target.checked) ? '1' : '0' })
    }
    const handleTabsModeType = (event) => {
        setTabsModeType(event.target.value);
        Utils.Petition.post('/page-section-configuration/update', { idpage: page.id, section_code: code, tabs_type: event.target.value })
    }
    const componentRender = () => {
        const status = editMode ? 'Edit' : 'View';
        if (status === 'Edit' || !tabsMode) {
            return (
                <SeccionWrapperHorizontal className={horizontalMode ? (editMode ? 'horizontal-edit': 'horizontal-mode'):null} amountModules ={ amountModules }>
                    {

                    modules.map((module, index) => {
                        const ComponentTagName = allModules[module.module_code][status];
                        if (status === 'Edit') {
                            return <EditModule
                                key={module.id}
                                order={orders[index]}
                                orders={orders} setOrders={setOrders}
                                templateZonesLoading={templateZonesLoading}
                                ComponentTagName={ComponentTagName}
                                modules={modules} setModules={setModules}
                                page={page}
                                module={module}
                            />
                        }
                        return (

                            <div key={module.id} id={`page_${page.id}_section_${code}_module_${module.id}`} className={`section-page-${page.id}-section-${code}-module-${module.id} module-component module-${module.module_code} module-status-${status}`}>
                                {
                                    <ComponentTagName setPopupStructure={setPopupStructure} templateZonesLoading={templateZonesLoading} page={page} module={module} />
                                }
                            </div>
                        )
                    })
                }
                </SeccionWrapperHorizontal>
            )
        } else {
            return (
                modules.length > 0 &&
                (
                    <>
                        {tabsModeType === 'vertical' && <VerticalTabs allModules={allModules} modules={modules} page={page} status={status} />}
                        {tabsModeType !== 'vertical' && <HorizontalTabs allModules={allModules} modules={modules} page={page} status={status} />}
                    </>
                )
            )
        }
    }
    return (
        <StylesDiv id={(popupStructure !== undefined) ? ('popup_' + popupStructure.id + '_section_' + code) : ('page_' + page.id + '_section_' + code)} className={'section-component section-page-' + page.id + '-section-' + code + (editMode ? ' section-editable-container' : '')}>
            {
                editMode &&
                <div className="section-actions">
                    <SectionSettings colors={colorPalette}>
                        <Dropdown>
                            <Dropdown.Toggle>
                                <FaPlus />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                {
                                    globalProps.moduleList.length &&
                                    globalProps.moduleList.map((module) => {
                                        return (

                                            <Dropdown.Item key={module.id} data-module-code={module.code} onClick={handleAddModule}>{module.name}</Dropdown.Item>

                                        )
                                    })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Button color={colorPalette.primary}><FaRedo onClick={handleRefreshSection} /></Button>


                    </SectionSettings>
                    <SectionTitle>
                        {code}
                    </SectionTitle>
                    
                    <SectionPestañas>
                    <div>
                        {
                            popupStructure === undefined &&
                            <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                                <input className="custom-control-input" id={`horizontal_${page.id}_section_${code}_module_${module.id}`} type="checkbox" checked={horizontalMode} onChange={handleHorizontalMode} disabled={!hasPermission.filterRegion}/>
                                <label className="custom-control-label" htmlFor={`horizontal_${page.id}_section_${code}_module_${module.id}`}>
                                    Mostrar módulos horizontalmente
                                </label>
                            </StyleCheckbox>
                        }
                    </div>
                        {
                            !hideTabs &&
                            popupStructure === undefined &&
                            <>
                                <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                                    <input className="custom-control-input" id={`tabs_${page.id}_section_${code}_module_${module.id}`} type="checkbox" checked={tabsMode} onChange={handleTabsMode} disabled={!hasPermission.filterRegion}/>
                                    <label className="custom-control-label" htmlFor={`tabs_${page.id}_section_${code}_module_${module.id}`}>
                                        Mostrar la sección en pestañas
                                    </label>
                                </StyleCheckbox>
                                <br />
                                {
                                    tabsMode &&
                                    <label>
                                        Presentación de pestañas:
                                        <select value={tabsModeType} onChange={handleTabsModeType} disabled={!hasPermission.filterRegion}>
                                            <option value="horizontal">
                                                Horizontal
                                            </option>
                                            <option value="vertical">
                                                Vertical
                                            </option>
                                        </select>
                                    </label>
                                }
                            </>
                        }
                    </SectionPestañas>

                </div>
            }
            {componentRender()}
        </StylesDiv>
    );
}

export default Section;