import { useEffect, useState, useContext } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import Indicator from "./Indicator";
import { ObjetiveContainer } from "./styles";
import { UserContext } from "../../Context/User";

function Objective({config, objective, indicatorData, setShowIndicatorsDetailModal, setSelectedIndicatorDetail, showObjective, designSecondary = false}){
    const { colorPalette } = useContext(UserContext);
    const [show, setShow] = useState(false);
    const [colorBG, setColorBG] = useState("");
    useEffect(()=>{
        setShow(showObjective);
        getColor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showObjective])
    const getColor = ()=>{
        let greens = 0;
        let reds = 0;
        let finalColor = '#808080';
        for(let i in objective.data){
            let _color = calculateColor(indicatorData[objective.data[i]]);
            if(_color === 'yellow') return setColorBG('#e6da00');
            if(_color==='green') greens++;
            else if(_color==='red') reds++;
        }

        if(greens || reds){
            if(!greens) finalColor='#ff0000';
            else if(!reds) finalColor='#008000';
            else finalColor='#e6da00';
        }
        setColorBG(finalColor);
    }
    const calculateColor = (indicator)=>{
        let base = 100;
        if((config.stages.includeYellow  && config.stages.unitPercent) || config.stages.unitPercent ) {
            base = indicator.unit === '%' ? 0: 100;
        }

        // let base =  config.stages.includeYellow ? 100: indicator.unit === '%' ? 0: 100;
        if(indicator.sign===null) return 'gray';
        if(base === parseFloat(indicator.sign)) return 'green';

        if(config.stages.descendingIndicator) {
            if(indicator.type === 'C'){
                if(parseFloat(indicator.sign)<base) return 'red';
                return 'green';
            }
            else {
                if(parseFloat(indicator.sign)>base) return 'red';
                return 'green';
            }
        }
        if(parseFloat(indicator.sign)>base) return 'green';
        if(config.stages.includeYellow && parseFloat(indicator.sign)/base > Number(config.stages.percentYellow)/100) return 'yellow';
        return 'red';
    }

    return (
        
        <Col sm={4}  className={((objective.transversal || objective.unique) && 'col-sm-12') + ' py-3 px-1'}>
            <Row>
                <Col className={(!objective.transversal && objective.unique && 'col-sm-4 offset-md-4') + ' px-0'}>
                    <ObjetiveContainer color={colorPalette.primary} backGroundColor={colorBG} >
                        <div onClick={()=>setShow(!show)} className={"objective-title color-"+ colorBG}>
                            <div className="label">{objective.name}</div>
                            <span className="icon">{show ? <FaAngleUp /> : <FaAngleDown />}</span>
                        </div>
                        {
                            show &&
                            <div className="container-table">
                                {
                                    <Table striped={designSecondary}>
                                        <thead className={designSecondary && "bg-dark text-white"}>
                                            <tr>
                                                <th>Indicador</th>
                                                <th>{config.stages.col1.name}</th>
                                                {
                                                    config.stages.col2.name !== '' &&
                                                    <th>{config.stages.col2.name}</th>
                                                }
                                                {
                                                    config.stages.col3.name !== '' &&
                                                    <th>{config.stages.col3.name}</th>
                                                }
                                                {
                                                    config.stages.col4.name !== '' && config.stages.col4.name !== undefined  &&
                                                    <th>{config.stages.col4.name}</th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            objective.data.map((indicator, index)=>{
                                                return (
                                                    <Indicator key={index} setShowIndicatorsDetailModal={setShowIndicatorsDetailModal} setSelectedIndicatorDetail={setSelectedIndicatorDetail} config={config} indicator={indicator.toString()} indicatorData={indicatorData} />
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                }
                            </div>
                        }
                    </ObjetiveContainer>
                </Col>
            </Row>
        </Col>
    );
}
export default Objective;