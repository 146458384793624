import styled from "styled-components";

export const Header = styled.div`
    width: 100%;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 80px;
    background-color: #ffffff;
`;
export const SectionLogo = styled.div`
    display: flex;
    button{
        background-color: transparent;
        color: ${props => props.colors?.grayText};
        border: none;
        font-size: 12px;
        &:hover, &:active, &:focus {
            color: ${props => props.colors?.primary} !important;
            background-color: transparent !important;
        }
    }
`;
export const SectionAccount = styled.div`
    display: flex;
    .dropdown{
        margin-left: 15px;
        button{
            font-size: 12px;
            background-color: transparent;
            color : ${props => props.colors?.secondary};
            border: none;
            &:hover, &:active, &:focus {
            color: ${props => props.colors?.primary} !important;
            background-color: transparent !important;
        }
        }
    }
`;

export const Logo = styled.div`
    width: 100%;
    max-width: 120px;
    max-height: 60px;
    margin-right: 65px;
    img{
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
    }
    & .mi-logo {
        font-size: 1.6rem;
        font-weight: bold;
    }
    a {
        color: ${props => props.color};
        & p {
            margin: 0;
        }
    }
    
`;

export const ContainerSearch = styled.section`
    position: absolute;
    top: 4rem;
    left: 0;
    box-shadow: 0px 0px 20px 0 rgb(0 0 0 / 30%);
    max-height: 600px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 0.5rem;
    width: 600px;
    background-color: white;
    padding: 0.5rem;
    padding-bottom: 2rem;
    z-index: 100;
    & table {
        margin-top: 1rem;
    }
`;

export const ContainerRelative = styled.div`
    position: relative;
`;