import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Utils from "../utils";

function StackedBarChart({dataConfig, data}) { 

    const [chartData, setChartData] = useState({});
    const colors = ['#204f79', '#3074b6', '#8597b0', '#8eaadc', '#bdd7ee', '#aad18e', '#71ad47', '#548234', '#395723', '#e1f0d8']
    const [box, setBox] = useState(true);
    const format_number = Utils.getConfigPortal('format_number');
    const format_percent = Utils.getConfigPortal('format_percent');
    const chart = () => {   
        
        const dataSetInfo = []; 
        let xlabels = dataConfig.columns.map(column => column.columnLabel)
        let numbersTotal = [];
        let colorLabelsTotal = {};
        if(dataConfig.infoTotal) {
            if ( dataConfig.otherIndTotal ) {
                for( let clave in dataConfig.othersIndForTotal) {
                    let aux_total = new Array(dataConfig.othersIndForTotal[clave].indicator.length);
                    for( let i = 0; i < aux_total.length; i++) {
                        let data_total = dataConfig.othersIndForTotal[clave].indicator[i];
                        let colorAux = data_total.hasOwnProperty('totalColor') ? data_total.totalColor : '#000000'
                        if ( !colorLabelsTotal.hasOwnProperty(`${i}`) ) {
                            colorLabelsTotal = {
                                ...colorLabelsTotal,
                                [`${i}`]: [colorAux]
                            }
                        } else {
                            colorLabelsTotal[`${i}`].push(colorAux)
                        }
                        //console.log(data_total)
                        for(let j=0; j<data.length; j++) {
                            if(data_total.id === data[j].indicator && data_total.stage.id === data[j].stage && data[j].value !== null ){
                                let value  = Utils.formatNumber(format_number, data[j].value);
                                if( data_total.percent ) value = Utils.formatNumber(format_percent, data[j].value) + "%";
                                if( data_total.peso ) value = "$" + value;
                                aux_total[i] = value;
                                break;
                            }
                        }
                    }
                    
                    numbersTotal.push(aux_total)
                }
            } else {
                dataConfig.indicatorTotalSelected.map( ind => {
                    let aux_total = new Array(dataConfig.stagesTotalSelected.length);
                    dataConfig.stagesTotalSelected.map((stage, index) => {
                        for(let i=0; i<data.length; i++) {
                            if(ind.id === data[i].indicator && stage.id === data[i].stage && data[i].value !== null ){
                                let value  = Utils.formatNumber(format_number, data[i].value);
                                if( stage.percent ) value = Utils.formatNumber(format_percent, data[i].value) + "%";
                                if( stage.peso ) value = "$" + value;
                                aux_total[index] = value;
                                break;
                            }
                        }
                    })
                    numbersTotal.push(aux_total)
                })
            }
            
        }
        let aux_labelsSerie = [];

        if(dataConfig.stagesSerie && !dataConfig.otherIndSerie) {
            aux_labelsSerie = dataConfig.stagesSerieSelected.map( stage => stage.hasOwnProperty('serieLabel') && stage.serieLabel.trim() !== "" ? stage.serieLabel : stage.name )
        }

        dataConfig.series.map((serie, index) => {
            let info = {};
            info.backgroundColor = [];
            let numbersSerie = [];
            let labelsSerie = [];
            
            let labelSerie = serie.serieLabel;
            info.label = labelSerie;
           
            
            let dataTemp = dataConfig.columns.map((col, indexC) => {
                let temp = 0;
                let columnsOfSerie = dataConfig.indicatorsStages.filter(indStage => indStage.serieLabel === labelSerie && indStage.columnLabel === col.columnLabel)[0];
                if (columnsOfSerie !== undefined) {
                    let aux_numbers = [];
                    let aux_labels = [];
                    let info_col = [];

                     // Busca en indicador Stage su color
                     let colorMatch;
                     if( serie.multipleColors && !columnsOfSerie.checked){
                         colorMatch = colors[indexC] || "#B3B2B2";
                         setBox(false);
                     } else if(columnsOfSerie.checked) {
                         colorMatch = columnsOfSerie.background;
                     } else {
                         colorMatch = serie.serieColor;
                     }
                     info.backgroundColor.push(colorMatch);
                    if(dataConfig.stagesSerie) {
                        if(dataConfig.otherIndSerie) {
                            info_col = dataConfig.othersIndForLabels[`${index}-${indexC}`].indicator;
                            aux_numbers = new Array(info_col.length);
                            aux_labels = new Array(info_col.length);
                        } else {
                            aux_numbers = new Array(dataConfig.stagesSerieSelected.length);
                        }
                    }

                    for(let i=0; i<data.length; i++) {
                        if(columnsOfSerie.indicator.id === data[i].indicator && columnsOfSerie.stage.id === data[i].stage && data[i].value !== null ){
                            temp = data[i].value;
                        }
                        if( dataConfig.stagesSerie) {
                            if(!dataConfig.otherIndSerie) {
                                for( let j=0; j < aux_numbers.length; j++) {
                                    if(columnsOfSerie.indicator.id === data[i].indicator && dataConfig.stagesSerieSelected[j].id === data[i].stage && data[i].value !== null ){
                                        let value  = Utils.formatNumber(format_number, data[i].value);
                                        if( dataConfig.stagesSerieSelected[j].percent) value = Utils.formatNumber(format_percent, data[i].value) + "%";
                                        if( dataConfig.stagesSerieSelected[j].peso) value = "$" + value;
                                        aux_numbers[j] = value;
                                        aux_labels[j] = aux_labelsSerie[j]
                                    }
                                }
                            } else {
                                for( let j=0; j < aux_numbers.length; j++) {
                                    if( info_col[j].id === data[i].indicator && info_col[j].stage.id === data[i].stage && data[i].value !== null) {
                                        let value  = Utils.formatNumber(format_number, data[i].value);
                                        if( info_col[j].percent ) value = Utils.formatNumber(format_percent, data[i].value) + "%";
                                        if( info_col[j].peso ) value = "$" +value;
                                        aux_numbers[j] = value;
                                        aux_labels[j] = info_col[j].hasOwnProperty('serieLabel') && info_col[j].serieLabel.trim() !== "" ? info_col[j].serieLabel : info_col[j].name.trim();
                                    }
                                }
                                
                            }
                            
                        }
                    }
                    numbersSerie.push(aux_numbers);
                    labelsSerie.push(aux_labels);
                    return temp;    
                } else {
                    numbersSerie.push([]);
                    if (dataConfig.stagesSerie && dataConfig.otherIndSerie ) labelsSerie.push([]);
                    return temp
                }
            })
            info.borderRadius = 10;  
            info.dataM = numbersSerie;
            info.data = dataTemp;
            info.nameLabels = labelsSerie;
            if( index === dataConfig.series.length - 1) {
                let colorV = 'white';
                if(serie.hasOwnProperty('serieLabelColor') && serie.serieLabelColor !== '#777273' ) colorV = serie.serieLabelColor;
                info.dataT = numbersTotal;
                if(!serie.hideLabel) {
                    info.datalabels = {
                        labels: {
                            value: {
                                color: colorV,
                                textAlign: 'center',
                                formatter: function(value, ctx) {
                                    let val = ctx.dataset.dataM[ctx.dataIndex];
                                    let resp = "";
                                    if( val.length > 0 ) {
                                        for(let i=0; i<val.length; i++) {
                                            if (val[i] !== undefined) {
                                                if( i + 1 === val.length) resp = resp + val[i];
                                                else resp = resp + val[i] + '\n';
                                            }
                                        }
                                    }
                                    return resp;
                                }
                            }
                        }
                    }
                } else {
                    info.datalabels = {
                        labels: {
                            value: {
                                formatter: function(value, ctx) {
                                    return ""
                                }
                            }
                        }
                    }
                }
                
                if(dataConfig.infoTotal) {
                    if ( !dataConfig.otherIndTotal){
                        let var_off = 22;
                        let offset = var_off*(dataConfig.stagesTotalSelected.length-1);
                        dataConfig.stagesTotalSelected.map((stage, index) => {
                            let color = 'black';
                            if(stage.hasOwnProperty('totalColor') && stage.totalColor !== "'#777273'") color = stage.totalColor;

                            info.datalabels.labels["label_" + index] = {
                                color,
                                font: {
                                    weight: 'bold',
                                    size: 13
                                },
                                anchor: 'end',
                                align: 'top',
                                offset,
                                textAlign: 'center',
                                formatter: function(value, ctx) {
                                    let resp = "";  
                                    if( numbersTotal.length > 0 && Object.keys(numbersTotal[ctx.dataIndex]).length > 0 && numbersTotal[ctx.dataIndex].length > 0 && numbersTotal[ctx.dataIndex][index] !== undefined ) {
                                        return numbersTotal[ctx.dataIndex][index]
                                    }
                                    return resp;
                                },
                            }
                            offset = offset - var_off + 4;
                        })
                    }
                    else {

                        let var_off = 22;
                        let offset = var_off*(numbersTotal[0].length-1);

                        for( let k = 0; k<numbersTotal[0].length; k++) {
                            let color = colorLabelsTotal[`${k}`];
                            info.datalabels.labels["label_" + k] = {
                                color,
                                font: {
                                    weight: 'bold',
                                    size: 13
                                },
                                anchor: 'end',
                                align: 'top',
                                textAlign: 'center',
                                offset,
                                formatter: function(value, ctx) {
                                    let resp = ""; 
                                    //console.log(numbersTotal[ctx.dataIndex])
                                    if( numbersTotal.length > 0 && numbersTotal[ctx.dataIndex].length > 0 && numbersTotal[ctx.dataIndex][k] !== undefined ) {
                                        return numbersTotal[ctx.dataIndex][k];
                                    }
                                    return resp;
                                },
                            }
                            offset = offset - var_off + 4;
                        }
                    }
                }
                
            } else {
                if ( !serie.hideLabel ) {
                    let color = 'white';
                    if(serie.hasOwnProperty('serieLabelColor') && serie.serieLabelColor !== '#777273' ) {
                        color = serie.serieLabelColor;
                    }
                    info.datalabels = {
                        color,
                        textAlign: 'center',
                        font: {
                            size: 13
                        },
                        formatter: function(value, ctx) {
                            let val = ctx.dataset.dataM[ctx.dataIndex]
                            let resp = "";
                            if( val.length > 0 && val[0] !== undefined ) {
                                for(let i=0; i<val.length; i++) {
                                    if(val[i] !== undefined) {
                                        if( i + 1 === val.length) resp = resp + val[i];
                                        else resp = resp + val[i] + '\n';
                                    }
                                }
                            }
                            return resp;
                        }
                    }
                } else {
                    info.datalabels = {
                        formatter: function(value, ctx) {
                            return ""
                        }
                    }
                }
                
            }
            
            dataSetInfo.push(info)
        });
        
        setChartData({
            labels: xlabels,
            datasets: dataSetInfo
        })
    }

    useEffect(() => {
        chart();
      }, []);

    return (
        <div>
            <Bar
                data={chartData}
                height={dataConfig.height !== "" ? dataConfig.height: 400}
                width={600}
                plugins = {[ChartDataLabels]}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            display: !dataConfig.hideY,
                            stacked: true,
                            grid: {
                                display: false
                            },
                            ticks: {
                                callback: function(value, index) {
                                    return dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft  === "$" ?  "$" +  Utils.formatNumber(format_number,value) : Utils.formatNumber(format_percent,value)  + "%"  :  Utils.formatNumber(format_number,value)
                                }
                            }
                        },
                        x: {
                            stacked: true,
                            grid: {
                                display: false
                            }
                        }
                    },
                    plugins: {
                        legend: { 
                            labels: {
                                boxWidth: box ? 40 : 0,
                                padding: 5,
                                usePointStyle: true,
                                pointerStyle: 'circle'
                            },
                            display: !dataConfig.hideLegend, 
                            position: dataConfig.hasOwnProperty('legendPosition') && dataConfig.legendPosition !== "PorDefecto" ?
                                        dataConfig.legendPosition : "top"                    
                        },
                        title: {
                            display: true,
                            text: "",
                            font: {
                                size: 15
                            }
                        },  
                        tooltip: {
                            bodyFont: {
                                size: 9
                            },
                            titleFont: {
                                size: 11    
                            },
                            enabled: !dataConfig.hideTooltip,
                            callbacks: {
                                label: function (ctx) {
                                    let labels_serie = [];
                                    let label = ctx.dataset.label || '';
                                    let valuePre =  ctx.dataset.data[ctx.dataIndex];
                                    let value = valuePre !== undefined ? valuePre : 0;
                                    value = dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft  === "$" ?  "$" +  Utils.formatNumber(format_number,value) : Utils.formatNumber(format_percent,value)  + "%"  :  Utils.formatNumber(format_number,value)
                                    labels_serie.push(`${label}: ${value}`)
                                    for (let i = 0; i < ctx.dataset.nameLabels[ctx.dataIndex].length; i++) {
                                        let name = ctx.dataset.nameLabels[ctx.dataIndex][i];
                                        let value =  ctx.dataset.dataM[ctx.dataIndex][i];
                                        labels_serie.push(`${name}: ${value}`)
                                    }
                                    return labels_serie
                                }
                            }
                        } 
                    },
                }}
            />
        </div>
    )
}

export default StackedBarChart