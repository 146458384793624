
import { Link } from "react-router-dom";
import { StyledMenu,  DivMenu } from "./styles";
import { useLocation } from "react-router-dom";
import React, { useContext } from 'react';
import { Button } from "../../commonStyles";
import { UserContext } from "../../Context/User";


function View({ page, module }) {
    const { colorPalette } = useContext(UserContext);
    let location = useLocation();


    return (
        <DivMenu colors={colorPalette} className={'menu-type-' + module.config.menu + ' tmenu-' + module.config.tMenu}>
            <StyledMenu>
                {Object.keys(module.config).length>0 && module.config.data.map((e,i) => {
                    if (e.type === 'externo') {
                        return <li key={i}>
                            {module.config.tMenu !== 'btn'
                                ? <a href={e.url} target={e.newTab ? '_blank':'_self'} rel="noreferrer">{e.title} <img src="/assets/Icon-compartir.svg" alt="export" /></a>
                                : <a href={e.url} target={e.newTab ? '_blank':'_self'} rel="noreferrer" > <Button color={colorPalette.primary} >{e.title}</Button> </a>
                            }
                        </li>
                    }
                    return (
                    <React.Fragment key={i}>
                        {module.config.tMenu !== 'btn'? 
                            <li className={location.pathname.includes(e.url) ? 'active' : null}>
                                <Link to={e.url} as={Button} target={e.newTab ? '_blank':'_self'} >{e.title}</Link>
                            </li>
                        :   <li className={location.pathname === e.url ? 'active' : null}>
                                <Link to={e.url} target={e.newTab ? '_blank': '_self'}> <Button  color={colorPalette.dark}> {e.title}</Button> </Link>
                            </li> 
                    }
                    </React.Fragment>
                    )
                })}
            </StyledMenu>
        </DivMenu>
    );
}
export default View;