import styled from "styled-components";
import { Theme1 } from "../../../themes/Theme1";

export const ContainerBanner = styled.div`
    box-shadow: ${Theme1.shadow};
    border-radius: 1rem;
    z-index: 1;
    min-height: 200px;
    margin-bottom: 30px;
    & .row {
        margin: 0;
        padding: 0 !important;
    }

    & .col-sm-6 {
        padding: 0;
    }
    & .img-card {
        z-index:-3;
        background: linear-gradient(to right,rgba(0,0,0,0) 0%,rgba(0,0,0,0) 50%,rgb(255 255 255) 100%),url(${props => props.imgData}) no-repeat;
        background-size: cover;
        border-radius: 1rem;
        //background: url("/assets/black.jpg") no-repeat;
    }
    

    & .body-card {
       
        background-color: white;
        padding: 3rem 2rem 3rem 0;
        border-radius: 1rem;
        & .title {
            font-size: 25px;
            font-weight: 500;
        }
        & .description {
            font-size: 14px;
        }
    }
    
    
`;

export const BtnLink = styled.div`
    cursor: pointer;
    background-color: ${props => props.color};
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
    & a {
        color: white;
        font-size: 14px;
        font-weight: bold;
    }
    &:hover {
        opacity: 0.9;
    }
    & img {
        filter:  brightness(0) invert(1);
        padding-left: 1rem;
    }
`;

export const BtnLinkSingle = styled.div`
    font-weight: bold;
    display:flex;
    align-items: center;
    justify-content: flex-end;
    & a {
        color: black;
        font-size: 12px;
    }
    & img {
        width: 14px;
        transform: rotate(180deg);
    }
`;
export const ContainerHorizontal = styled.div`
    box-shadow: ${Theme1.shadow};
    border-radius: 1rem;
    z-index: 2;
    min-height: 170px;
    & .row {
        margin: 0;
        min-height: 170px;
        padding:0px !important;
    }

    & .col-sm-6 {
        padding: 0;
    }
    & .img-card {
        border-radius: 0.6rem;
        background: url(${props => props.imgData}) no-repeat;
        background-size: cover;
        
    }

    & .body-card {
        background-color: white;
        padding: 1rem;
        padding-top: 2rem;
        border-radius: 1rem;
        & .title {
            font-size: 12px;
            font-weight: 600;
        }
        & .description {
            font-size: 10px;
        }
    }
`;

export const ContainerVertical = styled.div`
    box-shadow: ${Theme1.shadow};
    border-radius: 1rem;
    z-index: 2;
    & .img-card {
        height: 170px;
        border-radius: 0.6rem 0.6rem 0 0;
        background: url(${props => props.imgData}) no-repeat;
        background-size: cover;        
    }
    & .body-card {
        background-color: white;
        padding: 1rem;
        border-radius: 1rem;
        & .title {
            font-size: 12px;
            font-weight: 600;
        }
        & .description {
            font-size: 10px;
        }
    }
`;

export const ContainerSimple = styled.div`
    box-shadow: ${Theme1.shadow};
    border-radius: 1rem;
    z-index: 2;

    & .img-card {
        min-height: 170px;
        border-radius: 0.6rem 0.6rem 0 0;
        //background: url("/assets/black.jpg") no-repeat;
        background: linear-gradient(to bottom,rgba(255,255,255, 50%) 0%,rgba(255,255,255, 75%) 50%,rgb(255 255 255) 100%),url(${props => props.imgData}) no-repeat;
        background-size: cover;
        
        & .title {
            font-size: 16px;
            font-weight: 500;
            color: black;
            padding: 5rem 1rem 0;
        }
        & .description {
            font-size: 12px;
            color: black;
            padding-left: 1rem;
        }
    }
    & .body-card {
        background-color: white;
        padding: 1rem;
        border-radius: 1rem;
        & .title {
            font-size: 18px;
            font-weight: 500;
        }
        & .description {
            font-size: 12px;
        }
    }
`;