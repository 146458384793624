import 'bootstrap/dist/css/bootstrap.min.css';
import { UserInfo } from './Context/User';
import React, { useRef } from "react";
import './App.css'
import { useEffect, useState } from 'react';
import Utils from './utils';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './account/Login';
import { VERSION_SESSION } from './config';
import RedirectAdminComponent from './base/RedirectAdminComponent';
import MasterPage from './base/MasterPage';
import { GlobalStyle } from './globalStyles';

import PreLogin from './account/PreLogin';
import AutologinSSO from './account/AutologinSSO';
import Logout from './account/Logout';
import { Loading } from './Components/Loading';
import { Theme1 } from './themes/Theme1';
import { registerVisit } from './utils/RegisterVisit';


export var counter = {number: 0, startTime: [], endTime: [], enabled: true};
function App() {
  const [editMode, setEditMode]=useState(null);
  const [moduleList, setModuleList]=useState([]);
  const [loadedEditMode, setLoadedEditMode] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [logged, setLogged] = useState(false);
  const [reloadContent, setReloadContent] = useState(false);
  const [loginDefaultMessage, setLoginDefaultMessage] = useState('');

  const filterTag = useRef();
  const configGeneral = useRef(null);
  let globalProps = {
    moduleList,
    editMode,
    loaded,
    reloadContent,
    setReloadContent,
    setLogged,
    filterTag,
  }
  useEffect(()=>{
    
    
    if(VERSION_SESSION===localStorage.getItem('version') && localStorage.getItem('token_user')){
      Utils.Petition.get('/users/validate-token',(response)=>{
        if(localStorage.getItem('editable')==='1') setEditMode(true);
        let config = response.data.config;
        configGeneral.current = {colors:{...Theme1, primary: config.color_primary, secondary: config.color_secondary} };
        Utils.Security.setStartLocalStorage(response, 'AppValidate', ()=>{
          setLogged(true);
      })

      },
      (_)=>{
        setLoaded(true);
        setEditMode(false);
        localStorage.setItem('editable','0');
        setLoginDefaultMessage('Su sesión ha vencido');
      });
    }else{
      setLoaded(true);
      (localStorage.getItem('version') && VERSION_SESSION!==localStorage.getItem('version')) && setLoginDefaultMessage('Se han realizado actualizaciones en la plataforma, para tener una experiencia completa, debes volver a iniciar sesión');
      setEditMode(false);
      localStorage.clear();
    }
    localStorage.setItem("idUserPlatform", Date.parse(new Date()));
    setInterval(() => {
      let date = new Date();
      let formatTime = date.toLocaleTimeString();
      if(formatTime.split(":").at(1) === "00") {
        // cada hora minuto 0
        registerVisit("", "", false, true)
      }
    }, 60000)
  }, []);

  useEffect(()=>{
    if(logged){
      setLoaded(false); 
      localStorage.getItem('redirect_url');
      if(editMode){
        Utils.Petition.get(
          '/modules',
          (response)=>{
              setModuleList(response.data);
              setLoadedEditMode(true);
              localStorage.setItem('editable','1');
              setLoaded(true);
          }
        );
      }else{
        setLoadedEditMode(false);
        localStorage.setItem('editable','0');
        setLoaded(true);
      }
    }
  }, [editMode, logged]);
  
  return (
    <React.Fragment>
     
      <GlobalStyle colors={configGeneral.current?.colors || {}}/>

        <BrowserRouter>
          <div className="App">
            {
              !loaded && <Loading />
            }
            { 
              loaded && 
              <>
                <Route path="/login" exact component={()=><Login loginDefaultMessage={loginDefaultMessage} setLoginDefaultMessage={setLoginDefaultMessage} logged={logged} setLogged={setLogged} />} />
                <Route path="/login/token/:token" exact component={()=><AutologinSSO setLoginDefaultMessage={setLoginDefaultMessage} logged={logged} setLogged={setLogged} />} />
                <Route path="/login/logout" exact component={()=><Logout setLoginDefaultMessage={setLoginDefaultMessage} logged={logged} setLogged={setLogged} />} />
              </>
            }
            {
              loaded && !logged &&
              <Switch>
                <Route path="/login" />
                <Route component={PreLogin} />
              </Switch>
            }
            {
              loaded && ( !editMode || loadedEditMode ) && logged &&
              <Switch>
                <Route path="/login" />
                <Route path="/redirect-admin/url/:url" exact component={() => <RedirectAdminComponent />} />
                <Route component={ ()=> 
                <UserInfo>
                  <MasterPage globalProps={globalProps} editMode={editMode} setEditMode={setEditMode}/>
                </UserInfo>
                 }/>
              </Switch>
            }
          </div>
        </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
