import styled from "styled-components";
import { Theme1 } from "./themes/Theme1";

export const Button = styled.button`
        color: white;
        background-color: ${props => props.color};
        border: solid 1px ${props => props.color};
        font-size: 14px;
        font-weight: 500;
        border-radius: 0.3rem;
        box-shadow: ${props => props.boxShadow && "1px 0px 20px 10px #00000020"};
        padding: 0.4rem 0.8rem;
        width: fit-content;
        display: block;
        margin: ${props => props.margin};
        &:hover{
            color: ${props => props.color} ;
            background-color: transparent;
        }

        &:disabled{
            cursor: not-allowed;
            opacity: 0.7;
        }
        &:focus {
            box-shadow: 0 0 12px 0 ${props => props.color}40;
        }
        & .btn-inactive {
            display: none;
        }
        
`;

export const StyleCheckbox = styled.div`
margin-bottom: 6px;
/*** CHECK AND RADIO INPUT CUSTOM **/
& .custom-control-input:checked ~ .custom-control-label::before {
  color: white !important;
  border: ${props => props.colors?.primary} !important;
  background-color: ${props => props.colors?.primary} ;
}

& .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem ${props => props.colors?.primary}30;
}
& .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 0, 0, 0.25)
}
& .custom-control-input:active ~ .custom-control-label::before {
  background-color: ${props => props.colors?.primary}; 
}
& .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: ${Theme1.grayLight}; 
}

`; 


