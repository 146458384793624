import { useContext } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { UserContext } from "../../Context/User";
import { TabVerticalWrapper } from "./styles";

function VerticalTabs({allModules, modules, code, page, status}){
    const { colorPalette } = useContext(UserContext);
    return (
        <TabVerticalWrapper color={colorPalette.secondary}>
            <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        {
                            modules.map((module, index) => {
                                return (
                                    <Nav.Item>
                                        <Nav.Link eventKey={index}><span>{module.name}</span> <div className="space"/></Nav.Link>
                                    </Nav.Item>
                                )

                            })
                        }
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        {
                            modules.map((module, index) => {
                                const ComponentTagName = allModules[module.module_code][status];
                                return (
                                    <Tab.Pane eventKey={index}>
                                        <div key={module.id} id={`page_${page.id}_section_${code}_module_${module.id}`} className={`section-page-${page.id}-section-${code}-module-${module.id} module-component module-${module.module_code} module-status-${status}`}>
                                            <ComponentTagName page={page} module={module}  />
                                        </div>
                                    </Tab.Pane>
                                )
                            })
                        }
                    </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </TabVerticalWrapper>
        
    )
}
export default VerticalTabs;