import Swal from 'sweetalert2';
import {Theme1} from './../themes/Theme1'

export const ICON = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
    INFO: 'info',
    QUESTION: 'question'
}

export const AlertForm = (icon = ICON.INFO, title = "", text = "", position = "center", timer = 5000, confirmButtonText) => {
    const Toast = Swal.mixin({
        toast: true,
        position,
        showConfirmButton: confirmButtonText,
        timer,
        timerProgressBar: true,
        confirmButtonColor: Theme1.orange, 
        confirmButtonText: confirmButtonText
    })

    Toast.fire({
        icon,
        title,
        text
    })
}

export const AlertFormLarge = (icon = ICON.INFO, title = "", text = "", footer = null) => {
    Swal.fire({
        icon,
        title,
        text,
        footer
    })
}

export const AlertConfirm = (icon = ICON.WARNING, title = "", text = "", callback = () => { }, textButton = 'Si', fallback = () => { }) => {
    Swal.fire({
        title,
        text,
        icon,
        showCancelButton: true,
        confirmButtonColor: Theme1.orange,
        cancelButtonColor: Theme1.red,
        confirmButtonText: textButton
    }).then((result) => {
        if (result.isConfirmed) {
            callback();
        } else if (result.dismiss === "cancel") {
            fallback();
        }
    })
}

export const AlertHtml = (icon = ICON.INFO, title = "", html = "", footer = null) => {
    Swal.fire({
        title,
        html,
        icon,
        footer
    });
}