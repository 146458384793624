import React, { useContext, useEffect, useState } from "react";
import Utils from "../../../utils";
import Select from "react-select";
import { styleTheme } from "../../../themes/themeSelect";
import { UserContext } from "../../../Context/User";

function AddPopup({ idpage, addPopup }) {
  const { colorPalette } = useContext(UserContext);
  const [popupOption, setPopupOption] = useState([]);
  const [popupDescription, setPopupDescription] = useState("");

  const popupOptionHandler = (event) => {
    setPopupOption(event.value);
  };

  const popupDescriptionHandler = (event) => {
    setPopupDescription(event.target.value);
  };

  useEffect(() => {
    Utils.Petition.get(
      `/popups/idpage/${idpage}`,
      (res) => {
        let data = res.data;
        let _options = [];
        for (let pos in data) {
          let label = `${data[pos].id} - ${data[pos].name}`;
          let value = data[pos].id;

          _options.push({ value: value, label: label });
        }
        setPopupOption(_options);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);
  return (
    localStorage.setItem("popupOption", popupOption) ||
    localStorage.setItem("popupDescription", popupDescription) || (
      <div className="card">
        <div className="card-header text-center">
          <b>Generar PopUp</b>
        </div>
        <div className="card-body">
          <div className="mt-3">
            <label htmlFor={"selectPopUp"}>Descripcion PopUp</label>
            <input
              className="form-control"
              name="description"
              onChange={popupDescriptionHandler}
            />
          </div>
          <div className="mt-3">
            <label htmlFor={"selectPopUp"}>Seleccionar PopUp</label>
            <Select
              options={popupOption}
              onChange={popupOptionHandler}
              theme={theme => (styleTheme(theme, colorPalette?.primary))}
            />
          </div>
        </div>
      </div>
    )
  );
}

export default AddPopup;
