import { Header, Logo, SectionLogo, SectionAccount } from "./styles"
import {Search} from "./search"
import { FaBars, FaUserAlt, FaAngleRight } from "react-icons/fa";
import { Dropdown } from 'react-bootstrap';
import AdminSection from './../AdminSection';
import Utils from './../../utils';
import { Link } from "react-router-dom";
import Filtros from "../Filtros";
import { CENTRIFY_LOGOUT } from "../../config";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "../../Context/User";

export const Head = ({ editMode, globalProps, setEditMode, onPress }) => {
    const { colorPalette } = useContext(UserContext);
    const [updateLocalStorage, setUpdateLocalStorage] = useState(false);
    const [logo, setLogo] = useState("");
    const handleCloseSession = () => {
        const w = window.open(CENTRIFY_LOGOUT,"Cerrando sesión", "height=50,width=50");
        setTimeout(()=>w.close(), 3000);
        localStorage.removeItem('token_user');
        localStorage.removeItem('editable');
        setEditMode(false);
        globalProps.setLogged(false);
    }
    useEffect(() => {
        setLogo(JSON.parse(localStorage.getItem("config")).logo);
    }, [])

    return (
        <Header>
            <SectionLogo colors={colorPalette} >
                <Logo color={colorPalette.primary}>
                    <Link to="/">
                    {logo && <img src={`data:image/png;base64,${logo}` } alt="logo" />}
                    {!logo && <p className="mi-logo">Mi Logo</p>}
                    </Link>
                </Logo>
                <button type="button" onClick={onPress}> <FaBars /></button>
            </SectionLogo>
            <SectionAccount>
                <Search setUpdateLocalStorage={setUpdateLocalStorage}/>
            </SectionAccount>
            <SectionAccount colors={colorPalette}>
                <Filtros globalProps={globalProps} setEditMode={setEditMode} updateLocalStorage={updateLocalStorage} setUpdateLocalStorage={setUpdateLocalStorage}/>
                {Utils.Security.checkRoleGroup('adminSite') && <AdminSection globalProps={globalProps} editMode={editMode} setEditMode={setEditMode} />}
                <Dropdown>
                    <Dropdown.Toggle id="dropdown-account">
                        <FaUserAlt style={{fontSize: "1.3rem"}} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {/* <Dropdown.Item><FaAngleRight /> Gestionar cuenta</Dropdown.Item>
                        <Dropdown.Item><FaAngleRight /> Gestionar perfil</Dropdown.Item> */}
                        <Dropdown.Item onClick={handleCloseSession}><FaAngleRight /> Cerrar Sesion</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </SectionAccount>
        </Header>
    )
}