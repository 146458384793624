import { useContext, useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { Button } from "../../../../commonStyles";
import { UserContext } from "../../../../Context/User";
import Utils from "../../../../utils";
import Item from "./Item";

function AdminPopups({templateZonesLoading, setPopupStructure, page}){
    const { colorPalette } = useContext(UserContext);
    const [popups, setPopups] = useState([]);
    const [message, setMessage] = useState('');
    const [form, setForm] = useState({
        name: '',
        idpage: page.id
    })
    useEffect(()=>{
        Utils.Petition.get('/popups/idpage/'+page.id, (response)=>{
            setPopups(response.data);
        })
    }, []);
    const handleForm = (event)=>{
        setForm({...form, [event.target.name]: event.target.value});
    }
    const handleCreatePopup=(event)=>{
        event.preventDefault();
        setMessage("");
        let valid = true;
        if(form.name===''){
            valid = false;
            setMessage("Ingresa un nombre por favor")
        }
        if(valid){
            Utils.Petition.post('/popups', form, (response)=>{
                let _popups = [...popups];
                _popups.push(response.data);
                setPopups(_popups);
                
            });
            setForm({...form, name:''})
        }
    }
    return (
        <>
            <h3>Popups</h3>
            <Form>
                <Form.Group>
                    <Form.Label>Título</Form.Label>
                    <Form.Control type="text" name="name" value={form.name} onChange={handleForm} placeholder="Título del popup" />
                    <Form.Text className="text-muted">
                        El popup tendrá este título y además se usará para identificarlo cuando agregue el evento que lo mostrará
                    </Form.Text>
                </Form.Group>
                <Form.Group>
                {
                    message && <Alert variant="danger"> {message}</Alert>
                }
                <div>
                <Button color={colorPalette.primary} onClick={handleCreatePopup}>Crear popup <FaSave/></Button>
                </div>
                   
                </Form.Group>
            </Form>
            <hr />
            <div>
                {
                    popups.map((popup, index)=>{
                        return (
                            <Item key={index} templateZonesLoading={templateZonesLoading} setPopupStructure={setPopupStructure} popups={popups} setPopups={setPopups} popup={popup} index={index} />
                        )
                    })
                }
            </div>
        </>

    )
    
}
export default AdminPopups;