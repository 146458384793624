import { useContext, useEffect, useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import { FaSave } from "react-icons/fa";
import { Button, StyleCheckbox } from "../../../../commonStyles";
import { AlertForm, ICON } from "../../../../Components/SweetAlert";
import { UserContext } from "../../../../Context/User";
import Utils from "../../../../utils";

function AdminComments({ page }) {
    const { colorPalette } = useContext(UserContext);
    const [form, setForm] = useState({
        includeComment: '0',
        comment: '',
    })
    useEffect(() => {
        setForm({
            includeComment: page.comment,
        });
    }, [page]);
    const handleFormCheckbox = (event) => {
        setForm({ ...form, [event.target.name]: (event.target.checked) ? '1' : '0' });
    }

    const handleSaveComment = () => {
        Utils.Petition.post('/pages/update', {
            id: page.id,
            comment: form.includeComment
        },
            (response) => {
                AlertForm(ICON.SUCCESS, response.message, "");
            });

    }
    return (
        <>
            <h3>Comentarios</h3>
            <FormGroup>
                <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                    <input className="custom-control-input" id="includeComment" type="checkbox" checked={form.includeComment === '1' ? true : false}  name={`includeComment`} onChange={handleFormCheckbox} />
                    <label className="custom-control-label" htmlFor="includeComment">
                        ¿Desea incluir comentarios en ésta página?
                    </label>
                </StyleCheckbox>
                
            </FormGroup>
            <FormGroup>
                <div>
                    <Button color={colorPalette.primary} onClick={handleSaveComment}>Guardar cambios <FaSave /></Button>
                </div>
            </FormGroup>
        </>
    )
}
export default AdminComments;