import React, { useState, useEffect } from "react";
// import { FaBars, FaUserAlt, FaAngleRight } from "react-icons/fa";
import { Button, Container, Col, Row, InputGroup, Form, FormControl, Table } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { Loading } from "../../Components/Loading";

import Utils from '../../utils';
import { ContainerRelative, ContainerSearch } from "./styles";

export const Search = ({setUpdateLocalStorage}) => {
    const [indicators, setIndicators] = useState([]);
    const [pages, setPages] = useState([]);
    const [content, setContent] = useState([]);
    const [wordToSearch, setWordToSearch] = useState('');
    const [dateDefault, setDateDefault] = useState({year:new Date().getFullYear(), month:new Date().getMonth(), idfilter:1})
    const [isLoadingIndicator, setIsLoadingIndicator] = useState(false);
    const [isLoadingPages, setIsLoadingPages] = useState(false);
    const [isLoadingContent, setIsLoadingContent] = useState(false);

    const format_number = Utils.getConfigPortal('format_number');

    const handleSearch = (event) => {
        let wordToSearch = event.target.value
        setWordToSearch(wordToSearch);
        setIndicators([]);
        setPages([]);
        setContent([]);
        setIsLoadingIndicator(true);
        wordToSearch.length > 2 && Utils.Petition.get(`/indicators/indicatorSearch/${dateDefault.year}/${dateDefault.month}/${wordToSearch}`,
          (res) => {
            setIndicators(res.data);
          },
          (err) => {
            console.log("indicator",err);
          }, () => {
            setIsLoadingIndicator(false);
          }
        );
        setIsLoadingPages(true);
        wordToSearch.length > 2 && Utils.Petition.get(`/pages/pagesForSearch/${wordToSearch}`,
          (res) => {
            setPages(res.data);
          },
          (err) => {
            console.log("pages",err);
          }, () => {
            setIsLoadingPages(false);
          }
        );
        setIsLoadingContent(true);
        wordToSearch.length > 2 && Utils.Petition.get(`/pages-modules/page/search/${wordToSearch}`,
          (res) => {
            setContent(res.data);
          },
          (err) => {
            console.log("pages-modules",err);
          }, () => {
            setIsLoadingContent(false);
          }
        );
    }

    const handleLocalStorage = ({month, year, idfilter}) => {
        setWordToSearch("");
        month !== null ? localStorage.setItem('month', month) : localStorage.setItem('month', dateDefault.month)
        year !== null ? localStorage.setItem('year', year) : localStorage.setItem('year',dateDefault.year);
        idfilter !== null && localStorage.setItem('idfilter', idfilter);
        setUpdateLocalStorage(true);
    }

    const Mes = ({month}) => {
        let mes = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'] 
        return month !== null && mes[month]
    }

    return(
        <ContainerRelative>
            <Row>
                <Col>
                    <InputGroup >
                        <FormControl type="text" placeholder="Busqueda" value={wordToSearch} onChange={(event)=>handleSearch(event)} />
                    </InputGroup>
                </Col>
            </Row>
            {wordToSearch.length > 2 && 
            <ContainerSearch>
                <Table bordered className="table-striped">
                    <thead className="bg-dark text-white text-center">
                        <tr>
                            <th scope="col">Indicador</th>
                            <th scope="col">Real</th>
                            <th scope="col">Ppto</th>
                            <th scope="col">RF</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                    {indicators.length > 0 && indicators.map((el, index)=>{
                        return(
                            <tr key={`search-${index}`} className="text-center">
                                <td className="text-left"><b>{el.name}</b></td>
                                <td className="text-left">{el.col1 !== null ? Utils.formatNumber(format_number,el.col1) : 0}{el.unit === '%' && '%'}</td>
                                <td className="text-left">{el.col2 !== null ? Utils.formatNumber(format_number,el.col2) : 0}{el.unit === '%' && '%'}</td>
                                <td className="text-left">{el.col3 !== null ? Utils.formatNumber(format_number,el.col3) : 0}{el.unit === '%' && '%'}</td>
                            </tr>
                        )}
                    )}
                    </tbody>
                </Table>
                {isLoadingIndicator && <Loading fontSize="1rem"/>}
                {!indicators.length  && !isLoadingIndicator && "No hay resultados de indicadores"}
                <Table bordered className="table-striped">
                    <thead className="bg-dark text-white text-center">
                        <tr>
                            <th scope="col">Página</th>
                        </tr>
                    </thead>
                    <tbody>
                    {pages.length > 0 && pages.map((el, index)=>{
                        return(
                            <tr key={`search-${index}`} className="text-center">
                                <td className="text-left"><b onClick={() => setWordToSearch("")}><Link to={el.path}>{el.name}</Link></b></td>
                            </tr>
                        )}
                    )}
                    </tbody>
                </Table>
                {isLoadingPages && <Loading fontSize="1rem"/>}
                {!pages.length  && !isLoadingPages && "No hay resultados de Paginas"}
                <Table bordered className="table-striped" size="sm">
                    <thead className="bg-dark text-white text-center">
                        <tr>
                            <th scope="col">Contenido</th>
                            <th scope="col" style={{width: "270px"}}>Ubicado en</th>
                        </tr>
                    </thead>
                    <tbody>
                    {content.length > 0 && content.map((el, index)=>{
                        return(
                            <tr key={`search-${index}`} className="text-center">
                                <td className="text-left">
                                    <b onClick={()=> {handleLocalStorage({month:el.month, year:el.year, idfilter:el.idfilter})}}>
                                        <Link to={el.path}>{el.name}</Link>
                                    </b>
                                </td>
                                <td className="text-left">
                                    <div> <b>Página:</b> {el.page_name}</div>
                                    <div> {el.month === null && el.year === null ? <><b>Publicado:</b> Actualmente</> : <> <b>Publicado:</b> <Mes month={el.month}/> {el.year} </> }</div>
                                    <div> <b>Región:</b> {el.region === null ? 'Todas' : el.region}</div>
                                </td>
                            </tr>
                        )}
                    )}
                    </tbody>
                </Table>
                {isLoadingContent && <Loading fontSize="1rem"/>}
                {!content.length  && !isLoadingContent && "No hay resultados de Contenido"}
            </ContainerSearch>
            }
        </ContainerRelative>
    )
}