import styled from "styled-components";
import { Theme1 } from "../../../themes/Theme1";

export const ContainerCustom = styled.div`
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1.5rem;
    margin-bottom: 0.6rem;
    box-shadow: ${Theme1.shadowContainer};
    background-color: #ffffff;
    &.active {
        display: block;

    }
    h3{
        font-size: 18px;
    }
`;