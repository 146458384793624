import { useContext, useState } from "react";
import {FaRegCommentDots} from 'react-icons/fa';
import CustomModal from "../../base/CustomModal";
import Sign from "../../Components/Sign";
import SignYellowIndicator from "../../Components/SignYellowIndicator";
import { UserContext } from "../../Context/User";
import Utils from "../../utils";
import { IndicatorLabel } from "./styles";

function Indicator({indicator, indicatorData, setShowIndicatorsDetailModal, setSelectedIndicatorDetail, config}){
    const { colorPalette } = useContext(UserContext);

    const [comment, setComment] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const format_number = Utils.getConfigPortal('format_number');
    const format_percent = Utils.getConfigPortal('format_percent');
    const handleCommentModal = ()=>{
        Utils.Petition.get('/comments/type-model/indicator/type-id/'+indicator+'/year/'+localStorage.getItem('year')+'/month/'+localStorage.getItem('month')+'/view/'+localStorage.getItem('view'), (response)=>{
            if(response){
                setComment(response.data.comment);
            }else{
                setComment('');
            }
            setShowModal(true);
        })
    }
    const handleDetailIndicator = (event)=>{
        event.preventDefault();
        setSelectedIndicatorDetail(indicator);
        setShowIndicatorsDetailModal(true);
    }

    return (
        <tr>
            <td>
                <IndicatorLabel colors={colorPalette}>
                    <span onClick={handleDetailIndicator}>{indicatorData[indicator].name}</span> <FaRegCommentDots onClick={handleCommentModal} />
                </IndicatorLabel>
                {showModal &&
                    <CustomModal title={'Comentario del indicador: '+indicatorData[indicator].name} show={showModal} setShow={setShowModal} >
                        {comment!==''?comment:<i>Ningun comentario ingresado en el mes</i>}
                    </CustomModal>
                }
            </td>
            <td>
                {
                    config.stages.includeYellow  && config.stages.unitPercent &&
                    <>
                        {
                            indicatorData[indicator].unit === '%' && <Sign isDescendingIndicator={config.stages.descendingIndicator} value={indicatorData[indicator].sign} type={indicatorData[indicator].type} base={0} />
                        }
                        {
                            indicatorData[indicator].unit !== '%' && <SignYellowIndicator isDescendingIndicator={config.stages.descendingIndicator} value={indicatorData[indicator].sign ? Number(indicatorData[indicator].sign): null} type={indicatorData[indicator].type} base={100} percent={config.stages.percentYellow} />
                        }
                           
                        
                    </>
                }
                {
                    !config.stages.unitPercent && config.stages.includeYellow &&
                    <SignYellowIndicator isDescendingIndicator={config.stages.descendingIndicator} value={indicatorData[indicator].sign ? Number(indicatorData[indicator].sign): null} type={indicatorData[indicator].type} base={100} percent={config.stages.percentYellow} />
                }
                {
                    !config.stages.unitPercent && !config.stages.includeYellow &&
                    <Sign isDescendingIndicator={config.stages.descendingIndicator} value={indicatorData[indicator].sign} type={indicatorData[indicator].type} base={100} />
                }
                {
                    !config.stages.includeYellow && config.stages.unitPercent && 
                    <Sign isDescendingIndicator={config.stages.descendingIndicator} value={indicatorData[indicator].sign} type={indicatorData[indicator].type} base={indicatorData[indicator].unit === '%' ? 0: 100} />
                }
                {
                    config.stages.col1.percent && indicatorData[indicator].col1 ? Utils.formatNumber(format_percent,indicatorData[indicator].col1) + "%" : ""
                }
                {
                    !config.stages.col1.percent && Utils.formatNumber(format_number,indicatorData[indicator].col1)
                }
            </td>
                
            {
                indicatorData[indicator].col2 !== "0" &&
                <td>
                    {
                        config.stages.col2.percent && indicatorData[indicator].col2 ? Utils.formatNumber(format_percent,indicatorData[indicator].col2) + "%" : ""
                    }
                    {
                        !config.stages.col2.percent && Utils.formatNumber(format_number,indicatorData[indicator].col2)
                    }
                </td>
            }
            {
                indicatorData[indicator].col3 !== "0" &&
                <td>
                    {
                        config.stages.col3.percent && indicatorData[indicator].col3 ? Utils.formatNumber(format_percent,indicatorData[indicator].col3) + "%" : ""
                    }
                    {
                        !config.stages.col3.percent && Utils.formatNumber(format_number,indicatorData[indicator].col3)
                    }
                </td>
            }
            {
                indicatorData[indicator].col4 !== "0" &&
                <td>
                    {
                        config.stages.col4.percent && indicatorData[indicator].col4 ? Utils.formatNumber(format_percent,indicatorData[indicator].col4) + "%" : ""
                    }
                    {
                        !config.stages.col4.percent && Utils.formatNumber(format_number,indicatorData[indicator].col4)
                    }
                </td>
            }
        </tr>
    );
}
export default Indicator;