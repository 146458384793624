import React, { useState,  useContext, everypages, filter } from "react";
import Utils from "../../utils";
import CustomModal from "../../base/CustomModal";
import { ButtonConfig } from "../Components/ButtonConfig";
import { UserContext } from "../../Context/User";

function Edit({ globalParams, page, module }) {
  const { validateShowComponent } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (event) => {
    event.preventDefault();
    setShowModal(true);
  };

  return (
    <>

    {(module.idpopup !== null || validateShowComponent(everypages, filter)) && <ButtonConfig onPress={handleShowModal} />}
      <CustomModal title={"Plantilla web"} show={showModal} setShow={setShowModal}>
        <iframe title={"Administrar Plantilla"} style={{ border: 0 }} width="100%" height="500" src={Utils.getAdminUrl(`/plantilla-web/module/${module.id}`)}></iframe>
      </CustomModal>
    </>
  );
}

export default Edit;

