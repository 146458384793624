import React, { useState, useEffect, useContext } from "react";
import { Table } from 'react-bootstrap';
import { UserContext } from "../Context/User";
import Utils from "../utils";
import Sign from "./Sign";
import { TableWrapper } from "./styles";

function CreateTableCostumer({dataConfig, data}) {
    
    const { colorPalette } = useContext(UserContext);
    const year = parseInt(useState(localStorage.getItem('year')));
    const [tableData, setTableData] = useState([]);
    const [headersTable, setHeadersTable] = useState([]);
    
    const format_number = Utils.getConfigPortal('format_number');
    const format_percent = Utils.getConfigPortal('format_percent');

    useEffect(() => {
        const real = dataConfig.stages.filter( stage => stage.code === "R")[0];
        const variacion = dataConfig.stages.filter( stage => stage.code === "VR")[0];
        const data_now = (data.filter( d => d.year === year && d.stage === real.idstage)).sort( (a, b) => parseFloat(b.value) - parseFloat(a.value));
        const data_pre = (data.filter( d => d.year === (year-1) && d.stage === real.idstage)).sort((a, b) => parseFloat(b.value) - parseFloat(a.value));
        let htmlTr = [];
        let htmlHeaders = [];   

        htmlHeaders.push(
            <tr key="0">
                <th key="1" colSpan={dataConfig.hideLastYear ? "1" : "2"}>Ranking</th>
                <th key="2" ></th>
                <th key="3" ></th>
                <th key="4" colSpan="2">Ventas</th>
                <th key="5" colSpan="2">Variación</th>
            </tr> 
        )
        htmlHeaders.push(
            <tr key="1">
                <th key="1" className="text-center">Actual</th>
                {dataConfig.hideLastYear ? "" :  <th key="2">A.Ant</th>}
                <th key="3" className="text-left">Cliente</th>
                <th key="4" className="text-left">Canal</th>
                <th key="5">Actual</th>
                <th key="6">A.Ant</th>
                <th key="7">%</th>
                <th key="8">$</th>  
            </tr> 
        )
        data_now.map ( (dataN, index) => {
            if( index <= 19) {
                let pos_pre = 0;
                let indicator = dataConfig.indicators.filter( ind => ind.id === dataN.indicator)[0];
                const name_customer = indicator.hasOwnProperty('labelIndicator') && indicator.labelIndicator !== undefined && indicator.labelIndicator.trim() !== "" ? indicator.labelIndicator : indicator.name;
                const name_Canal = indicator.hasOwnProperty('labelCanal') && indicator.labelCanal !== undefined && indicator.labelCanal.trim() !== "" ? indicator.labelCanal : 'N/A';
                let data_var = data.filter( d => d.indicator === dataN.indicator && d.stage === variacion.idstage)[0];

                const ind_data_pre = data_pre.filter( (d, i) => {
                    if (d.indicator === dataN.indicator) {
                        pos_pre = i + 1;
                        return d;
                    }
                })[0];
                let dif = dataN.value - ind_data_pre.value;
                htmlTr.push(
                    <tr key={index + 2}>
                        <td key={1}>{index + 1 }</td>
                        {dataConfig.hideLastYear ? "" : <td key={2}>{pos_pre > 20 ? '-' : pos_pre}</td>}
                        <td key={3} className="text-left">{name_customer}</td>
                        <td key={4} className="text-left">{name_Canal}</td>
                        <td key={5}>{Utils.formatNumber(format_number, dataN.value)}</td>
                        <td key={6}>{Utils.formatNumber(format_number, ind_data_pre.value)}</td>
                        <td key={7}><Sign type={'C'} value={data_var.value} base={0}/> {Utils.formatNumber(format_number, data_var.value)}</td>
                        <td key={8}>{Utils.formatNumber(format_number, dif)}</td>
                    </tr>
                )
            }
        });

        setTableData(htmlTr);
        setHeadersTable(htmlHeaders)

    }, [])

    return (
        <div className="text-center">
            <TableWrapper colors={colorPalette}>
                <Table>
                    <thead>
                        {headersTable}
                    </thead>
                    <tbody>
                        {tableData}
                    </tbody>
                </Table>
            </TableWrapper>
            
        </div>

    )
}   

export default CreateTableCostumer