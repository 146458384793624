import React, { useState, useEffect } from "react";
import Sign from "../../Components/Sign";
import Utils from "../../utils";
let url = "";
function SignValue({ idIndicator, idStage }) {
  const [totalValue, setTotalValue] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  let year = localStorage.getItem("year");
  let month = localStorage.getItem("month");


  useEffect(() => {
      url = `/indicator-value/sign/idIndicator/${idIndicator}/stageId/${idStage}/year/${year}/month/${month}`

    Utils.Petition.get(
      url,
      (res) => {
          setTotalValue(res.data[0])
          setIsLoaded(true)
      }
    );
  }, []);

  return isLoaded && <Sign value={totalValue.value} type={totalValue.type} base={totalValue.unit==='%'?0:100} />;
}

export default SignValue;