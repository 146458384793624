import { useContext } from "react";
import { UserContext } from "../Context/User";
import { PageNotFoundImage, PageNotFoundTitle, PageNotFoundWrapper, PageNotFoundDescription } from "./styles";

function PageNotFound(){

    const { colorPalette } = useContext(UserContext);
    return (
        <PageNotFoundWrapper>
            <PageNotFoundImage >
                <img src="/assets/bx-error-alt.png" alt="not_found"/>
            </PageNotFoundImage>
            <PageNotFoundTitle color={colorPalette.primary}>Error 404</PageNotFoundTitle>
            <PageNotFoundDescription color={colorPalette.primary}>Página no encontrada</PageNotFoundDescription>
        </PageNotFoundWrapper>    
    )
}
export default PageNotFound;