
import { useContext } from "react";
import { FaCog } from "react-icons/fa";
import { Button } from "../../../commonStyles";
import { UserContext } from "../../../Context/User";
import { ButtonWrapper } from "./styles";

export const ButtonConfig = ({onPress}) =>{
    const { colorPalette } = useContext(UserContext);
    return (
        <>
        <ButtonWrapper>
            <Button className="mt-2" color={colorPalette.primary} onClick={onPress}>Configurar <FaCog /></Button>
        </ButtonWrapper>
            
        </>
    )
}