import { useState, useEffect, useContext } from 'react';
import CustomModal from '../../base/CustomModal';
import Utils from '../../utils';
import React from "react";
import { ButtonConfig } from '../Components/ButtonConfig';
import { UserContext } from '../../Context/User';


function Edit({ globalParams, page, module, everypages, filter }) {

    const { validateShowComponent } = useContext(UserContext);
    const [_idObjetive, setIdObjetive] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [showIndicatorsModal, setIndicatorsModal] = useState(false);
    const [isMenuModule, setIsMenuModule] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [dataObjetive, setDataObjetive] = useState([]);
    const [dataIndicators, setDataIndicators] = useState([]);
    const [image, setImage] = useState(new window.Image());
    const [state, setState] = useState({
        selectedShapeName: ""
    });
    const handlePopup = (event) => {
        event.preventDefault();
        setShowModal(true);
    }
    return (

        <div>
            {(module.idpopup !== null || validateShowComponent(everypages, filter)) &&<ButtonConfig onPress={handlePopup} />}
            
            {showModal &&
                <CustomModal title={"Administrar Mapa"} show={showModal} setShow={setShowModal} hideFooter>
                    <iframe style={{ border: 0 }} width="100%" height="820" src={Utils.getAdminUrl(`/value-map/module/${module.id}`)}></iframe>
                </CustomModal>
            }
        </div>
    );
}
export default Edit;