import Blank from './Blank';
import Default from './Default';
import EnvironmentAnalysis from './EnvironmentAnalysis';
import SalesContribution from './SalesContribution';
import Pyg from './Pyg';
import Home from './Home';
export default {
    Blank,
    Default,
    EnvironmentAnalysis,
    SalesContribution,
    Pyg,
    Home
}