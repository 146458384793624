import React, { useState, useEffect, useContext } from "react";
import { Table, Dropdown } from 'react-bootstrap';
import Utils from "../utils";
import { Link } from "react-router-dom";
import { ConfigTable, ConfigTable2, TableWrapper } from "./styles";
import Sign from "./Sign";
import CustomModal from '../base/CustomModal';
import {FaCog, FaEye, FaEyeSlash, FaAngleDown} from 'react-icons/fa';
import { UserContext } from "../Context/User";

function CreateTable({ dataConfig, data, idstages, configPopup, setConfigPopup, id }) {
  const { hasPermission, colorPalette } = useContext(UserContext);
  const [tableData, setTableData] = useState([]);
  const [headersTable, setHeadersTable] = useState([])
  //const [titleTable] = useState([dataConfig.title])
  const [showModal, setShowModal] = useState(false);
  const [hiddenRowsForm, setHiddenRowsForm] = useState([]);
  //const [hiddenRowsTable, setHiddenRowsTable] = useState([]);
  
  const [hiddenColsForm, setHiddenColsForm] = useState([]);
  
  const [hiddenCols, sethiddenCols] = useState(
    (dataConfig.hiddenCols && dataConfig.hiddenCols[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')])? 
    dataConfig.hiddenCols[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')]
    :
    []    
  );
  
  const [hiddenRows, setHiddenRows] = useState(
    (dataConfig.hiddenRows && dataConfig.hiddenRows[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')])? 
    dataConfig.hiddenRows[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')]
    :
    []
  );
  const format_number = Utils.getConfigPortal('format_number');
  const format_percent = Utils.getConfigPortal('format_percent');

  const handlePopup = (event, id, title) => {
    event.preventDefault();
    setConfigPopup({
      ...configPopup,
      id,
      title
    })
  }

  const handleHideRows=(row)=>{
    let _hiddenRowsForm= [...hiddenRowsForm];
    _hiddenRowsForm.push(row);
    setHiddenRowsForm(_hiddenRowsForm);
  }
  const handleShowRows=(row)=>{
    let _hiddenRowsForm= [...hiddenRowsForm];
    _hiddenRowsForm.splice(_hiddenRowsForm.indexOf(row),1);
    setHiddenRowsForm(_hiddenRowsForm);
  }

  const handleHideCols=(row)=>{
    let _hiddenColsForm= [...hiddenColsForm];
    _hiddenColsForm.push(row);
    setHiddenColsForm(_hiddenColsForm);
  }
  const handleShowCols=(row)=>{
    let _hiddenColsForm= [...hiddenColsForm];
    _hiddenColsForm.splice(_hiddenColsForm.indexOf(row),1);
    setHiddenColsForm(_hiddenColsForm);
  }

  const handleSaveRows = ()=>{

    if( dataConfig.hasOwnProperty('hiddenRows')) {
        dataConfig.hiddenRows[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')] = hiddenRowsForm;
    } else {
      dataConfig.hiddenRows = {
        [localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')]: hiddenRowsForm
      }
    }
    if( dataConfig.hasOwnProperty('hiddenCols')) {
      dataConfig.hiddenCols[localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')] = hiddenColsForm;
    } else {
      dataConfig.hiddenCols = {
        [localStorage.getItem('year')+'-'+localStorage.getItem('month')+'-'+localStorage.getItem('view')]: hiddenColsForm
      }
    }
    const data = {
      id,
      config: dataConfig
    };

    Utils.Petition.post(
        '/pages-modules/update', data
    );
    
    setHiddenRows(hiddenRowsForm);
    sethiddenCols(hiddenColsForm);
    setShowModal(false);
  }
  useEffect(() => {
    let htmlTr = [];
    let headers = [];
    //console.log("dataconfig-->",dataConfig)
    //console.log("data--->",data)
    //console.log("idstages---->", idstages)
    if (dataConfig.reverse) {
      headers.push(dataConfig.title_column !== "" ? <th key={0} scope="col" style={dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''}}>{dataConfig.title_column}</th> : <th key={0} scope="col">Escenarios</th>)

      headers.push(dataConfig.indicators.map((ind, index) => {
        let label = ind.name;
        if (ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "") {
          label = ind.labelIndicator;
        };
        let th;
        if (ind.popup) {
          th = <th style={{display:hiddenCols.includes(index) ? 'none': ''}} key={index} scope="row"><Link to="#" className="text-success" onClick={e => handlePopup(e, ind.popUpSelected.id, ind.popUpSelected.name)}>{label}</Link></th>
        };
        if (ind.url) {
          th = <th style={{display:hiddenCols.includes(index) ? 'none': ''}} key={index} scope="row"><Link to={ind.urlSelected.path}>{label}</Link></th>
        }
        if (!ind.popup && !ind.url) {
          th = <th style={{display:hiddenCols.includes(index) ? 'none': ''}} key={index} scope="row">{label}</th>
        }
        return th;
      }));

      idstages.map((idstage, index) => {
        let month = parseInt(localStorage.getItem('month')) + 1;
        let year = parseInt(localStorage.getItem('year'));
        if( dataConfig.stages[index].lastYear ) {
            year--;
        }   
        if( dataConfig.stages[index].lastDec ) {
            year--;
            month = 12;
        }
        let info = '';
        let stage_label = dataConfig.stages[index].label;

        if (dataConfig.stages[index].hasOwnProperty('labelStage') && dataConfig.stages[index].labelStage.trim() !== "") stage_label = dataConfig.stages[index].labelStage;
        if( dataConfig.stages[index].spaceAbove ) {
          htmlTr.push(
            <tr className="tr-empty">
              {
                dataConfig.stages[index].hasOwnProperty('labelSpaceAbove') && dataConfig.stages[index].labelSpaceAbove !== "" ? 
                <th key={index} scope="row">{dataConfig.stages[index].labelSpaceAbove}</th>
                :
                <th key={index} scope="row"> </th>
              }
              {
                dataConfig.indicators.map( (indicator, indexI) => {
                    return <td key={indexI}></td>
                })
              }
            </tr>
          )
        };
        htmlTr.push(
          <tr key={index} style={{display:hiddenRows.includes(index)?'none':'table-row'}}>
            <th scope="row">{stage_label}</th>
            {
              dataConfig.indicators.map((indicator, indexI) => {
                if (dataConfig.stages[index].othersInd) {
                  if( dataConfig.stages[index].lessInd ) {
                    if( indexI + 1 >= parseInt(dataConfig.stages[index].posLessInd) && indexI + 1 < parseInt(dataConfig.stages[index].posLessInd) + parseInt(dataConfig.stages[index].amountLessInd)) {
                      info = data.filter( aux => dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                      if(idstage.compliance && indicator.unit !== "%" ) {  
                        let real = data.filter( aux => dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].id === aux.indicator && parseInt(idstage.compliance_stages[0])  === aux.stage && aux.year === year && aux.month === month)[0];
                        let ppto = data.filter( aux => dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].id === aux.indicator && parseInt(idstage.compliance_stages[1])  === aux.stage && aux.year === year && aux.month === month)[0];
                        if( real.value !== null && ppto.value !== null) {
                          ppto.value = ppto.value === 0 ? 1 : ppto.value;
                          let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                          info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                        } 
                    }
                    } else {
                      return <td style={{display:hiddenCols.includes(indexI) ? 'none': ''}} key={indexI}></td>
                    }
                  } else {
                      info = data.filter( aux => dataConfig.stages[index].newIndicators[indexI].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                      if(idstage.compliance && indicator.unit !== "%" ) {  
                        let real = data.filter( aux => dataConfig.stages[index].newIndicators[indexI].id === aux.indicator && parseInt(idstage.compliance_stages[0]) === aux.stage && aux.year === year && aux.month === month)[0];
                        let ppto = data.filter( aux => dataConfig.stages[index].newIndicators[indexI].id === aux.indicator && parseInt(idstage.compliance_stages[1]) === aux.stage && aux.year === year && aux.month === month)[0];
                        if( real.value !== null && ppto.value !== null) {
                          ppto.value = ppto.value === 0 ? 1 : ppto.value;
                          let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                          info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                        } 
                    }
                  } 
                } else {
                  info = data.filter(aux => indicator.id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                  if(idstage.compliance && indicator.unit !== "%" ) {  
                    let real = data.filter( aux => indicator.id === aux.indicator && parseInt(idstage.compliance_stages[0]) === aux.stage && aux.year === year && aux.month === month)[0];
                    let ppto = data.filter( aux => indicator.id === aux.indicator && parseInt(idstage.compliance_stages[1]) === aux.stage && aux.year === year && aux.month === month)[0];
                    if( real.value !== null && ppto.value !== null) {
                      ppto.value = ppto.value === 0 ? 1 : ppto.value;
                      let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                      info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                    } 
                }
                }
                let value = 0;
                if(info.value!==null){
                    if(indicator.percent || idstage.percent){
                      value = Utils.formatNumber(format_percent, info.value)+'%';
                    }else{
                      value = Utils.formatNumber(format_number, info.value);
                    }
                }
                /*
                if (info.value !== null) {
                  let formato = Math.abs(info.value) < 100 ? ',.1f' : ',.0f';
                  value  = Utils.formatNumber(formato, info.value);
                }
                if (indicator.percent || idstage.percent) {
                  value = info.value !== null ? Utils.formatNumber(',.1f', info.value) + "%" : 0.0 + '%';
                }
                //*/
                if (idstage.semaphore === true) {
                  if( dataConfig.stages[index].othersInd ) {
                    if(dataConfig.stages[index].lessInd ) {
                        if( indexI + 1 >= parseInt(dataConfig.stages[index].posLessInd) && indexI + 1 < parseInt(dataConfig.stages[index].posLessInd) + parseInt(dataConfig.stages[index].amountLessInd)) {
                          return <td style={{display:hiddenCols.includes(indexI) ? 'none': ''}} key={indexI}><Sign type={dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].type} value={info.value} base={dataConfig.stages[index].newIndicators[indexI - parseInt(dataConfig.stages[index].posLessInd) + 1].unit === "%" ? 0 : 100}  isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                        }
                    } else {
                        return <td style={{display:hiddenCols.includes(indexI) ? 'none': ''}} key={indexI}><Sign type={dataConfig.stages[index].newIndicators[indexI].type} value={info.value} base={dataConfig.stages[index].newIndicators[indexI].unit === "%" ? 0 : 100}  isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                    }
                  } else {
                    return <td style={{display:hiddenCols.includes(indexI) ? 'none': ''}} key={indexI}><Sign type={indicator.type} value={info.value} base={indicator.unit === "%" ? 0 : 100}  isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                  }   
                } else {
                  return <td style={{display:hiddenCols.includes(indexI) ? 'none': ''}} key={indexI}>{value}</td>
                }
              })
            }
          </tr>
        );
        if( dataConfig.stages[index].spaceBelow ) {
          htmlTr.push(
            <tr className="tr-empty">
              {
                dataConfig.stages[index].hasOwnProperty('labelSpaceBelow') && dataConfig.stages[index].labelSpaceBelow !== "" ? 
                <th scope="row">{dataConfig.stages[index].labelSpaceBelow}</th>
                :
                <th scope="row">  </th>
              }
              {
                dataConfig.indicators.map( (indicator, indexI) => {
                    return <td key={indexI}></td>
                })
              }
            </tr>
          )
        };
      })


    } else {

      headers.push(dataConfig.title_column !== "" ? <th key={0} scope="col" style={dataConfig.widthFirstCol && dataConfig.widthFirstCol > 0 ? {width:`${dataConfig.widthFirstCol}px`} : {width:''}}>{dataConfig.title_column}</th> : <th key={0} scope="col">Indicadores</th>)

      headers.push(dataConfig.stages.map((stage, index) => {

        if (stage.hasOwnProperty('labelStage') && stage.labelStage.trim() !== "") {
          return <th key={index + 1} scope="col" style={{display:hiddenCols.includes(index) ? 'none': ''}} >{stage.labelStage}</th>;
        } else {
          return <th key={index + 1} scope="col" style={{display:hiddenCols.includes(index) ? 'none': ''}} >{stage.label}</th>;
        }
      }));

      dataConfig.indicators.map((indicator, index) => {
        let info = '';
        let ind_label = indicator.name;
        if (indicator.hasOwnProperty('labelIndicator') && indicator.labelIndicator.trim() !== "") ind_label = indicator.labelIndicator;
        if( indicator.spaceAbove ) {
          
          htmlTr.push(
            <tr className="tr-empty">
              {
                indicator.hasOwnProperty('labelSpaceAbove') && indicator.labelSpaceAbove !== "" ? 
                <th scope="row">{indicator.labelSpaceAbove}</th>
                :
                <th scope="row">  </th>
              }
              {
                idstages.map( (idstage, indexS) => {        
                    return <td key={indexS}></td>
                })
              }
            </tr>
          )
        };
        
        htmlTr.push(
          <tr key={index} style={{display:hiddenRows.includes(index)?'none':'table-row'}} className={indicator.highlight ? "table-secondary" : null}>
            {
              indicator.url &&
              <th scope="row"><Link to={indicator.urlSelected.path}>{ind_label}</Link></th>
            }
            {
              indicator.popup &&
              <th scope="row"><Link to="#" className="text-success" onClick={e => handlePopup(e, indicator.popUpSelected.id, indicator.popUpSelected.name)}>{ind_label}</Link></th>
            }
            {
              !indicator.popup && !indicator.url &&
              <th scope="row">{ind_label}</th>
            }
            {
              idstages.map((idstage, indexS) => {
                let month = parseInt(localStorage.getItem('month')) + 1;
                let year = parseInt(localStorage.getItem('year'));
                if( dataConfig.stages[indexS].lastYear ) {
                    year--;
                }   
                if( dataConfig.stages[indexS].lastDec ) {
                    year--;
                    month = 12;
                }
                if (dataConfig.stages[indexS].othersInd) {
                  if(  dataConfig.stages[indexS].lessInd ) {
                    if( index + 1 >= parseInt(dataConfig.stages[indexS].posLessInd) && index + 1 < parseInt(dataConfig.stages[indexS].posLessInd) + parseInt(dataConfig.stages[indexS].amountLessInd)) {
                      info = data.filter( aux => dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                      if(idstage.compliance && indicator.unit !== "%" ) {  
                        let real = data.filter( aux => dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].id === aux.indicator && parseInt(idstage.compliance_stages[0]) === aux.stage && aux.year === year && aux.month === month)[0];
                        let ppto = data.filter( aux => dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].id === aux.indicator && parseInt(idstage.compliance_stages[1]) === aux.stage && aux.year === year && aux.month === month)[0];
                        if( real.value !== null && ppto.value !== null) {
                          ppto.value = ppto.value === 0 ? 1 : ppto.value;
                          let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                          info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                        } 
                      }
                    } else {
                      return <td key={indexS}></td>
                    }
                  } else {
                      info = data.filter( aux => dataConfig.stages[indexS].newIndicators[index].id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                      if(idstage.compliance && indicator.unit !== "%") {
                        let real = data.filter( aux => dataConfig.stages[indexS].newIndicators[index].id === aux.indicator && parseInt(idstage.compliance_stages[0])  === aux.stage && aux.year === year && aux.month === month)[0];
                        let ppto = data.filter( aux => dataConfig.stages[indexS].newIndicators[index].id === aux.indicator && parseInt(idstage.compliance_stages[1])  === aux.stage && aux.year === year && aux.month === month)[0];
                        if( real.value !== null && ppto.value !== null) {
                          ppto.value = ppto.value === 0 ? 1 : ppto.value;
                          let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                          info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                        } 
                    }
                  }
                } else {
                  info = data.filter(aux => indicator.id === aux.indicator && idstage.id === aux.stage && aux.year === year && aux.month === month)[0];
                  if(idstage.compliance && indicator.unit !== "%") {
                    let real = data.filter( aux => indicator.id === aux.indicator && parseInt(idstage.compliance_stages[0]) === aux.stage && aux.year === year && aux.month === month)[0];
                    let ppto = data.filter( aux => indicator.id === aux.indicator && parseInt(idstage.compliance_stages[1]) === aux.stage && aux.year === year && aux.month === month)[0];
                    if( real.value !== null && ppto.value !== null) {
                      ppto.value = ppto.value === 0 ? 1 : ppto.value;
                      let variacion = Math.abs((parseFloat(real.value)-parseFloat(ppto.value))/parseFloat(ppto.value));
                      info.value = parseFloat(real.value) > parseFloat(ppto.value) ? (1 + variacion) * 100 : (1 - variacion) * 100;
                    } 
                  }
                }
                let value = 0;
                if(info.value!==null){
                    if(indicator.percent || idstage.percent){
                      value = Utils.formatNumber(format_percent, info.value)+'%';
                    }else{
                      value = Utils.formatNumber(format_number, info.value);
                    }
                }
                /*
                if (info.value !== null) {
                  let formato = Math.abs(info.value) < 100 ? ',.1f' : ',.0f';
                  value  = Utils.formatNumber(formato, info.value);
                }
                if (indicator.percent || idstage.percent) {
                  value = info.value !== null ? Utils.formatNumber(',.1f', info.value) + "%" : 0.0 + '%';
                }
                //*/

                
                if (idstage.semaphore === true) {
                  if( dataConfig.stages[indexS].othersInd ) {
                    if(  dataConfig.stages[indexS].lessInd ) {
                      if( index + 1 >= parseInt(dataConfig.stages[indexS].posLessInd) && index + 1 < parseInt(dataConfig.stages[indexS].posLessInd) + parseInt(dataConfig.stages[indexS].amountLessInd)) {
                          return <td key={indexS} style={{display:hiddenCols.includes(indexS) ? 'none': ''}}><Sign type={dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].type} value={info.value} base={dataConfig.stages[indexS].newIndicators[index - parseInt(dataConfig.stages[indexS].posLessInd) + 1].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                      }
                    } else {
                        return <td key={indexS} style={{display:hiddenCols.includes(indexS) ? 'none': ''}}><Sign type={dataConfig.stages[indexS].newIndicators[index].type} value={info.value} base={dataConfig.stages[indexS].newIndicators[index].unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                    }
                  } else { 
                      return <td key={indexS} style={{display:hiddenCols.includes(indexS) ? 'none': ''}}><Sign type={indicator.type} value={info.value} base={indicator.unit === "%" ? 0 : 100} isDescendingIndicator={!dataConfig.disableDesendingSign}/><span> {value}</span></td>
                  }
                } else {
                  return <td key={indexS} style={{display:hiddenCols.includes(indexS) ? 'none': ''}}>{value}</td>
                }

              })
            }
          </tr>
        )
        if( indicator.spaceBelow ) {
         
          htmlTr.push(
            <tr className="tr-empty">
              {
                indicator.hasOwnProperty('labelSpaceBelow') && indicator.labelSpaceBelow !== "" ? 
                <th scope="row">{indicator.labelSpaceBelow}</th>
                :
                <th scope="row">  </th>
              }
              {
                idstages.map( (idstage, indexS) => {        
                    return <td key={indexS}></td>
                })
              }
            </tr>
          );
        };
      });

    }
    setHeadersTable(headers)
    setTableData(htmlTr)


  }, [hiddenRows,hiddenCols]);

  return (
    <div className="text-center">
      <TableWrapper colors={colorPalette}>
        {
          hasPermission.edit &&
          <ConfigTable className="config-buton" colors={colorPalette}>
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                <FaAngleDown />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={()=>{setShowModal(true); setHiddenRowsForm(hiddenRows); setHiddenColsForm(hiddenCols) }}>Configurar <FaCog /></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            
          </ConfigTable>
        }
        <Table style={{marginTop:`${dataConfig.marginTop !== undefined && dataConfig.marginTop !== "" ? dataConfig.marginTop : 0 }px`}} className={dataConfig.otherStyle ? "table-sec" : null}>
          <thead >
            <tr>
              {headersTable}
            </tr>
          </thead>
          <tbody>
            {tableData}
          </tbody>
        </Table>
      </TableWrapper>
      <CustomModal show={showModal} setShow={setShowModal} handleSaveButton={handleSaveRows}>
        <Table>
          <thead>
            <tr>
              <th>Fila</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            { dataConfig.reverse === false ?
              dataConfig.indicators.map((ind, index)=>{
                return (
                  <tr key={index}>
                      <td>{(ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "") ? ind.labelIndicator : ind.name}</td> 
                      <td>
                        {
                          hiddenRowsForm.includes(index)?
                          <ConfigTable2 align="left" colors={colorPalette}>
                            <span onClick={()=>handleShowRows(index)}>Mostrar <FaEye /></span>
                          </ConfigTable2>:
                          <ConfigTable2 align="left" colors={colorPalette}>
                            <span onClick={()=>handleHideRows(index)}>Ocultar <FaEyeSlash /></span>
                          </ConfigTable2>    
                        }
                      </td>
                  </tr>
                )
              })
              :
              dataConfig.stages.map((stage,idx) => {
                return(
                  <tr key={idx}>
                    <td>{(stage.hasOwnProperty('labelStage') && stage.labelStage.trim() !== "") ? stage.labelStage : stage.label}</td> 
                    <td>
                      {
                        hiddenRowsForm.includes(idx)?
                        <ConfigTable2 align="left" colors={colorPalette}>
                          <span onClick={()=>handleShowRows(idx)}>Mostrar <FaEye /></span>
                        </ConfigTable2>:
                        <ConfigTable2 align="left" colors={colorPalette}>
                          <span onClick={()=>handleHideRows(idx)}>Ocultar <FaEyeSlash /></span>
                        </ConfigTable2>
                      }
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
        <Table>
          <thead>
            <tr>
              <th>Columna</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            { dataConfig.reverse === false ?
              dataConfig.stages.map((stage,idx) => {
                return(
                  <tr key={idx}>
                    <td>{(stage.hasOwnProperty('labelStage') && stage.labelStage.trim() !== "") ? stage.labelStage : stage.label}</td> 
                    <td>
                      {
                        hiddenColsForm.includes(idx)?
                        <ConfigTable2 align="left" colors={colorPalette}>
                          <span onClick={()=>handleShowCols(idx)}>Mostrar <FaEye /></span>
                        </ConfigTable2>:
                        <ConfigTable2 align="left" colors={colorPalette}>
                          <span onClick={()=>handleHideCols(idx)}>Ocultar <FaEyeSlash /></span>
                        </ConfigTable2>
                      }
                    </td>
                  </tr>
                )
              })
              :
              dataConfig.indicators.map((ind, index)=>{
                return (
                  <tr key={index}>
                      <td>{(ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "") ? ind.labelIndicator : ind.name}</td> 
                      <td>
                        {
                          hiddenColsForm.includes(index)?
                          <ConfigTable2 align="left" colors={colorPalette}>
                            <span onClick={()=>handleShowCols(index)}>Mostrar <FaEye /></span>
                          </ConfigTable2>:
                          <ConfigTable2 align="left" colors={colorPalette}>
                            <span onClick={()=>handleHideCols(index)}>Ocultar <FaEyeSlash /></span>
                          </ConfigTable2>    
                        }
                      </td>
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </CustomModal>
    </div>
  )
}

export default CreateTable