import { useContext, useEffect, useState } from 'react';
import { FaRegTrashAlt, FaArrowsAlt, FaRegClone, FaEdit, FaSave, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import Utils from '../utils';
import { ModuleWrapper, ModuleTitle, ModuleOptions, ModuleOrder, ModuleTabs, ModuleActions,ModuleType } from './styles';
import { Dropdown} from 'react-bootstrap';
import {AlertConfirm, ICON} from "../Components/SweetAlert";
import { UserContext } from '../Context/User';
import { Button, StyleCheckbox } from '../commonStyles';

function EditModule({order, orders, setOrders, templateZonesLoading, ComponentTagName, page, modules, setModules, module}){
    const { hasPermission, colorPalette } = useContext(UserContext);
    const [isMenuMove, setIsMenuMove] = useState(false);
    
    const [nameEditMode, setNameEditMode] = useState(false);
    const [yearMonth, setYearMonth] = useState('no-filter');
    const [name, setName] = useState('');
    const [viewType, setViewType] = useState(false);
    const [toogleViewFilter, setToggleViewFilter] = useState(false);
    const [filter, setFilter] = useState(false);
    const [everypages, setEveryPages] = useState(false);
    useEffect(() => {
        if(module.idfilter!==null){
            setFilter(true);
        }
        if(module.year!==null && module.month ===null){
            setYearMonth('year');
        }else if(module.year!==null && module.month !==null){
            setYearMonth('year-month');
        }
        if(module.view!==null){
            setViewType(true);
        }
        if(module.idpage===null){
            setEveryPages(true);
        
        }
        setName(module.name);
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleMenuFaArrowsAlt = ()=>{
        setIsMenuMove(!isMenuMove);
    }
    const handleNameEditMode = ()=>{
        setNameEditMode(true);
    };
    const handleChangeName = (event)=>{
        setName(event.target.value);
    }
    const handleToogleViewFilter = ()=>{
        setToggleViewFilter(!toogleViewFilter);
    }
    const handleSaveName = () => {
        let newModules = [...modules];
        for(let i =0; i<newModules.length; i++){
            if(newModules[i].id === module.id){
                newModules[i].name=name;
                break;
            }
        }
        setModules(newModules);
        Utils.Petition.post('/pages-modules/update/', {id:module.id, name:name});


        findOrderById(1);
        setNameEditMode(false);
        
    };
    const handleMoveZone =(event=>{
        event.preventDefault();
        Utils.Petition.post(
            '/pages-modules/update',
            {
                id:module.id,
                section: event.target.innerText
            },
            (_)=>{
                templateZonesLoading[event.target.innerText].setLoaded(false);
                templateZonesLoading[module.section].setLoaded(false);
                setIsMenuMove(false);
            }
        );
    })
    const handleChangeOrder = ((event)=>{
        let newOrders = [...orders];
        const oldOrderNumber = parseInt(event.target.name.split('-')[1]);
        const newOrderNumber = parseInt(event.target.value);
        const oldOrder = findOrder(newOrders, oldOrderNumber);
        
        if(oldOrderNumber>newOrderNumber){
            for(let i = oldOrderNumber-1; i>=newOrderNumber;i--){
                const selectedOrder = findOrder(newOrders, i);
                selectedOrder.order++;
            }
        }else{
            for(let i=oldOrderNumber+1; i<=newOrderNumber; i++){
                const selectedOrder = findOrder(newOrders, i);
                selectedOrder.order--;
            }
        }
        oldOrder.order = newOrderNumber;
        setOrders(newOrders);
        Utils.Petition.post('/pages-modules/update/multiple-order', newOrders);

    });
    function findOrder(newOrders, index){
        for(let io=0;io<newOrders.length;io++){
            if(newOrders[io].order===index){
                return newOrders[io];
            }
        }
        return null;
    }
    function findOrderById(id){
        for(let ioi=0;ioi<orders.length;ioi++){
            if(orders[ioi].id===id){
                return orders[ioi];
            }
        }
        return null;
    }
    const isValidDelete = (valid) => {
        if(valid){
            Utils.Petition.delete(
                '/pages-modules/id/'+module.id,
                (_)=>{ 
                    let newOrders = [...orders];
                    
                    const oldOrder = findOrderById(module.id);
                    const oldOrderNumber =oldOrder.order;
                    
                    for(let i=oldOrderNumber+1; i<=newOrders.length; i++){
                        const selectedOrder = findOrder(newOrders, i);
                        selectedOrder.order--;
                    }
                    
                    newOrders = newOrders.filter((order)=> order.id !== module.id);
                    setOrders(newOrders);
    
                    const newModules = modules.filter((_module)=>_module.id!==module.id);
                    setModules(newModules);
                    
                }
            );
        }
    }
    const handleDelete = ()=>{
        console.log("delete", everypages)
        if(everypages){
            AlertConfirm(ICON.WARNING, 'Este módulo está agregado en varias páginas, si lo elimina, se eliminará de todas las páginas', "¿seguro que desea continuar?",
            () => isValidDelete(true), "Si",() =>  isValidDelete(false));
        } else {
            isValidDelete(true);
        }
    }
    const handleDuplicate = ()=>{
        let year = null;
        let month = null;
        let idfilter = null;
        if(filter){
            idfilter = localStorage.getItem('idfilter');
        }

        if(yearMonth==='year'){
            year = localStorage.getItem('year');
        }else if(yearMonth==='year-month'){
            year = localStorage.getItem('year');
            month = localStorage.getItem('month');
        }

        const pageModule = { ...module, year, month, idfilter };

        delete pageModule.id;
        delete pageModule.createdAt;
        delete pageModule.updatedAt;
        Utils.Petition.post('/pages-modules',
        pageModule,
        (response)=>{
            setModules([...modules, response.data]);
            const amountOrders = orders.length;
            setOrders([...orders, {id:response.data.id, order:amountOrders+1}]);
        })
    }
    const handleIdFilter = (event)=>{
        const new_filter = (!filter);
        setFilter(new_filter);
        if(new_filter===true){
            Utils.Petition.post('/pages-modules/update',
            {
                id:module.id,
                idfilter: localStorage.getItem('idfilter')
            });
        }else{
            Utils.Petition.post('/pages-modules/update',
            {
                id:module.id,
                idfilter: null
            });
        }
    }
    const handleYearMonth = (event)=>{
        const new_yearMonth = event.target.value
        let year = null;
        let month = null;
        setYearMonth(new_yearMonth);
        
        if(new_yearMonth==='year'){
            year = localStorage.getItem('year');
        }else if(new_yearMonth==='year-month'){
            year = localStorage.getItem('year');
            month = localStorage.getItem('month');
        }
        Utils.Petition.post('/pages-modules/update',
            {
                id:module.id,
                year,
                month
            });
    }
    const handleViewType = (event)=>{
        const newViewType = (!viewType);
        setViewType(newViewType);
        if(newViewType===true){
            Utils.Petition.post('/pages-modules/update',
            {
                id:module.id,
                view: localStorage.getItem('view')
            });
        }else{
            Utils.Petition.post('/pages-modules/update',
            {
                id:module.id,
                view: null
            });
        }
    }

    const isValid = (check, valid) => {
        if(valid){
            setEveryPages(check);
            if(check){
                Utils.Petition.post('/pages-modules/update',{
                    id:module.id,
                    idpage: null
                });
            }else{
                Utils.Petition.post('/pages-modules/update',{
                    id:module.id,
                    idpage: page.id
                });
            }
        }
    }
    const handleEveryPages = (event)=>{
        console.log(event)
        if(!event.target.checked){
            AlertConfirm(ICON.WARNING, 'Deshabilitar esta opción, significa que el módulo dejará de mostrarse en las demás páginas y únicamente se mostrará en la página '+page.name, "¿seguro que desea continuar?",
            () => isValid(false, true), "Si", () => isValid(false, false));
        } else {
            isValid(true, true)
        }
    }
    const validateShowComponent = () => {
        // Mostrar en todas las páginas en la sección si esta activo 
        // y si idPage y idFilter estan en 0 se muestra componente
        // mostrar todas las paginas esta desactivado se muestra componente

        // filtrar por region esta desactivado y si filterRegion esta en 0 se muestra
        // filtrar por region esta activo se muestra

        return ((!everypages || (module.idpopup && hasPermission.filterRegion)) || (hasPermission.showAllPages && hasPermission.filterRegion)) && (filter || hasPermission.filterRegion);
    }
    return (
        <ModuleWrapper color={colorPalette.primary} >
            <ModuleOptions colors={colorPalette}>
                {(validateShowComponent() || module.idpopup !== null ) 
                &&
                 <Dropdown>
                    <Dropdown.Toggle>
                        <FaArrowsAlt title="Mover"/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {
                            Object.keys(templateZonesLoading).map((zone)=>{
                                return (
                                    zone === module.section ?
                                    <Dropdown.Item key={zone} className="actual">{zone}<span>Actual</span></Dropdown.Item>
                                    :<Dropdown.Item key={zone} onClick={handleMoveZone}>{zone}</Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>}
                {(validateShowComponent() || module.idpopup !== null ) && <Button><FaRegClone title="Duplicar" onClick={handleDuplicate} /></Button>}
                {(validateShowComponent() || module.idpopup !== null) && <Button className="trash" onClick={handleDelete}><FaRegTrashAlt title="Eliminar" /></Button>}
                
            </ModuleOptions>
            <ModuleType color={colorPalette.secondary}>{
                module.module_code
            }</ModuleType>
            <ModuleTitle>
                <div className="input-label" >
                    { !nameEditMode && <label className="text-input-label">{name} <FaEdit onClick={handleNameEditMode}/></label> } 
                    { nameEditMode && <> <input type="text" value={name} className="text-input-edition" onChange={handleChangeName} /> <FaSave onClick={handleSaveName}/> </> }
                </div>
            </ModuleTitle>
            
            <ModuleTabs>
                {
                    (module.idpage !== null || module.idpopup === null ) && 
                    <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-3">
                        <input className="custom-control-input" id={`pages_${page.id}_module_${module.id}`} type="checkbox" checked={everypages} onChange={handleEveryPages} disabled={!hasPermission.showAllPages}/>
                        <label className="custom-control-label" htmlFor={`pages_${page.id}_module_${module.id}`}>
                        Mostrar en todas las páginas en la sección <b>{module.section}</b>
                        </label>
                    </StyleCheckbox>
                }
            </ModuleTabs>
            <ModuleOrder>
                <label>
                    Orden {
                        order !== undefined && 
                        <select name={"order-"+order.order} onChange={handleChangeOrder} value={order.order}>
                        {
                            orders.map((_order)=>{
                                return (
                                    <option key={_order.id} value={_order.order}>{_order.order}</option>
                                );
                            })
                        } 
                    
                        </select>
                    }
                </label>
            </ModuleOrder>
            
            
            <ModuleActions className="actions">
                <div>
                    <div className="filters">
                        <Button color={"white"} onClick={handleToogleViewFilter} className="buton-filter">Filtrar {toogleViewFilter ? <FaChevronUp />:<FaChevronDown />}</Button>
                        {
                            toogleViewFilter &&
                            <div className="section-filter">
                                <div className="form-group">
                                    <div>
                                    <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-4">
                                        <input className="custom-control-input" id={`filter_${page.id}_module_${module.id}`} name="idFilter" type="checkbox" checked={filter} onChange={handleIdFilter} disabled={ !((validateShowComponent() && hasPermission.filterRegion))}/>
                                        <label className="custom-control-label" htmlFor={`filter_${page.id}_module_${module.id}`}>
                                            Filtrar por región
                                        </label>
                                    </StyleCheckbox>
                                    </div>
                                    <div>
                                        <label>
                                            Año y mes <select onChange={handleYearMonth} value={yearMonth} disabled={!(validateShowComponent() || module.idpopup !== null)}>
                                                <option key={1} value="no-filter" >Todos los meses y años</option>
                                                <option key={2} value="year">Filtrar solo por año</option>
                                                <option key={3} value="year-month">Filtrar por año y mes</option>
                                            </select>
                                        </label>
                                    </div>
                                    <div>
                                        <StyleCheckbox colors={colorPalette} className="custom-checkbox ml-4">
                                            <input className="custom-control-input" id={`view_${page.id}_module_${module.id}`} name="viewType" type="checkbox" checked={viewType} onChange={handleViewType} disabled={!(validateShowComponent() || module.idpopup !== null)}/>
                                            <label className="custom-control-label" htmlFor={`view_${page.id}_module_${module.id}`}>
                                                Filtrar por vista {localStorage.getItem('view')==='A'?'acumulada':'mensual'} 
                                            </label>
                                        </StyleCheckbox>
                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </ModuleActions>
            <ComponentTagName page={page} module={module} everypages={everypages} filter={filter}/>
        </ModuleWrapper>
    )
}
export default EditModule;