import Utils from ".";
import { VERSION_SESSION } from "../config";
import Petition from "./Petition";

const Security = {
    checkRoleGroup:(rol)=>{
        return true;
    },
    //type = SSO, Login, AppValidate
    setStartLocalStorage: function(response, type, callback){
        if(type!=='AppValidate'){
            localStorage.setItem('version', VERSION_SESSION);
        }
        if(!localStorage.getItem('idfilter')){
            localStorage.setItem('idfilter', response.data.idfilter);
            localStorage.setItem('filter', response.data.filter);
        }
        localStorage.setItem('user_ocupation', JSON.stringify({department: response.data.user.department?.name,position:response.data.user.position?.name}));
        localStorage.setItem('token_user', response.data.token);
        localStorage.setItem('iduser', response.data.user.id)
        localStorage.setItem('idportal', response.data.idportal);
        localStorage.setItem('portal', response.data.portal);
        localStorage.setItem('business', response.data.business);
        localStorage.setItem('client', response.data.client);
        localStorage.setItem('config', JSON.stringify(response.data.config));
        localStorage.setItem('content_permissions', JSON.stringify(response.data.permissionsContent));
        localStorage.setItem('admin_permissions', JSON.stringify(response.data.permissionsAdmin));
        localStorage.setItem('idrolAdmin', response.data.idrol_admin);
        localStorage.setItem('idrolContent', response.data.idrol_content);
        localStorage.setItem('rolAdmin', response.data.rol_admin);
        localStorage.setItem('rolContent', response.data.rol_content);
         Utils.Petition.get('/portals-filter', (response) => {
            let _filter = [];
            for (let i = 0; i < response.data.length; i++) {
                if(response.data[i].default==='1') localStorage.setItem('defaultFilter', response.data[i].filter.id);
                _filter.push({ value: response.data[i].filter.id, description: response.data[i].filter.name })
            }
            localStorage.setItem('filters', JSON.stringify(_filter));
            callback();
        });
    }
}

export default Security;