import React, { useContext, useEffect, useState } from "react";
import Utils from "../../../utils";
import Select from "react-select";
import { styleTheme } from "../../../themes/themeSelect";
import { UserContext } from "../../../Context/User";

function AddSign() {
  const { colorPalette } = useContext(UserContext);
  //obtains data from DB
  const [optionsIndicator, setOptionsIndicator] = useState([]);
  const [optionsStage, setOptionsStage] = useState([]);
  const [selectedIndicator, setSelectedIndicator] = useState(null);
  const [selectedStage, setSelectedStage] = useState("");

  const indicatorHandler = (evt) => {
    setSelectedIndicator(evt.value);
    localStorage.setItem("signIndicator", evt.value);
  };

  const stageHandler = (evt) => {
    setSelectedStage(evt.value);
    localStorage.setItem("signStage", evt.value);
  };

  useEffect(() => {
    Utils.Petition.get(
      "/indicators/getNames",
      (res) => {
        let data = res.data;
        let _options = [];
        for (let name in data) {
          let label = `${data[name].code} ${data[name].name}`;
          let value = data[name].id;

          _options.push({ value: value, label: label });
        }
        setOptionsIndicator(_options);
      },
      (err) => {
        console.log(err);
      }
    );

    Utils.Petition.get(
      "/stages_relation/filterbyname/montly",
      (res) => {
        let _options = [];
        let data = res.names;
        for (let pos in data) {
          _options.push({ value: data[pos].code, label: data[pos].name });
        }
        setOptionsStage(_options);
      },
      (err) => {
        console.log(err);
      }
    );
  }, []);

  return (
    <div className="card">
      <div className="card-header text-center">
        <b>Generador de Semaforos</b>
      </div>
      <div className="card-body">
        <div className="mt-3">
          <label htmlFor={"selectIndicator"}>
            <b>Seleccionar Indicador</b>
          </label>
          <Select
            options={optionsIndicator}
            onChange={indicatorHandler}
            theme={theme => (styleTheme(theme, colorPalette?.primary))}
          />
        </div>
        <div className="mt-3">
          <label htmlFor={"selectStage"}>
            <b>Seleccionar Escenario</b>
          </label>
          <Select
            options={optionsStage}
            onChange={stageHandler}
            theme={theme => (styleTheme(theme, colorPalette?.primary))}
          />
        </div>
      </div>
    </div>
  );
}

export default AddSign;
