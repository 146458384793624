import React, { useState, useEffect } from "react";
import BarChart from "./BarChart";
import Utils from '../utils';
import { GraphicCustomWrapper, GraphicCustomWrapperContent, GraphicCustomContainer } from "../modules/graphic/styles";

function GraphicView({config}){
    const [data, setData] = useState([]);
    const [_config, setConfig]=useState(config);
    useEffect(() => {
        let end_point = '';     
        let indicators_temp = [];
        let indicators_complete_temp = []
        
        config.indicators.map(ind => {
            if( ind.multiStages ) {
                for( let i=0; i<ind.stage.length; i++ ) {
                    let info_ind = {...ind};
                    info_ind['labelIndicator'] = ind.stage[i]['labelStage'];
                    info_ind['completeYearInd'] = ind.stage[i]['completeYearInd'];
                    info_ind['color'] = ind.stage[i]['color'];
                    info_ind['type'] = ind.stage[i]['type'];
                    info_ind['otherAxis'] = ind.stage[i]['otherAxis'];
                    info_ind['borderDash'] = ind.stage[i]['borderDash'];
                    info_ind['stage'] = ind.stage[i];
                    
                    indicators_temp.push(info_ind);
                }
            } else {
                indicators_temp.push(ind);
            }
        })
        if( config.completeYear ) {
            config.indicatorsComplete.map(ind => {
                if( ind.multiStages ) {
                    for( let i=0; i<ind.stage.length; i++ ) {
                        let info_ind = {...ind};
                        info_ind['labelIndicator'] = ind['stage'][i]['labelStage'];
                        info_ind['color'] = ind['stage'][i]['color'];
                        info_ind['type'] = ind['stage'][i]['type'];
                        info_ind['otherAxis'] = ind['stage'][i]['otherAxis'];
                        info_ind['borderDash'] = ind['stage'][i]['borderDash'];
                        info_ind['stage'] = ind['stage'][i];
                        indicators_complete_temp.push(info_ind);
                    }
                } else {
                    indicators_complete_temp.push(ind);
                }
            })
        }
        // config['indicators'] = indicators_temp;
        // config['indicatorsComplete'] = indicators_complete_temp;

        let temp = [];
        let ite = 0;
        let today = new Date(localStorage.getItem('year'), localStorage.getItem('month'))
        let to = '';

        if( today.getMonth() + 1 < 10){
            to =  today.getFullYear() + '-0' + (today.getMonth()+1)+ '-01';
        }else{
            to = today.getFullYear() + '-' + (today.getMonth()+1)+ '-01';
        } 
        const fecha_SinDia = new Date(today.getFullYear(), today.getMonth());
        const cont = indicators_temp.length + indicators_complete_temp.length;
        let from = today.getFullYear()+'-01-01';

        indicators_temp.map( (infoIndicator) => {

            const toBefore = to;
            if(infoIndicator.completeYearInd) {
                to = today.getFullYear() + '-12-01';
            };

            end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stage.id +  '/range/' + from + '/' + to;
            Utils.Petition.get(end_point,
                (response) => {
                    temp.push({
                        indicator: infoIndicator.id,
                        stage: infoIndicator.stage.id,
                        data: response.data
                    });
                    ite++;
                    if(ite === cont){
                        setData(temp);
                    } 
                }   
            );
            to = toBefore
        }); 

        if( indicators_complete_temp !== undefined && indicators_complete_temp.length > 0) {
            
            let fromComplete = '';
            const aux = new Date(fecha_SinDia.setMonth(fecha_SinDia.getMonth()+1))

            if( aux.getMonth() + 1 < 10){
                fromComplete =  aux.getFullYear() + '-0' + (aux.getMonth()+1) + '-01';
            } else {
                fromComplete = aux.getFullYear() + '-' + (aux.getMonth()+1) + '-01';
            }
            let toComplete = today.getFullYear() + '-12-01';

            indicators_complete_temp.map( (infoIndicator) => {
                end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stage.id +  '/range/' + fromComplete + '/' + toComplete;
                Utils.Petition.get(end_point,
                    (response) => {
                        temp.push({
                            indicator: infoIndicator.id,
                            stage: infoIndicator.stage.id,
                            data: response.data
                        })
                        ite++;
                        if(ite === cont){
                            setData(temp);
                        } 
                    }   
                )
            });
        }  
        setConfig({..._config,indicators:indicators_temp,indicatorsComplete:indicators_complete_temp})
    }, []);

    return(
        
        <GraphicCustomWrapper>
            <GraphicCustomWrapperContent>
                <GraphicCustomContainer className="graphic-custom">
                    <h3 className="mb-3">{config.title}</h3>
                       
                    {           
                        data.length > 0  && <BarChart dataConfig={_config} data={data}/>
                    }
                </GraphicCustomContainer>
            </GraphicCustomWrapperContent>
        </GraphicCustomWrapper>
        
    )
}

export default GraphicView;