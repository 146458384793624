import { useContext, useEffect, useState } from 'react';
import Section from '../base/Section';
import { UserContext } from '../Context/User';
import { ChangeProfile } from './Components/ChangeProfile';


function Home({ globalProps, page, templateZonesLoadingPageRoute, setPopupStructure }) {
    const { setPageId } = useContext(UserContext);
    const [loadedSuperiorCompleto, setLoadedSuperiorCompleto] = useState(false);

    const [loadedCentroA, setLoadedCentroA] = useState(false);

    const [loadedIzquierdaB, setLoadedIzquierdaB] = useState(false);
    const [loadedDerechaB, setLoadedDerechaB] = useState(false);

    const [loadedIzquierdaC, setLoadedIzquierdaC] = useState(false);
    const [loadedCentroC, setLoadedCentroC] = useState(false);
    const [loadedDerechaC, setLoadedDerechaC] = useState(false);

    const [loadedCentroD, setLoadedCentroD] = useState(false);
    useEffect(() => {
        setPageId(page.id);
    }, [page])

    const templateZonesLoading = {
        superiorCompleto: { loaded: loadedSuperiorCompleto, setLoaded: setLoadedSuperiorCompleto },

        centroA: { loaded: loadedCentroA, setLoaded: setLoadedCentroA },

        izquierdaB: { loaded: loadedIzquierdaB, setLoaded: setLoadedIzquierdaB },
        derechaB: { loaded: loadedDerechaB, setLoaded: setLoadedDerechaB },

        izquierdaC: { loaded: loadedIzquierdaC, setLoaded: setLoadedIzquierdaC },
        centroC: { loaded: loadedCentroC, setLoaded: setLoadedCentroC },
        derechaC: { loaded: loadedDerechaC, setLoaded: setLoadedDerechaC },

        centroD: { loaded: loadedCentroD, setLoaded: setLoadedCentroD },
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="superiorCompleto" />
                    </div>
                </div>
                
            </div>

            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroA" />
                    </div>
                </div>
                

                <div className="row">

                    <div className="col-6">
                        <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaB" />
                    </div>
                    <div className="col-6">
                        <Section setPopupStructure={setPopupStructure}  globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaB" />
                    </div>

                </div>

                <div className="row">
                    <div className="col-4">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="izquierdaC" />
                    </div>
                    <div className="col-4">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroC" />
                    </div>
                    <div className="col-4">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="derechaC" />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="centroD" />
                    </div>
                </div>
                

            </div>
            <ChangeProfile />
        </>

    );
}

export default Home;