import { useState, useEffect, useContext } from 'react';
import CustomModal from '../../base/CustomModal';
import { Container, Row } from 'react-bootstrap';
import Utils from '../../utils';
import React from "react";
import Mapa from "../value-map/mapa-e.png";
import '../../style.css'
import IndicatorDetail from '../../Components/IndicatorDetail';
import Sign from '../../Components/Sign';
import { IndicatorConvensiones } from '../../Components/styles';
import { NameIndicator } from './styles';
import { UserContext } from '../../Context/User';


function View({ globalParams, page, module }) {
  const { colorPalette } = useContext(UserContext);

  const [config, setConfig] = useState({});
  
  const [_idObjetive, setIdObjetive] = useState(0);
  const [_idIndicator, setIdIndicator] = useState(0);
  const [dataObjetive, setDataObjetive] = useState([]);
  const [image, setImage] = useState(new window.Image());
  const [showIndicatorsModal, setIndicatorsModal] = useState(false);
  const [showIndicatorsDetailModal, setIndicatorsDetailModal] = useState(false);
  const [dataIndicators, setDataIndicators] = useState([]);
  const format_number = Utils.getConfigPortal('format_number');
  const format_percent = Utils.getConfigPortal('format_percent');
  
  const img = new window.Image();

  useEffect(() => {
    img.src = Mapa;
    img.addEventListener('load', handleLoad);
    if (Object.keys(module.config).length === 0) {
      module.config = {
        indicator: [],
        objetives: []
      }
    }
    setConfig(module.config)
    getColor();
  }, []);

  const handleLoad = () => {
    setImage(img);
  }

  const handlePopupIndicators = (id) => {
    setIdObjetive(id)
    setDataIndicators([]);
    asyncUpdateIndicator(id);
    setIndicatorsModal(true);
  }

  const handlePopupDetail = (id) => {
    setIdIndicator(id)
    setIndicatorsDetailModal(true);
  }
  let v = 0;
  let r = 0;
  function getColor() {
    var _newDataIndicators = [];
    module.config.objetives.map((obj, key) => {
      let newDataObjetive = [];
      module.config.indicator.map((ind, index) => {
        if (module.config.indicator[index].IDObjetive === obj.ID) {
          newDataObjetive.push(module.config.indicator[index].IDIndicator)
        }
      })
      
      let color = "amarillo"
      if (obj.DSNumber !== "" && newDataObjetive !== []) {
        const indicatorValue = {
          indicators: newDataObjetive,
          view: "A",// localStorage.getItem('view'),
          year: localStorage.getItem('year'),
          month: parseInt(localStorage.getItem('month'))+1
        };
        if (indicatorValue.indicators.length !== 0 && indicatorValue.indicators !== []) {
          Utils.Petition.post('/indicator-value/indicators_table_values',
            indicatorValue,
            (response) => {
              r=0;
              v=0;
              response.data.map((data, index2) => {
                var real = 0;
                var ppto = 0;
                if (data.real !== null) { real = parseFloat(data.real.replace(".", "").replace(",", ".")) };
                if (data.ppto !== null) { ppto = parseFloat(data.ppto.replace(".", "").replace(",", ".")) };
                if (real === 0 || ppto === 0) {
                  color = "amarillo"
                } else
                if (real === ppto) { color = "verde";v++;}
                else {
                  if (data.type === "C") {
                    if (real > ppto) { color = "verde";v++; }
                    else { color = "rojo";r++; }
                  } else {
                    if (real > ppto) { color = "rojo";r++; }
                    else { color = "verde";v++; }
                  }
                }
                response.data[index2].color = color
                response.data[index2].IDObjetive = obj.ID
                _newDataIndicators.push(response.data[index2])
              })
              if (v === 0 && r > 0) color = "rojo";
              else if (r === 0 && v > 0) color = "verde";
              else color="amarillo";
              
              module.config.objetives[key].semaforo = color
              setDataObjetive([...module.config.objetives]);
            })
        }else{
          module.config.objetives[key].semaforo = color
          setDataObjetive([...module.config.objetives]);
        }
      }
      
    })
    setDataIndicators(_newDataIndicators);
  }

  function asyncUpdateIndicator(id) {
    let newDataObjetive = [];
    config.indicator.map((data, index) => {
      if (config.indicator[index].IDObjetive == id) {
        newDataObjetive.push(config.indicator[index].IDIndicator)
      }
    })
    if (newDataObjetive !== []) {
      const indicatorValue = {
        indicators: newDataObjetive,
        view: "A",// localStorage.getItem('view'),
        year: localStorage.getItem('year'),
        month: parseInt(localStorage.getItem('month'))+1
      };
      if (indicatorValue.indicators.length !== 0) {
        Utils.Petition.post('/indicator-value/indicators_table_values',
          indicatorValue,
          (response) => {
            response.data.map((data, index) => {
              if(data.unit !=="%") response.data[index].base = 100;
              else response.data[index].base = 0;
              response.data[index].porcentaje = data.cp
            })
            setDataIndicators(response.data);
          })
      }
    }

  }
  return (
    <Container fluid>
      <CustomModal title={"Indicadores"} show={showIndicatorsModal} setShow={setIndicatorsModal}>
        <Container fluid>
          <Row>
            <table className="table table-bordered">
              <thead>
                <th>Nombre</th>
                <th>Real</th>
                <th>Ppto</th>
                <th>RF</th>
                <th>VAR</th>
              </thead>
              <tbody>
                {
                  dataIndicators.map((indicator, index) => {
                    return (
                      <tr key={index}>
                        <td><NameIndicator color={colorPalette.primary} onClick={handlePopupDetail.bind(this, indicator.id)}>{indicator.name}</NameIndicator></td>
                        <td><Sign value={indicator.porcentaje} type={indicator.type} base={indicator.base} isDescendingIndicator/> {Utils.formatNumber(format_number, indicator.real)} </td>
                        <td>{Utils.formatNumber(format_number, indicator.ppto)}</td>
                        <td>{Utils.formatNumber(format_number, indicator.rf)}</td>
                        <td>{Utils.formatNumber(format_percent, indicator.var)}</td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </Row>
        </Container>
      </CustomModal>
      <CustomModal title={"Detalle Indicador"} show={showIndicatorsDetailModal} setShow={setIndicatorsDetailModal}>
        <IndicatorDetail idindicator={_idIndicator}></IndicatorDetail>
      </CustomModal>
      <IndicatorConvensiones className="B-option">
          
              <div style={{ display: "inline-block" }}>Convenciones: </div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail verde"></i><span>100%</span></div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail amarillo"></i><span>Parcial</span></div>
              <div style={{ display: "inline-block" }}><i className="circle-color-detail rojo"></i><span>No cumple</span></div>
            
      </IndicatorConvensiones>
      <div id="canvas-map">{
        dataObjetive.map((objetive, index) => {
          const style = {
            left: objetive.NMX,
            top: objetive.NMY,
            width: objetive.NMWidth + "px",
            transform: `rotate(${objetive.NMRotation}deg) `,
            color: objetive.DSColor,
            padding: "10px",
            transformOrigin: "left top"
          };
          var number = ""
          var classes = ""
          if (objetive.DSNumber !== "") {
            classes = `mv-dynamic-element objetives `;
            number = `number ${objetive.semaforo}`
          } else {
            classes = `mv-dynamic-element`;
          }
          if(objetive.DSNumber!== ""){
            return (
                <div className={classes === "" ? null : classes} id={"objetives_" + objetive.ID} style={style}  onClick={handlePopupIndicators.bind(this, objetive.ID)}>{
                  <>
                    <div className={number === "" ? null : number}>{objetive.DSNumber}</div>
                    <div className="text">{objetive.DSText}</div>
                  </>
                }
                </div>
            )
          }else{
            return (
                <div className={classes === "" ? null : classes} id={"objetives_" + objetive.ID} style={style} >{
                  <>
                    <div className={number === "" ? null : number}>{objetive.DSNumber}</div>
                    <div className="text">{objetive.DSText}</div>
                  </>
                }
                </div>
            )
          }
          
        })
      }
      </div>
    </Container>
  );
}
export default View;