import { useContext, useState } from "react";
import { FaCog, FaAngleRight } from "react-icons/fa";
import Utils from "../utils";
import { Dropdown } from 'react-bootstrap';
import { UserContext } from "../Context/User";
import { AlertForm, ICON } from "../Components/SweetAlert";

function AdminSection({globalProps, editMode, setEditMode}){
    const { hasPermission } = useContext(UserContext);
    const [isMenuAdmin, setIsMenuAdmin] = useState(false);
    const handleEditPage = (event)=>{

        event.preventDefault();
        setIsMenuAdmin(false);
        setEditMode(!editMode);
    }
    const handleMenuAdmin = (event) => {
        event.preventDefault();
        Utils.Petition.post('/users/create-temporal-token-admin', {}, (data)=>{
            window.location.href=Utils.adminAutologin(data.token);
        })
        
        
    }
    return (  
    <Dropdown>
        <Dropdown.Toggle id="dropdown-account">
        <FaCog style={{fontSize: "1.3rem"}} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
            {hasPermission.edit && <Dropdown.Item onClick={handleEditPage}><FaAngleRight/>{ editMode ? 'Dejar de editar'  :'Editar página' }</Dropdown.Item>}
            {/*<Dropdown.Item href={Utils.getAdminUrl('/')}><FaAngleRight/> Ir al administrador</Dropdown.Item>*/}
            <Dropdown.Item onClick={handleMenuAdmin} ><FaAngleRight/> Ir al administrador</Dropdown.Item>
        </Dropdown.Menu>
    </Dropdown>
    );
}
export default AdminSection;