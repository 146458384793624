import { useContext } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { UserContext } from "../../Context/User";
import { TabHorizontalWrapper } from "./styles";

function HorizontalTabs({allModules, modules, code, page, status}){
    const { colorPalette } = useContext(UserContext);
    return (
        <TabHorizontalWrapper color={colorPalette.secondary}>
            <Tabs defaultActiveKey={0}>
                {
                    modules.map((module, index) => {
                        const ComponentTagName = allModules[module.module_code][status];
                        return (
                            <Tab eventKey={index} title={module.name}>
                                <div key={module.id} id={`page_${page.id}_section_${code}_module_${module.id}`} className={`section-page-${page.id}-section-${code}-module-${module.id} module-component module-${module.module_code} module-status-${status}`}>
                                    <ComponentTagName page={page} module={module}  />
                                </div>
                            </Tab>
                        )
                    })
                }
            </Tabs>
        </TabHorizontalWrapper>
        
    )
}
export default HorizontalTabs;