import { Col, Container, Row } from "react-bootstrap";
import CustomModal from "../../../CustomModal";
import Section from "../../../Section";

function Popup({page, templateZonesLoading, popupStructure, setPopupStructure, globalProps}){

    const handleShow = (isShow)=>{
        setPopupStructure({...popupStructure, show:isShow});
    }
    return (
    <CustomModal show={popupStructure.show} setShow={handleShow} title={popupStructure.title} size="xl">
        {
            popupStructure.show && 
            <div>
                <Section 
                    globalProps={globalProps} 
                    popupStructure={popupStructure} 
                    setPopupStructure={setPopupStructure} 
                    templateZonesLoading={templateZonesLoading} 
                    page={page} 
                    code="popup"
                />
                <Row>
                    <Col md={3}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupIzquierda"
                        />
                    </Col>
                    <Col md={9}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupDerecha"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupIzquierdaA"
                        />
                    </Col>
                    <Col md={6}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupDerechaA"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupIzquierdaB"
                        />
                    </Col>
                    <Col md={4}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupCentroB"
                        />
                    </Col>
                    <Col md={4}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupDerechaB"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Section 
                            globalProps={globalProps} 
                            popupStructure={popupStructure} 
                            setPopupStructure={setPopupStructure} 
                            templateZonesLoading={templateZonesLoading} 
                            page={page} 
                            code="popupCentroC"
                        />
                    </Col>
                </Row>
            </div>
        }        
    </CustomModal>
    
    )
}
export default Popup;