import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from 'react'
import { Button } from "../../commonStyles";
import { Theme1 } from "../../themes/Theme1";
import { AlertForm, ICON } from "../../Components/SweetAlert";
import Utils from "../../utils";

export const EditorText = ({ content, setContent, page, inline = false, menubar = true }) => {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            setContent(editorRef.current.getContent());
            Utils.Petition.post('/comments', {
                comment: editorRef.current.getContent(),
                year: localStorage.getItem('year'),
                month: localStorage.getItem('month'),
                view: localStorage.getItem('view'),
                idfilter: localStorage.getItem('idfilter'),
                typeModel: 'page',
                idtype: page.id
            }, (response) => {
                AlertForm(ICON.SUCCESS, response.message, "", "center", 2500);
            });
        }
    };
    return (
        <>
            <Editor
                apiKey='66yhm7quk48ngil2sffgmag70o378r4v3yoz749l4jp4y5xa'
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content}
                init={{
                    selector: "textarea#classic",
                    width: "100%",
                    height: "Calc(100% - 45px)",
                    resize: false,
                    inline,
                    statusbar: false,
                    language: "es",
                    image_title: true,
                    /* enable automatic uploads of images represented by blob or data URIs*/
                    automatic_uploads: true,
                    file_picker_types: "image",
                    /* and here's our custom image picker*/
                    file_picker_callback: function (cb, value, meta) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");
                        input.onchange = function () {
                            var file = this.files[0];
                            if (file.type.slice(0, 5) === "image") {
                                let reader = new FileReader();
                                reader.onload = function (e) {
                                    if (meta.filetype === 'image') {
                                        cb(e.target.result, { alt: file.name });
                                    }
                                };
                                reader.readAsDataURL(file);
                            }
                        };
                        input.click();
                    },

                    menubar: menubar ? "file edit view insert format tools table" : false,
                    plugins: [
                        " advlist anchor autolink codesample fullscreen help image imagetools charmap print",
                        " lists link media noneditable preview emoticons insertdatetime hr",
                        " searchreplace table template  visualblocks wordcount code pagebreak "
                    ],
                    templates: [
                        {
                            title: "Non-editable Example",
                            description: "Non-editable example.",
                            content: "table1"
                        },
                        {
                            title: "Simple Table Example",
                            description: "Simple Table example.",
                            content: "table2"
                        }
                    ],

                    toolbar:
                        "undo redo | bold italic | forecolor backcolor | template codesample | link image | alignleft aligncenter alignright alignjustify | bullist numlist  | code pagebreak |  fontselect fontsizeselect styleselect  ",

                    tinydrive_token_provider: function (success, failure) {
                        success({
                            token:
                                "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqb2huZG9lIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Ks_BdfH4CWilyzLNk8S2gDARFhuxIauLa8PwhdEQhEo"
                        });
                    },
                    content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }"
                }}
            />
            <Button color={Theme1.success} onClick={log} className="my-1">Guardar</Button>
        </>
    )
}