import styled from "styled-components";
import { Theme1 } from "../../themes/Theme1";


export const WrapperModule = styled.div`
    width: 100%;
    display: flex;
    max-height: 300px;
    height: 300px;
`;

export const WrapperModuleNav = styled.div`
    width: 25%;
    height: 100%;
    overflow-y: auto;
    height: 100%;
    padding-right: 20px;
    .nav-link{
        color: ${props => props.colors?.secondary } !important;
        border-bottom: ${Theme1.border};
        font-size: 16px;
        &:hover{
            color: ${props => props.colors?.primary} !important;
        }
    }
`;

export const WrapperModuleContainer = styled.div`
    width: 75%;
    padding: 0 20px;
    overflow-y: auto;
    height: 100%;
    border-radius: 10px;
`;

export const WrapperButton = styled.div`
    width: 100%;
    button{
        font-size: 12px;
        color: white !important;
        background-color: ${props => props.color} !important;
        border-color: ${props => props.color} !important;
        &:hover{
            background-color: transparent !important;
            color: ${props => props.color} !important;
        }
    }
`;