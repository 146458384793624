import { useContext, useEffect, useState } from "react";
import {  Nav } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa";
import { UserContext } from "../../Context/User";
import { WrapperModule, WrapperModuleNav, WrapperModuleContainer} from "./styles";

function CustomPageComponentGroup({children, selectedComponent, name}){
    const { colorPalette } = useContext(UserContext);
    const [component, setComponent] = useState(<div>Cargando</div>);
    const [activeName, setActiveName] = useState('');

    useEffect(()=>{
        if(Array.isArray(children)){
            setComponent(children[0].props.selectedComponent);
            setActiveName(children[0].props.name);
        }else{
            setComponent(children.props.selectedComponent);
            setActiveName(children.props.name);
        }

    }, []);
    const handleChangeComponent = (_component)=>{
        setComponent(_component.selectedComponent);
        setActiveName(_component.name);
    }

    return (
        <WrapperModule>
            <WrapperModuleNav colors={colorPalette}>
                <Nav className="flex-column" activeKey={activeName}>
                {
                    Array.isArray(children) &&
                    children.map((Component, index)=>{

                        return (<Nav.Link eventKey={Component.props.name} key={index} onClick={()=>{ handleChangeComponent(Component.props) }}>{Component.props.name} <FaAngleRight/></Nav.Link>)
                    })

                    
                }
                { !Array.isArray(children) && <Nav.Link eventKey={children.props.name} onClick={()=>{ handleChangeComponent(children.props) }}>{children.props.name} <FaAngleRight/></Nav.Link> }
                </Nav>
            </WrapperModuleNav>
            <WrapperModuleContainer color={colorPalette.primary}>
                { component }
            </WrapperModuleContainer>
        </WrapperModule>
    )
}

export default CustomPageComponentGroup;