import styled from 'styled-components';
import { Theme1 } from './../../themes/Theme1';

export const TabVerticalWrapper = styled.div`
    padding: 0 15px;
    .row{
        &>div{
            padding: 0 !important;
        }
    }
    .nav-pills{
        background-color: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: -15px 12px 16px rgba(0,0,0,0.2);
        .nav-item{
            a{
                color: ${props => props.color};
                font-weight:500;
                font-size: 12px;
                padding: 0px 20px 0px 40px;
                position:relative;
                &.active{
                    background-color: white;
                    box-shadow: -12px 0px 16px rgba(0,0,0,0.2);
                    z-index: 2;
                    .space{
                        display: block;
                        position: absolute;
                        width: 20px;
                        background-color: white;
                        top: 0;
                        bottom: 0;
                        right: -10px;
                    }
                    span{
                        border: none;
                    }
                }
                span{
                    padding: 20px 0;
                    display: block;
                    text-align: center;
                    border-bottom: ${Theme1.borderTwo};
                }

            }
        }
    }
    .tab-content{
        padding: 20px;
        background-color: white;
        box-shadow: -15px 12px 16px rgba(0,0,0,0.2);
        min-height: 100%;
        img{
            max-width: 100%;
        }
    }
`;


export const TabHorizontalWrapper = styled.div`
    padding-top: 20px   ;
    .nav-tabs{
        border: none;
        .nav-link{
            color: ${props => props.color};
            font-size: 14px;
            font-weight: 500;
            position: relative;
            padding: 10px 30px;
            &::after{
                    content:"";
                    width: 1px;
                    height:Calc(100% - 20px);
                    position: absolute;
                    top:10px;
                    right: -2px;
                    background-color: #555;
                    display: block;
                }
            &.active{
                border: none;
                box-shadow: 0px -7px 10px rgba(0,0,0,0.2);
                &::after{
                    content:"";
                    display: none;
                }
                &::before{
                    content: "";
                    display: block;
                    width: 100%;
                    height: 5px;
                    position: absolute;
                    bottom: -2.5px;
                    left: 0;
                    background-color: white;
                }
            }
        }
    }
    .tab-content{
        margin-top: 0px;
        padding: 20px;
        box-shadow: 0px 0px 12px rgba(0,0,0,0.2);
    }
`;
