export const styleTheme = (theme, primary = "#000000") => {
    return {
        ...theme,
        borderRadius: 6,
        colors: {
            ...theme.colors,
            primary25: `${primary}30`,
            primary:  primary,
        },
    }
}