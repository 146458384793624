import { Card } from 'react-bootstrap';
import styled from 'styled-components';
import { Theme1 } from './../themes/Theme1';

export const SubContainer = styled.div`
    box-shadow: ${Theme1.shadowContainer};
    border-radius: 0.5rem;
    padding-top: 0rem;
    margin-bottom: 3rem;
`;

export const StyleCard = styled(Card)`
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 1px 0px 20px 10px #00000020;
    text-align: center;
    height: 100%;
    justify-content: space-between;
    & h4, & h3 {
        margin-bottom: 2rem;
    }

    & button {
        margin: 0 auto;
    }
`;

export const ButtonClose = styled.button`
    color: white;
    margin-top: 15px;
    width: 24px;
    height: 24px;
    background-color: ${props => props.color} !important;
    border-radius:100px;
    box-shadow: ${Theme1.shadow};
    &:hover{
        color:white;
    }
`;

export const WrapperPadding = styled.div`
    padding-top:30px;
`;