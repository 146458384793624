import { useContext, useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import Section from '../base/Section';
import { UserContext } from '../Context/User';

function Blank({globalProps, page, templateZonesLoadingPageRoute, setPopupStructure}) {
    const { setPageId } = useContext(UserContext);
    const [loadedContainer, setLoadedContainer] = useState(false);

    useEffect(() => {
        setPageId(page.id);
    }, [page])
    const templateZonesLoading = {
        container:{ loaded: loadedContainer, setLoaded:setLoadedContainer},
        popup: templateZonesLoadingPageRoute['popup'],
        popupIzquierda: templateZonesLoadingPageRoute['popupIzquierda'],
        popupDerecha: templateZonesLoadingPageRoute['popupDerecha'],
        popupIzquierdaA: templateZonesLoadingPageRoute['popupIzquierdaA'],
        popupDerechaA: templateZonesLoadingPageRoute['popupDerechaA'],
        popupIzquierdaB: templateZonesLoadingPageRoute['popupIzquierdaB'],
        popupCentroB: templateZonesLoadingPageRoute['popupCentroB'],
        popupDerechaB: templateZonesLoadingPageRoute['popupDerechaB'],
        popupCentroC: templateZonesLoadingPageRoute['popupCentroC'],
        
    };
    return (
        <div className="row">
            <Col>
            <Section setPopupStructure={setPopupStructure} globalProps={globalProps} templateZonesLoading={templateZonesLoading} page={page} code="container" />
            </Col>
        </div>
        
    );
  }
  
  export default Blank;