import styled from 'styled-components';

export const StyleLoading = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height:  ${(props => props.height ? props.height : '100px')};
    & > div {
        margin-right: 1rem;
    }
`;