import React, { useState, useEffect } from "react";
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Utils from "../utils";

function WaterfallChart({dataConfig, data}) {

    const [chartData, setChartData] = useState({});
    const [minChart, setMinChart] = useState(0);

    const format_number = Utils.getConfigPortal('format_number');
    const format_percent = Utils.getConfigPortal('format_percent');

    const chart = () => {

        let xlabels = [];
        const dataSet = [];
        let total = 0.0;
        let backgroundColors = [];
        let mayor = 0;
        let menor = 0;
        let contador = 0

        if( !dataConfig.addTotalColumn ) {
            dataConfig.indicators.map((ind, index) => {
                const aux = data.filter( d => d.indicator === ind.id && d.stage === ind.stage.id )[0];
                if( aux.value !== null && parseFloat(aux.value) !== 0 ) {
                    contador++;
                    if( index === 0) {
                        mayor = parseFloat(aux.value);
                        menor = parseFloat(aux.value);
                    }
                    xlabels.push(ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== "" ? ind.labelIndicator : ind.name);
                    const vStart = total;
                    if(ind.negative){
                        total -= parseFloat(aux.value);
                    }else { 
                        total += parseFloat(aux.value);
                    }
                    if( index === dataConfig.indicators.length - 1) {dataSet.push(parseFloat(aux.value)); total = parseFloat(aux.value)}
                    else dataSet.push([parseFloat(vStart.toFixed(2)), parseFloat(total.toFixed(2))]);
                    mayor = total > mayor ? total : mayor;
                    menor = total < menor ? total : menor;
                    if( index > 0 && index < dataConfig.indicators.length - 1) {
                        backgroundColors.push(aux.value < 0 ? dataConfig.colorNegative !== undefined ? dataConfig.colorNegative : "#ef8220" : dataConfig.colorPositive !== undefined ? dataConfig.colorPositive : "#8eaadc")
                    } else {
                        backgroundColors.push(ind.color)
                    }
                    
                };
            })  
        } else {
            /*let labelTotal = "Label no definido";
            if( dataConfig.hasOwnProperty('labelTotal') && dataConfig.labelTotal !== undefined && dataConfig.labelTotal.trim() !== ""){
                labelTotal = dataConfig.labelTotal;
            };
            let xlabels = dataConfig.indicators.map(ind => {
                if(ind.hasOwnProperty('labelIndicator') && ind.labelIndicator.trim() !== ""){
                    return ind.labelIndicator;
                } else {
                    return ind.name;
                }
            }).concat(labelTotal);
             const backgroundColors = dataConfig.indicators.map(indicator => indicator.color).concat(dataConfig.backgroundTotalLabel)
            */
        }

        let aux_min = Math.floor(menor - (mayor - menor)/10)

        setMinChart( contador > 1 ?  menor < 0 ? aux_min : aux_min < 0 ? 0 : aux_min : 0)

        setChartData({
            labels: xlabels,    
            datasets: [{
                barPercentage: 1,
                data: dataSet,
                backgroundColor: backgroundColors,
                datalabels: {
                    color: 'black',
                    font: {
                        weight: 'bold',
                        size: 15
                    },
                    anchor: function(context) {
                        let value = context.dataset.data[context.dataIndex];
                        if (Array.isArray(value)) {
                            if( value[1] - value[0] > 0) {
                                return  "end"
                            } else if (value[1] - value[0] < 0) {
                                return "start"
                            }
                        } else {
                            if(value > 0) {
                                return  "end"
                            } else if (value < 0) {
                                return "start"
                            }
                        };
                    },
                    align: function(context) {
                        let value = context.dataset.data[context.dataIndex];
                        if (Array.isArray(value)) {
                            if( value[1] - value[0] > 0) {
                                return  "top"
                            } else if (value[1] - value[0] < 0) {
                                return "bottom"
                            }
                        } else {
                            if(value > 0) {
                                return  "top"
                            } else if (value < 0) {
                                return "bottom"
                            }
                        };
                    },
                    offset: 5,
                    formatter: function(value, context) {
                        let str = ""
                        if (Array.isArray(value)) {
                            if( value[1] - value[0] === 0) {
                                return str
                            }
                            let format = Math.abs(value[1] - value[0]) < 100 ? ',.1f' : ',.0f';
                            str =  dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft === '%' ? Utils.formatNumber(format_percent, value[1] - value[0]) + "%" : "$" + Utils.formatNumber(format_number, value[1] - value[0]) : Utils.formatNumber(format, value[1] - value[0]);
                            return str;
                        } else {
                            let format = Math.abs(value) < 100 ? ',.1f' : ',.0f';
                            if( value !== 0 ) str = dataConfig.hasOwnProperty('yLeft') && dataConfig.yLeft !== undefined && dataConfig.yLeft.trim() !== "PorDefecto" ? dataConfig.yLeft === '%' ? Utils.formatNumber(format_percent, value) + "%" : "$" + Utils.formatNumber(format_number, value) : Utils.formatNumber(format_number, value);
                            return str
                        };
                    },

                }
            }]
        })
    }

    useEffect(() => {
        chart();
    }, []);

    return (
        <div>
            <Bar
                data={chartData}
                height={dataConfig.height !== "" ? dataConfig.height: 400}
                width={600}
                plugins = {[ChartDataLabels]}
                options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: !dataConfig.hideLegend, 
                            position: dataConfig.hasOwnProperty('legendPosition') && dataConfig.legendPosition !== "PorDefecto" ?
                                        dataConfig.legendPosition : "top",
                            labels: {
                                boxWidth: 10,
                                padding: 5,
                                usePointStyle: true,
                                pointerStyle: 'circle'
                            },

                        },
                        tooltip: {
                            enabled: !dataConfig.hideTooltip,
                        },
                        title: {
                            display: true,
                            text: "",
                            font: {
                                size: 5
                            }
                        },      
                    },

                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            
                        },
                        y: {
                            display: !dataConfig.hideY,
                            grid: {
                                display: false
                            },
                            min: dataConfig.hasOwnProperty("minYL") && dataConfig.minYL !== undefined && dataConfig.minYL !== "" ? dataConfig.minYL : minChart
                        }
                    },
                }}
            />
        </div>
    )
}

export default WaterfallChart