import { useContext, useState } from "react";
import Objective from "./Objective";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { PerspectiveWrapper, PerspectiveTitle, ObjetiveWrapper, PerspectiveTitleWrapper, PerspectiveTitleHandle, ObjetiveContainerCollapse, ObjetiveWrapperCollapse, StyleArrow } from "./styles";
import { UserContext } from "../../Context/User";
import { Row } from "react-bootstrap";

function Perspective({showObjective, config, perspective, setShowIndicatorsDetailModal, setSelectedIndicatorDetail, indicatorData, idx}){
    const { colorPalette } = useContext(UserContext);
    const [design, setDesign] = useState(config.stages.designStrategicMap === undefined ? "Default":config.stages.designStrategicMap.value);

    const getClassName = ()=>{
        let _class='';
        if(perspective.grouped){
            _class += ' is-grouped'
        }
        if(perspective.horizontal){
            _class += ' is-horizontal'
        }
        return _class;
    }
    const [showIndicadoresH, setShowIndicadoresH] = useState(false);

    const haddleIndicadores = () => {
        setShowIndicadoresH(!showIndicadoresH);
    }

    return (
        <>
            {
                design === "Jerarquico" && idx !== 0 &&
                <StyleArrow image="/assets/ArrowUpBlue.svg"></StyleArrow>
            }
            <PerspectiveWrapper design={design === "Default"} className={"perspective "+(getClassName())}>
                <div>
                    <PerspectiveTitleWrapper design={design === "Default"}>
                        {perspective.horizontal && <div className="empty"></div>}
                        <PerspectiveTitle design={design === "Default"} color={colorPalette.primary} className="title">{perspective.name}</PerspectiveTitle>
                        {
                            perspective.horizontal && 
                            <PerspectiveTitleHandle color={colorPalette.primary} onClick={haddleIndicadores}>Ver indicadores {showIndicadoresH ? <FaAngleUp /> :<FaAngleDown />}</PerspectiveTitleHandle>
                        }
                    </PerspectiveTitleWrapper>
                    {
                        perspective.horizontal ?
                        <ObjetiveWrapperCollapse>
                            { showIndicadoresH &&
                            <ObjetiveContainerCollapse>
                            {
                                <ObjetiveWrapper>
                                    {

                                        perspective.data.map((objective, index)=>{
                                            return (
                                                <Objective key={index} setShowIndicatorsDetailModal={setShowIndicatorsDetailModal} setSelectedIndicatorDetail={setSelectedIndicatorDetail} showObjective={showObjective} config={config} objective={objective} indicatorData={indicatorData} />
                                            )
                                        })
                                    }
                                </ObjetiveWrapper>
                            }
                            </ObjetiveContainerCollapse>
                            }
                            
                        </ObjetiveWrapperCollapse>
                        :
                        <div>
                        {
                            design === "Default" && 
                            <Row className="justify-content-md-center m-0 p-0">
                                {
                                    perspective.data.map((objective, index)=>{
                                        return (
                                            <Objective key={index} setShowIndicatorsDetailModal={setShowIndicatorsDetailModal} setSelectedIndicatorDetail={setSelectedIndicatorDetail} showObjective={showObjective} config={config} objective={objective} indicatorData={indicatorData} />
                                        )
                                    })
                                }
                            </Row>
                        }
                        {
                            design === "Jerarquico" && 
                            <Row className="justify-content-md-center m-0 p-0">
                                {
                                    perspective.data.map((objective, index)=>{
                                        return (
                                            <Objective key={index} setShowIndicatorsDetailModal={setShowIndicatorsDetailModal} setSelectedIndicatorDetail={setSelectedIndicatorDetail} showObjective={showObjective} config={config} objective={objective} indicatorData={indicatorData} designSecondary={true}/>
                                        )
                                    })
                                }
                            </Row>
                        }
                        </div>
                    }
                </div>
            </PerspectiveWrapper>
            
        </>
    );
}
export default Perspective;