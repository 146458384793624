import styled from "styled-components";
import { Theme1 } from "./../themes/Theme1";

export const TableWrapper = styled.div`
    width: 100%;
    position: relative;
    margin-top: 0px;
    margin-bottom: 30px;
    .config-buton{
        position: absolute;
        z-index: 2;
        top: 22px;
        right: 0;
        display: none;
        button, button:hover{
            background-color: white !important;
            color: ${props => props.colors?.primary} !important;
        }
    }
    &:hover{
        .config-buton{
            display: block;
        } 
    }
    table{
            border-top-left-radius: ${Theme1.radius};
            border-top-right-radius: ${Theme1.radius};
            overflow: hidden; 
            thead{
                background-color: ${props => props.colors?.primary};
                color: white;
                font-size: 14px;
                th{
                    &:not(:first-child){
                        text-align: center !important;
                    }
                    &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                }
                a{
                    color: white;
                    text-decoration: underline;
                    font-weight: bold;
                }
            }
            tbody{
                font-size: 14px;
                tr{
                    background-color: #f2f2f2;
                    &.tr-empty{
                        th{
                            height: 20px;
                        }
                    
                    }
                    th{
                        font-weight: normal;
                        font-size: 16px;
                        padding: 0.3rem 0.5rem;
                        &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                        a{
                            color: ${props => props.colors?.grayText} !important;
                            text-decoration: underline;
                            &.text-success{
                                color: ${props => props.colors?.grayText} !important;  
                            }
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                    td{
                        font-size: 16px;
                        padding: 0.3rem 0.5rem;
                        &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                        a{
                            color: ${props => props.colors?.grayText} !important;
                            text-decoration: underline;
                            &.text-success{
                                color: ${props => props.colors?.grayText} !important;  
                            }
                        }
                    }
                    &.table-secondary{
                        th{
                            font-weight: bold;
                            &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                        }
                        td{
                            font-weight: bold;
                            &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                        }
                    }
                    &.tr-empty th{
                        font-weight: bold;
                    }
                }
            }
            &.table-sec{
                td, th{
                    padding: .3rem;
                }
                thead{
                    background-color: #f2f2f2;
                    color: ${props => props.colors?.grayText};
                    font-weight: 300;
                    th{
                        font-weight: bold;
                        &.text-center{
                        text-align: center !important;
                    }
                    &.text-left{
                        text-align: left !important;
                    }
                    }
                    a{
                        color:${props => props.colors?.grayText} !important;
                        text-decoration: underline;
                        font-weight: bold;
                        &:hover{
                            color: ${props => props.colors?.primary};
                        }
                    }
                }
            }
        }
        
    
`;
export const ConfigTable = styled.div`
    float: ${props => props.align || 'right'};
    margin-bottom: 5px;
    margin-top: -20px   ;
    span{
        font-size: 10px;
        color:${props => props.colors?.primary};
        border: thin solid ${props => props.colors?.primary};
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            color: white;
            background-color: ${props => props.colors?.primary};
        }

    }
    .dropdown{
        text-align: right !important;
    }
    button{
        font-size: 10px;
        color:${props => props.colors?.primary} !important;
        border: thin solid ${props => props.colors?.primary} !important;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;
        &:after{
            display: none;
        }
        &:hover, &:active, &:focus{
            color: white !important;
            background-color: ${props => props.colors?.primary} !important;
        }
    }
    .dropdown-menu{
        font-size: 10px;
    }
`;
export const ConfigTable2 = styled.div`
    float: ${props => props.align || 'right'};
    margin-bottom: 5px;
    margin-top:0  ;
    span{
        font-size: 10px;
        color:${props => props.colors?.primary};
        border: thin solid ${props => props.colors?.primary};
        padding: 5px 10px;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
            color: white;
            background-color: ${props => props.colors?.primary};
        }

    }
    .dropdown{
        text-align: right !important;
    }
    button{
        font-size: 10px;
        color:${props => props.colors?.primary} !important;
        border: thin solid ${props => props.colors?.primary} !important;
        padding: 5px 10px;
        border-radius: 4px;
        background-color: transparent;
        cursor: pointer;
        &:after{
            display: none;
        }
        &:hover, &:active, &:focus{
            color: white !important;
            background-color: ${props => props.colors?.primary} !important;
        }
    }
    .dropdown-menu{
        font-size: 10px;
    }
`;
//indicators

export const IndicatorHeadWrapper = styled.div`
    width: 100%;
`;

export const IndicatorConvensiones = styled.div`
    display: table;
    margin: 0 0 0 auto;
    &.B-option{
        margin: 0 auto 0 0;
    }
    &>div{
        vertical-align: middle;
        font-size: 12px;
        &:first-child{
            font-weight: bold;
        }
        span, i{
                display: inline-block;
                vertical-align: middle;   
        }
    }
`;

export const IndicatorConvensionesTwo = styled.div`
    display: table;
    margin: 10px 0 10px auto;
    &.B-option{
        margin: 0 auto 0 0;
    }
    &>div{
        vertical-align: middle;
        font-size: 12px;
        &:first-child{
            font-weight: bold;
        }
        span, i{
                display: inline-block;
                vertical-align: middle;   
        }
    }
`;

export const IndicatorHeadContain = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    
`;
export const IndicatorHeadContainer = styled.div`
    width: ${props => props.Wd || '50%'};
    padding: 10px;
    &>div{
        padding: 10px;
        border-radius: ${Theme1.radius};
        border: ${Theme1.borderTwo};
    }

    & ul p:hover, & p:hover {
        cursor: pointer;
        text-decoration: underline;
    }
    table{
        thead{
            th{
                text-align: center;
                &.activo{ 
                    background-color: #f2f2f2;
                    color: ${props => props.colors?.grayText};
                }
            }
        }
        tbody{
            td{
                &.activo{
                    background-color: #f2f2f2;
                    color: ${props => props.colors?.grayText};
                }
            }
        }
    }
`;


export const IndicatorHeadLabel = styled.div`
    width: 100%;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    padding-bottom: 10px;
    text-align: center;
    &::after{
        content: "";
        width: 50px;
        height: 1px;
        background-color: ${props => props.colors?.primary};
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
    }
`;
