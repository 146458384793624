import React, { useState, useEffect } from "react";
import DoughnutChart from "../../Components/DoughnutChart";
import BarChart from "../../Components/BarChart";
import WaterfallChart from "../../Components/WaterfallChart";
import StackedBarChart from "../../Components/StackedBarChart";
import Utils from '../../utils';
import { GraphicCustomWrapper, GraphicCustomWrapperContent, GraphicCustomContainer } from "./styles";

function View({page, module}){
   
    const [data, setData] = useState([]);
    const [bandera, setBandera] = useState(0);
    const currentYear = useState(parseInt(localStorage.getItem('year')));

    useEffect(() => {
        if( Object.keys(module.config).length > 0) {
            let end_point = '';     
            let indicators_temp = [];
            let indicators_complete_temp = []
            module.config.indicators.map(ind => {
                if( ind.multiStages ) {
                    for( let i=0; i<ind.stage.length; i++ ) {
                        let info_ind = JSON.parse(JSON.stringify(ind));
                        info_ind['type'] = ind.stage[i]['type'];
                        info_ind['otherAxis'] = ind.stage[i]['otherAxis'];
                        info_ind['borderDash'] = ind.stage[i]['borderDash'];
                        info_ind['stage'] = ind.stage[i];
    
                        if(ind.stage[i].otherYears && ind.stage[i].numYears > 0) {
                            info_ind['labelIndicator'] = ind.stage[i]['labelStage'] === undefined || ind.stage[i]['labelStage'] === "" ? "" + currentYear[0] : ind.stage[i]['labelStage'];
                            for( let j=0; j < parseInt(ind.stage[i].numYears); j++) {
                                let aux = JSON.parse(JSON.stringify(info_ind));
                                aux['otherYears'] = ind.stage[i].otherYears;
                                aux['year'] = currentYear[0] - ind.stage[i].numYears + j;
                                aux['completeYearInd'] = true;
                                aux['labelIndicator'] = ind.stage[i].infoYears[j].labelYear !== "" ? ind.stage[i].infoYears[j].labelYear : "" + (currentYear[0] - ind.stage[i].numYears + j);
                                aux['color'] = ind.stage[i].infoYears[j].colorYear;
                                aux['dataLabelTop'] = false;
                                indicators_temp.push(aux);
                            } 
                        } else {
                            info_ind['labelIndicator'] = ind.stage[i]['labelStage'];
                        }
                        info_ind['otherYears'] = false;
                        info_ind['year'] = currentYear[0];
                        info_ind['color'] = ind.stage[i]['color'];
                        info_ind['completeYearInd'] = ind.stage[i]['completeYearInd'];
                        info_ind['dataLabelTop'] = ind.stage[i].dataLabelTop
                        info_ind['stageBarSelected'] = ind.stage[i].stageBarSelected;
                        info_ind['percent'] = ind.stage[i]['percent'];
                        info_ind['colorLabel'] = ind.stage[i]['colorLabel'];      
                        info_ind['nameLabel'] = ind.stage[i]['nameLabel'];      
                        indicators_temp.push(info_ind);
                    }
                } else {
                    let info_ind = JSON.parse(JSON.stringify(ind));
                    if(ind.otherYears && ind.numYears > 0 && !ind.multiStages) {
                        info_ind['labelIndicator'] = ind['labelIndicator'] === undefined || ind['labelIndicator'] === "" ? "" + currentYear[0] :  ind['labelIndicator'];
                        for( let j=0; j < ind.numYears; j++) {
                            let aux = JSON.parse(JSON.stringify(info_ind));
                            aux['otherYears'] = ind.otherYears;
                            aux['year'] = currentYear[0] - ind.numYears + j;
                            aux['completeYearInd'] = true;
                            aux['labelIndicator'] = ind.infoYears[j].labelYear !== "" ? ind.infoYears[j].labelYear : "" + (currentYear[0] - ind.numYears + j);
                            aux['color'] = ind.infoYears[j].colorYear;
                            aux['dataLabelTop'] = false;
                            indicators_temp.push(aux);
                        } 
                    }
                    info_ind.otherYears = false;
                    info_ind.year = currentYear[0];
                    indicators_temp.push(info_ind);
                }
            })

            if( module.config.completeYear ) {
                module.config.indicatorsComplete.map(ind => {
                    if( ind.multiStages ) {
                        for( let i=0; i<ind.stage.length; i++ ) {
                            let info_ind = {...ind};
                            info_ind['labelIndicator'] = ind['stage'][i]['labelStage'];
                            info_ind['color'] = ind['stage'][i]['color'];
                            info_ind['type'] = ind['stage'][i]['type'];
                            info_ind['otherAxis'] = ind['stage'][i]['otherAxis'];
                            info_ind['borderDash'] = ind['stage'][i]['borderDash'];
                            info_ind['stage'] = ind['stage'][i];
                            info_ind['dataLabelTop'] = ind.stage[i].dataLabelTop
                            info_ind['stageBarSelected'] = ind.stage[i].stageBarSelected;
                            info_ind['percent'] = ind.stage[i]['percent'];
                            info_ind['colorLabel'] = ind.stage[i]['colorLabel'];
                            info_ind['nameLabel'] = ind.stage[i]['nameLabel'];      
                            info_ind['year'] = currentYear[0];  
                            indicators_complete_temp.push(info_ind);
                        }
                    } else {
                        ind.year = currentYear[0];
                        indicators_complete_temp.push(ind);
                    }
                })
            }
            
            module.config['indicators'] = indicators_temp;
            module.config['indicatorsComplete'] = indicators_complete_temp;

            if(module.config.type === 'BarChart'){
                let temp = [];
                let ite = 0;
                let today = new Date(localStorage.getItem('year'), localStorage.getItem('month'))
                let to = '';

                if( today.getMonth() + 1 < 10){
                    to =  today.getFullYear() + '-0' + (today.getMonth()+1) + '-01';
                }else{
                    to = today.getFullYear() + '-' + (today.getMonth()+1)+ '-01';
                }
                const fecha_SinDia = new Date(today.getFullYear(), today.getMonth());
                let cont = module.config.indicators.length + (module.config.option === "currentYear" ? module.config.indicatorsComplete.length : 0);
                let aux_cont = 0;

                module.config.indicators.map( ind => {
                    if(ind.dataLabelTop && ind.hasOwnProperty('stageBarSelected') && ind.stageBarSelected !== undefined ) {
                        aux_cont ++; 
                    }
                })
                if( module.config.completeYear && module.config.option === "currentYear") {
                    module.config.indicatorsComplete.map( ind => {
                        if(ind.dataLabelTop && ind.hasOwnProperty('stageBarSelected') && ind.stageBarSelected !== undefined ) {
                            aux_cont ++; 
                        }
                    })
                };

                cont = cont + aux_cont;
                const option = module.config.option;
                let from = '';
        
                if(option !== 'lastYears'){

                    if(option === 'lastMonths') {
                        const months = module.config.months;
                        const aux = new Date(fecha_SinDia.setMonth(fecha_SinDia.getMonth() - months + 1))
                        if( aux.getMonth() + 1 < 10){
                            from =  aux.getFullYear() + '-0' + (aux.getMonth()+1) + '-01';
                        } else {
                            from = aux.getFullYear() + '-' + (aux.getMonth()+1) + '-01';
                        }
                    } else if(option === 'currentYear') from = today.getFullYear()+'-01-01';

                    module.config.indicators.map( (infoIndicator) => {
                        const toBefore = to;
                        const fromBefore = from;

                        if(option === 'currentYear' && infoIndicator.completeYearInd) {
                            to = today.getFullYear() + '-12-01';
                        };

                        if(option === 'currentYear' && infoIndicator.otherYears) {
                            to = infoIndicator.year + "-12-01";
                            from = infoIndicator.year + "-01-01";
                        };

                        end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stage.id +  '/range/' + from + '/' + to;
                        Utils.Petition.get(end_point,
                            (response) => {
                                temp.push({
                                    year: infoIndicator.year,
                                    indicator: infoIndicator.id,
                                    stage: infoIndicator.stage.id,
                                    data: response.data
                                });
                                ite++;
                                if(ite === cont){
                                    setData(temp);
                                    setBandera(1); 
                                } 
                            }   
                        );
                        if(infoIndicator.dataLabelTop && infoIndicator.hasOwnProperty('stageBarSelected') && infoIndicator.stageBarSelected !== undefined ) {
                            end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stageBarSelected.id +  '/range/' + from + '/' + to;
                            Utils.Petition.get(end_point,
                                (response) => {
                                    temp.push({
                                        year: infoIndicator.year,
                                        indicator: infoIndicator.id,
                                        stage: infoIndicator.stageBarSelected.id,
                                        data: response.data
                                    });
                                    ite++;
                                    if(ite === cont){
                                        setData(temp);
                                        setBandera(1); 
                                    } 
                                }   
                            );
                        }
                        to = toBefore;
                        from = fromBefore;
                    }); 

                    if( module.config.indicatorsComplete !== undefined && module.config.indicatorsComplete.length > 0) {
                        
                        let fromComplete = '';
                        const aux = new Date(fecha_SinDia.setMonth(fecha_SinDia.getMonth()+1))

                        if( aux.getMonth() + 1 < 10){
                            fromComplete =  aux.getFullYear() + '-0' + (aux.getMonth()+1) + '-01';
                        } else {
                            fromComplete = aux.getFullYear() + '-' + (aux.getMonth()+1) + '-01';
                        }
                        let toComplete = today.getFullYear() + '-12-01';

                        module.config.indicatorsComplete.map( (infoIndicator) => {
                            end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stage.id +  '/range/' + fromComplete + '/' + toComplete;
                            Utils.Petition.get(end_point,
                                (response) => {
                                    temp.push({
                                        year: infoIndicator.year,
                                        indicator: infoIndicator.id,
                                        stage: infoIndicator.stage.id,
                                        data: response.data
                                    })
                                    ite++;
                                    if(ite === cont){
                                        setData(temp);
                                        setBandera(1); 
                                    } 
                                }   
                            );
                            if(infoIndicator.dataLabelTop && infoIndicator.hasOwnProperty('stageBarSelected') && infoIndicator.stageBarSelected !== undefined ) {
                                end_point = '/indicator-value/indicator/' + infoIndicator.id + '/stage/' + infoIndicator.stageBarSelected.id +  '/range/' + from + '/' + to;
                                Utils.Petition.get(end_point,
                                    (response) => {
                                        temp.push({
                                            year: infoIndicator.year,
                                            indicator: infoIndicator.id,
                                            stage: infoIndicator.stageBarSelected.id,
                                            data: response.data
                                        });
                                        ite++;
                                        if(ite === cont){
                                            setData(temp);
                                            setBandera(1); 
                                        } 
                                    }   
                                );
                            };
                        });
                    }

                } else if(option === 'lastYears' ) {

                    let num_years = module.config.years;
                    let year = parseInt(localStorage.getItem('year'));
                    let month = parseInt(localStorage.getItem('month')) + 1;
                    let info_values = [];
                    
                    for(let i=0; i < num_years; i++) {
                        module.config.indicators.map(ind => {
                            info_values.push("'" + ind.id + "-" + ind.stage.id + "-" + (year - i) + "-" + month + "'");
                            if(ind.dataLabelTop && ind.hasOwnProperty('stageBarSelected') && ind.stageBarSelected !== undefined ) {
                                info_values.push("'" + ind.id + "-" + ind.stageBarSelected.id + "-" + (year - i) + "-" + month + "'");
                            }
                        })
                    }
                    
                    end_point = '/indicator-value/info_values/' + info_values;
                    Utils.Petition.get(end_point,
                        (response) => {
                            setData(response.data)
                            setBandera(1); 
                        }   
                    )         
                } 

            } else if (module.config.type === 'WaterfallChart' || module.config.type === 'DoughnutChart' || module.config.type === 'Table' ){

                let year = localStorage.getItem('year');
                let month = parseInt(localStorage.getItem('month')) + 1;
                let info_values = [];
                
                module.config.indicators.map(indicator => {
                    if(module.config.type === 'WaterfallChart') {
                        info_values.push("'" + indicator.id + "-" + indicator.stage.id + "-" + year + "-" + month + "'")
                    } else {
                        module.config.stages.map(stage => {
                            info_values.push("'" + indicator.id + "-" + stage.id + "-" + year + "-" + month + "'")
                        }); 
                    }           
                });

                end_point = '/indicator-value/info_values/' + info_values;
                Utils.Petition.get(end_point, 
                    (response) => {
                        setData(response.data)  
                        if( module.config.type === 'WaterfallChart' ) {
                            setBandera(2);
                        }else if(module.config.type === 'DoughnutChart' ){
                            setBandera(3);
                        }
                    }  
                )
                
            }  else if ( module.config.type === 'StackedBarChart' ) {

                let year = localStorage.getItem('year');
                let month = parseInt(localStorage.getItem('month')) + 1;
                let info_values = [];

                 
            module.config.indicatorsStages.map(indStage => {
                info_values.push("'" + indStage.indicator.id + "-" + indStage.stage.id + "-" + year + "-" + month + "'" );
                if(module.config.stagesSerie && !module.config.otherIndSerie) {
                    module.config.stagesSerieSelected.map(stage => {
                        info_values.push("'" + indStage.indicator.id + "-" + stage.id + "-" + year + "-" + month + "'");
                    })
                };
            });

            if(module.config.stagesSerie && module.config.otherIndSerie) {
                for( let clave in module.config.othersIndForLabels) {
                    for( let i = 0; i < module.config.othersIndForLabels[clave].indicator.length ; i++) {
                        info_values.push("'" + module.config.othersIndForLabels[clave].indicator[i].id + "-" + module.config.othersIndForLabels[clave].indicator[i].stage.id + "-" + year + "-" + month + "'");
                    }
                }
            }

            if(module.config.infoTotal && !module.config.otherIndTotal) {
                module.config.indicatorTotalSelected.map( ind => {
                    module.config.stagesTotalSelected.map(stage => {
                        info_values.push("'" + ind.id + "-" + stage.id + "-" + year + "-" + month + "'");
                    })
                })
            }

            if(module.config.infoTotal && module.config.otherIndTotal) {
                for( let clave in module.config.othersIndForTotal) {
                    for( let i = 0; i < module.config.othersIndForTotal[clave].indicator.length ; i++) {
                        info_values.push("'" + module.config.othersIndForTotal[clave].indicator[i].id + "-" + module.config.othersIndForTotal[clave].indicator[i].stage.id + "-" + year + "-" + month + "'");
                    }
                }
            }
                
                
                end_point = '/indicator-value/info_values/' + info_values;
                
                Utils.Petition.get(end_point, 
                    (response) => {
                        setData(response.data)
                        setBandera(4);
                    }
                )
            }    
        }
    }, []);
 
    return(
        <GraphicCustomWrapper>
            <GraphicCustomWrapperContent>
                <GraphicCustomContainer className="graphic-custom">
                    {
                        module.config.title !== undefined && module.config.title !== "" &&
                        <h3 className={`mb-3 text-${module.config.hasOwnProperty('posTitle') && module.config.posTitle !== undefined && module.config.posTitle !== "PorDefecto" ? module.config.posTitle : 'left'}`}>{module.config.title}</h3>
                    }  
                    {           
                        data.length > 0 && bandera === 1 && <BarChart dataConfig={module.config} data={data}/>
                    }
                    {
                        data.length > 0 && bandera === 2 && <WaterfallChart dataConfig={module.config} data={data} />
                    }
                    {
                        data.length > 0 && bandera === 3 && <DoughnutChart dataConfig={module.config} data={data} />
                    }
                    {
                        data.length > 0 && bandera === 4 && <StackedBarChart dataConfig={module.config} data={data} />
                    }
                    
                </GraphicCustomContainer>
            
            </GraphicCustomWrapperContent>
            
        </GraphicCustomWrapper>
        
    )
}

export default View;